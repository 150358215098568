import React, { Component } from 'react'
import styled from 'styled-components';
import media from '../stylesheets/media';
import _ from 'lodash';
import { COLUMN_WIDTH } from '../constants/defaults';

const Link = styled.a`
  display:block;
  padding: 32px;
  /* border-bottom: 1px solid black; */
  max-width: ${COLUMN_WIDTH};


  ${media.mobileLarge`
    padding: 10px 15px;
    max-width: inherit;
    width: calc(100% - 30px);
  `}


  &:hover {
    opacity: 1;
  }


  &:last-child {
    border-bottom: none;
  }
  h4 {
    margin-top: 10px;
    margin-bottom: 0;
  }


  ${media.mobileLarge`
    padding: 10px 15px;
  `}
`;

const TruncateDiv = styled.div`
  margin-bottom: 24px;
`;

const FullImg = styled.img`
  width: 100%;
`;

class CustomWorkThumb extends Component {
  render() {
    let firstParagraph = this.props.heading;
    let customPhoto = _.first(this.props.custom_work_photos);

    // console.log(this.props.description);
    return (
      <Link href={`/custom-work/${this.props.id}`}>
        {
          customPhoto ?
          <FullImg src={customPhoto.photo} alt="" /> :
          null
        }

        <h4>
          {this.props.title}
        </h4>
        <TruncateDiv dangerouslySetInnerHTML={{ __html: firstParagraph }} />

        {/* <TruncateDiv dangerouslySetInnerHTML={{ __html: _.truncate(this.props.description.replace(/(<([^>]+)>)/ig, " "), { length: 100 })}} /> */}
      </Link>
    )
  }
}

export default CustomWorkThumb;
