import React, { Component } from 'react'
import { DeviseError } from './';
import _ from 'lodash';
import { Form, Field } from 'react-final-form';

import { Container, Gutter, HalfContainer, LeftColumn, RightColumn, Title, FormField, FormBorderWrapper, PrimaryButton, ButtonA, PaddingDiv, ButtonArea } from '../stylesheets/components';

const Fragment = React.Fragment;

class PasswordEditComp extends Component {

  handleSubmit(e) {
    // debugger;
  }
  
  render() {
    let {  minimum_password_length, errors, bwMode } = this.props;
    const tokenDom = document.querySelector("meta[name=csrf-token]");
    const csrfToken = tokenDom.content;
    return (
      <PaddingDiv>
        {
          _.isUndefined(errors) ?
            null :
            <DeviseError {...errors} />
        }

        <Form
          onSubmit={this.handleSubmit.bind(this)}
          initialValues={{ user: this.props.user }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form method="post" action={this.props.password_path} onSubmit={this.handleSubmit.bind(this)}>
              <input type="hidden" name="authenticity_token" value={csrfToken} />
              <input type="hidden" name="_method" value="put" />
              <input type="hidden" name="user[reset_password_token]" value={this.props.reset_password_token} />
              <FormBorderWrapper bwMode={bwMode}>
                <FormField bwMode={bwMode}>
                  <label>New password</label>
                  <Field
                    name="user[password]"
                    component="input"
                    type="password"
                    autoFocus={true}
                    autoComplete="new-password"
                  />
                </FormField>
                <FormField bwMode={bwMode}>
                  <label>Confirm new password</label>
                  <Field
                    name="user[password_confirmation]"
                    component="input"
                    type="password"
                    autoComplete="new-password"
                  />
                </FormField>
              </FormBorderWrapper>
              <Gutter h={20} />

              <ButtonArea>
                <div>
                  <PrimaryButton bwMode={bwMode} type="submit" disabled={submitting || pristine}>
                    Submit
                  </PrimaryButton>
                </div>
                {/* <div>
                  <a href={this.props.new_session_path}>
                    Login
                  </a> |
                  <a href={this.props.new_registration_path}>Sign Up</a>
                </div> */}
              </ButtonArea>

            </form>
          )}
        />
      </PaddingDiv>
    )
  }
}

export default PasswordEditComp;