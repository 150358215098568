export const DEFAULT_WELCOME_STATE = {
  windowWidth: 1024,
  windowHeight: 768,
  new_user_session_path: "/users/sign_in",
  current_user: null,
  user_signed_in: false,
  destroy_user_session_path: "/users/sign_out",
  new_user_registration_path: "/users/sign_up",
  bwMode: "white",
  cart: null,
  csrfToken: null,
  typefacesPopup: false,
  scriptsPopup: false,
  selectedLanguages: {},
  upProductVariantID: null,
  downProductVariantID: null,
  cartModal: false,
  upProductVariantName: null,
  downProductVariantName: null,
  upProductVariantSupportedLanguage: null,
  downProductVariantSupportedLanguage: null,
  justBoughtLength: null,
  mobileMenuVisible: false,
  mobileMenuBackgroundColor: "#000000",
  mobileMenuForegroundColor: "#FFFFFF",
  previewFontSize: 124,
  selectedTestCategory: { value: 1, label: "Words" },
  customWorkContainerHeight: 1200,
  upVariables: {},
  downVariables: {}
};


export const COLUMN_WIDTH = "calc((100vw - 64px) / 23 * 10)";
