import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { MenuTypefaces, MobileMenu, Menu, MenuScripts, Emblem, MainpageStickyComp} from '../components';
import _ from 'lodash';
import media from '../stylesheets/media';
import { radiansToDegrees } from '../utils';
import FontFaceObserver from 'fontfaceobserver';
import invert from 'invert-color';

const scrollFactor = 3;

const ScrollBox = styled.div`
  height: 0;
  scroll-snap-align: start;
`;

const BigFauxBox = styled.div`
  height: 300vh;
  scroll-snap-align: none;
`;

const SmallFauxBox = styled.div`
  height: 100vh;
  scroll-snap-align: none;
`;


const Fragment = React.Fragment;

const EmblemArea = styled.div`
  position: absolute;
  transform: translate(0, -50%);
  z-index: 5;
  left: 24px;
  top: 50vh;
  width: 114px;
  height: 122px;

  ${media.mobileLarge`
    left: 15px;
    top: 75px;
    width: 48px;
    height: 50px;
    transform: none;
  `}
`;

const FixedMenu = styled.div`
  position: fixed;
  left:0;
  top: 0;
  width: 100vw;
  z-index: 999;
`;

const FixedBottomMenu = styled.div`
position: fixed;
left:0;
bottom: 100px;
width: 100vw;
z-index: 999;

`;

const ScrollContainer = styled.div`
  &.snap {
    scroll-snap-type: y mandatory;
  }
`;



const ProductName = styled.div`
  position:absolute;
  top: 84%;
  right: calc(50% + 50px);
  display: flex;
  justify-content: space-between;
  font-size:1.0em;
  color: ${props => props.color};
`;

const ArrowButton = styled.a`
  position:absolute;
  top: 84%;
  left: 50%;
  transform: translate(-50%,-13px);

  &:hover {
    opacity: 1;
  }
`;

const LanguageName = styled.div`
  position:absolute;
  top: 84%;
  left: calc(50% + 50px);
  font-size:1.0em;

  ${media.mobileLarge`
    width: calc(50% - 65px);
    transform: translate(0, calc(-50% + 14px));
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
  `}

`;
class NewMainPageRenderer extends Component {
  constructor(props){
    super(props);
    this.state = {
      angle: 0,
      containerIdx: 0,
      shouldSnap: false,
      isLoading: true
    };
    this.prevScrollTop = 0;
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount(e){
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount(){
    document.removeEventListener('scroll', this.handleScroll);
  }


  handleScroll(e){
    if(!e.target) return; // If synthetic event has been destroyed
    let { windowHeight } = this.props;
    let boxSize =  windowHeight * scrollFactor;
    let scrollTop = (document.documentElement && document.documentElement.scrollTop) || 
         document.body.scrollTop;

    let speed = scrollTop - this.prevScrollTop;
    const finalProgress = scrollTop / boxSize;
    let angle = radiansToDegrees((finalProgress - Math.floor(finalProgress)) * Math.PI);
    let shouldSnap = finalProgress - Math.floor(finalProgress) > 0.5;
    let containerIdx = Math.floor(finalProgress);
  
    // if (angle > 175) {
    //   if (speed < 30) {
        
    //     let doc = (document.documentElement && document.documentElement.scrollTop) ?  document.documentElement : document.body;

    //     doc.scrollTop = boxSize * (containerIdx + 1);
    //   }
   
    // } else if (angle < 5) {
    //   if (speed < 30) {
        
    //     let doc = (document.documentElement && document.documentElement.scrollTop) ?  document.documentElement : document.body;

    //     doc.scrollTop = boxSize * containerIdx;
    //   }
    // }
    
    
    this.prevScrollTop = scrollTop;
    
    this.setState({
      angle: angle,
      containerIdx: containerIdx,
      shouldSnap: shouldSnap
    });
  }

  render() {
    let { mainPages, windowWidth, windowHeight, products, languages } = this.props;
    let { containerIdx, shouldSnap, isLoading, angle } = this.state;

    let scrollBoxes = [];
    for (let i = 0; i < mainPages.length-1; i++) {
      scrollBoxes.push(
        <React.Fragment key={`scrollboxes_${i}`}>
          <ScrollBox style={{ height: windowHeight * 3}} key={`sb${i}`} />
        </React.Fragment>
      )
    }

    let currentMP;
    
    if (angle > 120) {
      currentMP = mainPages[containerIdx + 1 > mainPages.length - 1 ? mainPages.length - 1 : containerIdx + 1];
    } else {
      currentMP = mainPages[containerIdx];
    }

    if (!currentMP) {
      
      currentMP = mainPages[mainPages.length - 1];
    }
    return (
      <Fragment>

        <MobileMenu foregroundColor={currentMP.color} backgroundColor={currentMP.left_background_color} isLogoutEnabled={true} highlightColor={currentMP.color} currentPage="Welcome" />
        <FixedMenu>
          <Menu foregroundColor={currentMP.color} currentPage="Welcome" isLogoutEnabled={true} />
          <MenuTypefaces foregroundColor={currentMP.color} backgroundColor={currentMP.left_background_color} products={products} />
          <MenuScripts foregroundColor={currentMP.color} languages={languages} />

          <EmblemArea>
            <Emblem fill={currentMP.color} />
          </EmblemArea>
         
        </FixedMenu>
        <FixedBottomMenu>
          <ProductName color={currentMP.color}>
            {currentMP.product.product_name}
          </ProductName>
          <ArrowButton href={`/products/${currentMP.permalink}`}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
              <circle cx="24" cy="24" r="24" fill={currentMP.color} />
              <path d="M15.1875 23.7872C15.0625 23.9122 15 24.0841 15 24.3029C15 24.5216 15.0703 24.7013 15.2109 24.8419C15.3516 24.9825 15.5312 25.0529 15.75 25.0529H30.4219L27.2344 28.2872C26.8594 28.631 26.8516 28.9825 27.2109 29.3419C27.5703 29.7013 27.9219 29.6935 28.2656 29.3185L32.7656 24.8185C32.8594 24.756 32.9219 24.6779 32.9531 24.5841C32.9844 24.4904 33 24.3966 33 24.3029C33 24.2091 32.9844 24.1154 32.9531 24.0216C32.9219 23.9279 32.8594 23.8497 32.7656 23.7872L28.2656 19.2872C27.9219 18.9122 27.5703 18.9044 27.2109 19.2638C26.8516 19.6232 26.8594 19.9747 27.2344 20.3185L30.4219 23.5529H15.75C15.5312 23.5529 15.3438 23.631 15.1875 23.7872Z" fill={currentMP.left_background_color} />
            </svg>
          </ArrowButton>
          <LanguageName style={{color: currentMP.color}}>
            {
              _.map(currentMP.product.languages, l => l.language_name).join(", ")
            }
          </LanguageName>
        </FixedBottomMenu>

        {
          _.map(mainPages, (mp, i) => {
            const variantIds = _.map(_.filter(mp.product.variants, v => !v.complete), v => v.id);

            let mainPageClass = ""; //isLoading ? "" : "fonts-loaded ";
            let status;

            if (i === containerIdx) {
              mainPageClass += "top";
              status = "top";
            } else if (i === containerIdx + 1) {
              mainPageClass += "bottom";
              status = "bottom";
            }


            return (
              <MainpageStickyComp 
                key={i}
                angle={angle}
                windowWidth={windowWidth}
                variantIds={variantIds}
                mp={mp}
                mainPageClass={mainPageClass} 
                status={status}
                i={i} />
            )
          })
        }
      
        <ScrollContainer>
          { scrollBoxes }

          <ScrollBox style={{ height: windowHeight * 1}} />
        </ScrollContainer>
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight
  };
}

export default connect(mapStateToProps)(NewMainPageRenderer);