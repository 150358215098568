import React, { Component } from 'react'
import { connect } from 'react-redux';
import { UserLoginComponent, Emblem, TwitterIcon, InstagramIcon } from './';
import styled from 'styled-components';
import {changeBwMode, setTypefacesPopup} from '../actions';
import media from '../stylesheets/media';

const Line = styled.hr`
  border:none;
  border-bottom: 1px solid ${props =>
    props.foregroundColor ?
      props.foregroundColor :
      (props.bwMode === "white" ? "black" : "white")
    };
`;

const FooterDesktop = styled.div`
  display: block;

  ${media.padLandscape`
    display: none;
  `}
`;

const FooterMobile = styled.div`
  display: none;
  h4.title {
    padding: 0 15px;
    font-size: 0.8em;
    margin-bottom: 10px;

    a {
      color: ${props =>
      props.foregroundColor ?
        props.foregroundColor :
        (props.bwMode === "white" ? "black" : "white")
      };
    }
  }

  ${media.padLandscape`
    display: block;
    margin-top: 30px;
  `}
`;


const MenuContainer = styled.div`
  display: flex;
  padding: 0 15px;
  margin-bottom: 25px;
  justify-content: space-between;

  ${media.padLandscape`
    padding: 10px 15px;
  `}

`;

const LeftColumn = styled.div`
  width: 33.33%;
  a {
    font-size: 0.8em;
    color: ${props =>
    props.foregroundColor ?
      props.foregroundColor :
      (props.bwMode === "white" ? "black" : "white")
    };
  }
`;

const CenterColumn = styled.div`
  width: 50%;
  a {
    font-size: 0.8em;
    color: ${props =>
    props.foregroundColor ?
      props.foregroundColor :
      (props.bwMode === "white" ? "black" : "white")
    };
  }
`;

const RightColumn = styled.div`
  width: 16.6666667%;
`;

const SocialMediaArea = styled.div`
  display: flex;
  margin-top: 10px;
  a {
    margin-right: 15px;
  }
`;

const LogoArea = styled.div`
  display:flex;
  align-items:center;
  justify-content: center;
  margin: 30px 0;
  a {
    color: ${props =>
      props.foregroundColor ?
        props.foregroundColor :
        (props.bwMode === "white" ? "black" : "white")
    };
  }
  svg {
    width: 76px;
    height: 80px;
  }
`;

const FooterArea = styled.footer `
  border-top: 1px solid ${props =>
    props.foregroundColor ?
      props.foregroundColor :
      (props.bwMode === "white" ? "black" : "white")
  };
  margin: 10px 0;
  padding: 15px 20px 0;
  display: flex;
  justify-content: space-between;

  div, a, h4 {
    color:${props =>
      props.foregroundColor ?
        props.foregroundColor :
        (props.bwMode === "white" ? "black" : "white")
    };
  }

  h4 {
    font-size: 0.8em;
  }
`;

const LinkArea = styled.div`
  display: flex;

  a {
    margin: 0 10px;
    font-size: 0.8em;
  }
`;

const Fragment = React.Fragment;
class Footer extends Component {

  handleToggleTypeface(e){
    e.preventDefault();
    let finalPopupMode = !this.props.typefacesPopup;
    if (finalPopupMode) {
      this.props.dispatch(changeBwMode("black"));
    }
    this.props.dispatch(setTypefacesPopup(finalPopupMode));

  }
  render() {
    let { bwMode, foregroundColor } = this.props;

    // console.log("ForegroundColor Popup", foregroundColor);
    return (
      <Fragment>
        <FooterDesktop className="no-webfont">
          <LogoArea>
            <a href="/">
              <Emblem fill={this.props.foregroundColor ?
                this.props.foregroundColor :
                (this.props.bwMode === "white" ? "black" : "white")} />
            </a>
          </LogoArea>
          <FooterArea bwMode={bwMode} foregroundColor={this.props.foregroundColor}>
            <h4>
              <a href="/">
                Universal Thirst
              </a>
            </h4>

            <LinkArea>
              <a href="/products">Typefaces</a><br/>
              <a target="_blank" href="https://gazette.universalthirst.com">Gazette</a><br />
              <a href="/trial-fonts">Trial Fonts</a><br />
              <a href="/about">About</a><br />
              <a href="/custom-work">Custom Work</a><br />
              <a href="/faq">FAQ</a>
              <a href="/privacy-policy">Privacy Policy</a>
              <UserLoginComponent mode="footer" />
            </LinkArea>

            <LinkArea>
              <a href="https://instagram.com/universalthirst">
                <InstagramIcon foregroundColor={this.props.foregroundColor ?
                  this.props.foregroundColor :
                  (this.props.bwMode === "white" ? "black" : "white")} />
              </a>
              <a href="https://twitter.com/universalthirst">
                <TwitterIcon foregroundColor={this.props.foregroundColor ?
                  this.props.foregroundColor :
                  (this.props.bwMode === "white" ? "black" : "white")} />
              </a>
            </LinkArea>
          </FooterArea>
        </FooterDesktop>
        <FooterMobile bwMode={bwMode} foregroundColor={this.props.foregroundColor} className="no-webfont">
          <h4 className="title">
            <a href="/">
              Universal Thirst
            </a>
          </h4>
          <Line bwMode={bwMode} foregroundColor={this.props.foregroundColor}></Line>
          <MenuContainer>
            <LeftColumn foregroundColor={this.props.foregroundColor} bwMode={bwMode}>
              <a href="/products">Typefaces</a><br />
              <a target="_blank" href="https://gazette.universalthirst.com">Gazette</a><br />

              <a href="/trial-fonts">Trial Fonts</a><br />
              <a href="/about">About</a><br />

              <SocialMediaArea>
                <a href="https://instagram.com/universalthirst" target="_blank">
                  <InstagramIcon foregroundColor={this.props.foregroundColor ?
                    this.props.foregroundColor :
                    (this.props.bwMode === "white" ? "black" : "white")} />
                </a><br />
                <a href="https://twitter.com/universalthirst" target="_blank">
                  <TwitterIcon foregroundColor={this.props.foregroundColor ?
                    this.props.foregroundColor :
                    (this.props.bwMode === "white" ? "black" : "white")} />
                </a>
              </SocialMediaArea>
            </LeftColumn>
            <CenterColumn foregroundColor={this.props.foregroundColor} bwMode={bwMode}>
              <a href="/custom-work">Custom Work</a><br />
              <a href="/faq">FAQ</a><br />
              <a href="/privacy-policy">Privacy Policy</a><br/>
              <UserLoginComponent mode="footer" />
            </CenterColumn>
            <RightColumn>
              <LogoArea style={{margin: 0}}>
                <a href="/">
                  <Emblem fill={this.props.foregroundColor ?
                  this.props.foregroundColor :
                    (this.props.bwMode === "white" ? "black" : "white")} />
                </a>
              </LogoArea>
            </RightColumn>
          </MenuContainer>
        </FooterMobile>
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    typefacesPopup: state.typefacesPopup
  }
}

export default connect(mapStateToProps)(Footer);
