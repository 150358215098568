import React from 'react';
import _ from 'lodash';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger, CartItem } from '../components';
import { changeLoginInfo } from '../actions';
import { connect } from 'react-redux';
import { MenuGutter,  BorderArea, Container, ButtonAWhite, Gutter, HalfContainer, LeftColumn, OneLineDesktopGutter, RightColumn, Title, DesktopHr, PaddingDiv, MenuRightSingleGutter, Subtitle } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import styled from 'styled-components';
import media from '../stylesheets/media';

const Fragment = React.Fragment;
const OrderList = styled.a`
  padding: 10px 0;
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
`;

const Disclaimer = styled.div`
  margin: 20px 0;
  color: #828282;
  font-size:0.8em;
  line-height: 1.2;
`;

const Empty = styled.p`
  padding: 20px;
`;


const SubTitle = styled.div`
  margin: 10px 0;
  color: white;
`;
const CartItemContainer = styled.div`

  border-bottom: 1px solid white;

  &:last-child {
    border:none;
  }

  div.title-area {
    padding: 15px;
  }

  div.border {
    background-image: linear-gradient(to right, white 25%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;


    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    margin-bottom: 0;
  }
  div.title {
    width: calc(100% - 150px);
  }
  div.price {
    width: 150px;
    text-align: right;
  }

  div.licenses {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  div.license {
    width: 30%;
    font-size:0.9em;
    line-height: 1.3;
    background: repeating-linear-gradient(to bottom,transparent 0 4px, white 4px 5px) 0/1px 100% no-repeat;
    padding-left: 10px;
    &:first-child {
      background:none;
      padding-left: 0;
    }
  }

  span.label {
    color: #828282;
  }
`;


const OrderItemContainer = styled.div`

  /* justify-content: space-between; */
  margin-bottom: 50px;

  div.title {
    width: 40%;
  }
  div.licenses {
    width: 50%;
  }
  div.delete-price {
    display: flex;

    div.price {
      margin-right: 15px;
    }
  }
`;

const Header = styled.div`

  width: 100%;
  display: flex;
  border-bottom: 1px solid white;

  div.title {
    width: 40%;
  }
  div.licenses {
    width: 50%;
  }

`;



const CartContainer = styled.div`
  border:1px solid ${props => props.bwMode === "black" ? "white" : "black"};

  div, p, a {
    color: ${props => props.bwMode === "black" ? "white" : "black"};
  }
`;

const TotalArea = styled.div`
  border-top: 1px solid white;
  padding: 15px;
  display: flex;
  justify-content: space-between;

  div.elem {
    color: white;
  }
`;

const BackLink = styled.a`
  color: white;
  display: flex;
  align-items: center;

  .label {
    margin-top: 4px;
    margin-left: 5px;
  }
`;


const AddressContainer = styled.div`
  border: 1px solid white;
`;

const AddressComp = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid white;

  ${media.mobileSmall`
    display: block
  `}

  &:last-child {
    border: none;
  }

  div.label {
    color: #828282;
    width: 50%;
    ${media.mobileSmall`
      width: 100%;
      padding-top: 9px;
      padding-bottom: 0;
      padding-left: 2px;
    `}
  }

  div.value {
    width: 50%;
    color: white;

    ${media.mobileSmall`
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;

    `}

  }
`;
const LanguageLabel = styled.span`
  &:after {
    content: ", "
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
`;

class OrderShow extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
  }

  renderOrderedLicense(ordered_number_licenses, licenceType){

    let onl = _.find(ordered_number_licenses, nl => nl.license_name === licenceType);

    if (onl) {
      return (
        <div className="license">
          <span className="label">{licenceType} License</span><br />
          {onl.number_license_name}
        </div>
      )
    } else {
      return (
        <div className="license">
          <span className="label">{licenceType} License</span><br />
          None
        </div>
      )
    }
  }

  render() {
    let { products, languages, user, order, typefacesPopup, bwMode } = this.props;
    return (
      <Fragment>
        <Menu foregroundColor="#FFFFFF" isLogoutEnabled={true} highlightColor="#FFFFFF" currentPage="OrderShow" />
        <MenuTypefaces foregroundColor="#FFFFFF" backgroundColor="black" products={products} />
        <MenuScripts foregroundColor="#FFFFFF" languages={languages} />

        {
          typefacesPopup ?
            null :
            <Fragment>
              <BackgroundColorChanger color="#000000" bwMode="black" />
              <Container bwMode={bwMode} className="no-webfont">
                <BorderArea bwMode={bwMode}>
                  <MenuGutter />
                  <PaddingDiv>
                    <Title bwMode={bwMode}>
                      #{order.public_uid}
                    </Title>
                  </PaddingDiv>
                </BorderArea>
                <BorderArea bwMode={bwMode}>
                  <DesktopHr bwMode={bwMode} />
                  <HalfContainer>
                    <LeftColumn bwMode={bwMode}>
                      <PaddingDiv>

                        {
                          order.refunded ?
                            <SubTitle>
                              Refunded at {order.refunded_at}
                            </SubTitle> :
                            <SubTitle>
                              {order.payment_completed_at}
                            </SubTitle>
                        }

                        <CartContainer bwMode={bwMode}>

                          {
                            order.ordered_items.length > 0 ?
                              <Fragment>

                                {
                                  _.map(order.ordered_items, item => {
                                    return (

                                      <CartItemContainer>
                                        <div className="title-area">
                                          <div className="border">
                                            <div className="title">
                                              {item.ordered_product_name} {item.ordered_product_variant_name} | { item.ordered_languages }
                                            </div>

                                            <div className="price">
                                              €{numberWithDelimiter(item.ordered_price)}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="licenses">
                                          {
                                            this.renderOrderedLicense(item.ordered_number_licenses, "Desktop")
                                          }

                                          {
                                            this.renderOrderedLicense(item.ordered_number_licenses, "Web")
                                          }
                                          {
                                            this.renderOrderedLicense(item.ordered_number_licenses, "App")
                                          }
                                        </div>
                                      </CartItemContainer>
                                    )
                                  })
                                }
                              </Fragment> :
                              <Empty>
                                Cart is Empty.
                              </Empty>
                            }

                        </CartContainer>
                        <Disclaimer>
                          All license purchases (desktop, web, and app) are a one-time payment for perpetual use, with no recurring fees. Prices for web fonts are based on number of unique visitors per month, but still only require a single payment. If the number unique visitors per month exceeds your license, we ask that you upgrade to the appropriate license.
                        </Disclaimer>
                        <TotalArea>
                          <div className="elem">
                            Total Amount
                          </div>
                          <div className="elem">
                            €{numberWithDelimiter(order.total)}
                          </div>
                        </TotalArea>

                        <BackLink href={`/users/profile`}>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.8125 11.7872C20.9375 11.9122 21 12.0841 21 12.3029C21 12.5216 20.9297 12.7013 20.7891 12.8419C20.6484 12.9825 20.4688 13.0529 20.25 13.0529H5.57812L8.76562 16.2872C9.14063 16.631 9.14844 16.9825 8.78906 17.3419C8.42969 17.7013 8.07813 17.6935 7.73438 17.3185L3.23438 12.8185C3.14062 12.756 3.07812 12.6779 3.04688 12.5841C3.01562 12.4904 3 12.3966 3 12.3029C3 12.2091 3.01562 12.1154 3.04688 12.0216C3.07812 11.9279 3.14062 11.8497 3.23438 11.7872L7.73438 7.28723C8.07813 6.91223 8.42969 6.90442 8.78906 7.2638C9.14844 7.62317 9.14063 7.97473 8.76562 8.31848L5.57812 11.5529H20.25C20.4688 11.5529 20.6562 11.631 20.8125 11.7872Z" fill="white" />
                          </svg>
                          <span className="label">
                            Back to My Account
                          </span>
                        </BackLink>
  {/*
                        <OrderItemContainer>
                          <Header>
                            <div className="title">
                              Item
                        </div>

                            <div className="licenses">
                              Licenses
                        </div>

                            <div className="delete-price">
                              Price
                        </div>
                          </Header>

                          {
                            _.map(order.ordered_items, item => {
                              return (

                                <CartItemContainer>
                                  <div className="title">
                                    {item.ordered_product_name} {item.ordered_product_variant_name}
                                  </div>
                                  <div className="licenses">
                                    {
                                      _.map(item.ordered_number_licenses, onl => {
                                        return (
                                          <div>
                                            {onl.license_name} / {onl.number_license_name}
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                  <div className="delete-price">
                                    €{numberWithDelimiter(item.ordered_price)}
                                  </div>
                                </CartItemContainer>
                              )
                            })
                          }
                        </OrderItemContainer> */}


                        {
                          order.refunded ?
                            <a href={`/order/${order.public_uid}/refund_receipt`}>Download Refund PDF</a> : null
                        }

                        <br /><br />

                      </PaddingDiv>
                    </LeftColumn>
                    <RightColumn>

                      <PaddingDiv>
                        <SubTitle>
                          Licensee Information
                        </SubTitle>

                      <AddressContainer>
                        <AddressComp>
                          <div className="label">
                            Full Name
                          </div>
                          <div className="value">
                            { order.fullname }
                          </div>
                        </AddressComp>

                        {
                          order.company ?
                            <AddressComp>
                              <div className="label">
                                Company
                              </div>
                              <div className="value">
                                {order.company}
                              </div>
                            </AddressComp> : null
                        }
                        <AddressComp>
                          <div className="label">
                            Street Address 1
                          </div>
                          <div className="value">
                            {order.addr_01}
                          </div>
                        </AddressComp>
                        {
                          order.addr_02 ?
                          <AddressComp>
                            <div className="label">
                              Street Address 2
                            </div>
                            <div className="value">
                              {order.addr_02}
                            </div>
                          </AddressComp> : null
                        }

                        <AddressComp>
                          <div className="label">
                            City
                          </div>
                          <div className="value">
                            {order.city}
                          </div>
                        </AddressComp>
                        <AddressComp>
                          <div className="label">
                            Country
                          </div>
                          <div className="value">
                            {order.country}
                          </div>
                        </AddressComp>

                        <AddressComp>
                          <div className="label">
                            State
                          </div>
                          <div className="value">
                            {order.state}
                          </div>
                        </AddressComp>

                        <AddressComp>
                          <div className="label">
                            Zipcode
                          </div>
                          <div className="value">
                            {order.zipcode}
                          </div>
                        </AddressComp>

                        <AddressComp>
                          <div className="label">
                            Phone
                          </div>
                          <div className="value">
                            {order.phone}
                          </div>
                        </AddressComp>

                        </AddressContainer>
                        <Gutter h={20} />
                        <SubTitle>
                          Billing Address
                        </SubTitle>

                      <AddressContainer>
                        <AddressComp>
                          <div className="label">
                            Full Name
                          </div>
                          <div className="value">
                            { order.billing_fullname }
                          </div>
                        </AddressComp>
                        <AddressComp>
                          <div className="label">
                            Street Address 1
                          </div>
                          <div className="value">
                            {order.billing_addr_01}
                          </div>
                        </AddressComp>
                        {
                          order.addr_02 ?
                          <AddressComp>
                            <div className="label">
                              Street Address 2
                            </div>
                            <div className="value">
                              {order.billing_addr_02}
                            </div>
                          </AddressComp> : null
                        }

                        <AddressComp>
                          <div className="label">
                            City
                          </div>
                          <div className="value">
                            {order.billing_city}
                          </div>
                        </AddressComp>
                        <AddressComp>
                          <div className="label">
                            Country
                          </div>
                          <div className="value">
                            {order.billing_country}
                          </div>
                        </AddressComp>

                        <AddressComp>
                          <div className="label">
                            State
                          </div>
                          <div className="value">
                            {order.billing_state}
                          </div>
                        </AddressComp>

                        <AddressComp>
                          <div className="label">
                            Zipcode
                          </div>
                          <div className="value">
                            {order.billing_zipcode}
                          </div>
                        </AddressComp>

                        <AddressComp>
                          <div className="label">
                            Phone
                          </div>
                          <div className="value">
                            {order.billing_phone}
                          </div>
                        </AddressComp>

                        </AddressContainer>
                      </PaddingDiv>
                      <PaddingDiv>
                        {
                          order.payment_completed && !order.refunded ?
                            <ButtonAWhite target="_blank" href={`/order/${order.public_uid}/download`}>Download Files</ButtonAWhite> : null
                        }
                        &nbsp;
                        {
                          order.payment_completed && !order.refunded ?
                            <ButtonAWhite target="_blank" href={`/order/${order.public_uid}/receipt`}>Download Receipts</ButtonAWhite> : null
                        }
                      </PaddingDiv>


                    </RightColumn>
                  </HalfContainer>
                </BorderArea>
              </Container>

              <Footer />
            </Fragment>
        }
        <MobileMenu isLogoutEnabled={true} backgroundColor="black" foregroundColor="white" highlightColor="#FFFFFF"  />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode
  }
}
export default connect(mapStateToProps)(OrderShow);



// <Container>
//   <Gutter h={150} />
//   <CenterWrapper>
//     <div>
//       {user.email}<br />
//       {user.fullname}
//     </div>

//     <div style={{ padding: "50px 0" }}>
//       <h4>
//         Order #{order.public_uid}
//       </h4>


//     </div>
//   </CenterWrapper>
// </Container>
