import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ButtonAWhite, CartLink } from '../stylesheets/components';
import { changeCartModal } from '../actions';
import media from '../stylesheets/media';

const Back = styled.div`
  position: fixed;
  background-color: ${props => props.bwMode === "white" ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)"};
  width:100vw;
  height:100vh;
  z-index:9999998;
`;
const Modal = styled.div`
  position: fixed;
  /* width: 260px; */
  padding: 0;
  border-radius:16px;
  border: 1px solid ${props => props.foregroundColor};
  left: 50%;
  top: 50%;
  background-color: ${props => props.backgroundColor};
  transform: translate(-50%, -50%);

  ${media.mobileLarge`
    width: calc(100% - 30px);
  `}
`
const Title = styled.div`
  background-image: linear-gradient(to right, white 25%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x; 
  font-size: 32px;
  padding: 20px 70px;
  color: ${props => props.foregroundColor};

  ${media.mobileLarge`
    padding: 20px;
    text-align:center;
  `}
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 70px;

  ${media.mobileLarge`
    padding: 20px;
  `}
`
const ButtonAreaCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  
`

const ArrowButtonArea = styled(ButtonArea)`
  
  ${media.mobileLarge`
    padding: 0 0 20px 0;
  `}
`;

const CartLinkBorder = styled(CartLink)`
  background-color: ${props => props.backgroundColor};
  border: 1px solid ${props => props.color};
  margin-right: 15px;
  padding: 8px 15px;
`;

const ArrowLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  .label {
    margin-left: 10px;
    color: ${props => props.foregroundColor};
  }
`

class CartAddedModal extends Component {
  handleClick(e){
    this.props.dispatch(changeCartModal(false, null));
  }
  render() {
    let { cartModal, bwMode, cart, justBoughtLength } = this.props;

    let foregroundColor = bwMode === "white" ? "black" : "white";
    let backgroundColor = bwMode === "white" ? "white" : "black";
    
    return (
      <Back bwMode={bwMode}>
        <Modal foregroundColor={foregroundColor} backgroundColor={backgroundColor}>
          <Title foregroundColor={foregroundColor}>
            { justBoughtLength } item{justBoughtLength > 1 ? "s" : ""} added to cart
          </Title>
          <ButtonArea>
            {
              _.isNull(cart) ? 
              null : 
              (
                cart.cart_items.length > 0 ? 
                <CartLinkBorder href="/cart" color={foregroundColor} backgroundColor={backgroundColor} bwMode={bwMode}>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.54004 12H29.4601L28.1048 26.9081C27.98 28.2816 26.8283 29.3333 25.4491 29.3333H6.55104C5.17183 29.3333 4.02019 28.2816 3.89532 26.9081L2.54004 12ZM5.46013 14.6667L6.55104 26.6667H9.27143L8.72598 14.6667H5.46013ZM11.3954 14.6667L11.9409 26.6667H14.6667V14.6667H11.3954ZM17.3334 14.6667V26.6667H20.0593L20.6048 14.6667H17.3334ZM23.2742 14.6667L22.7287 26.6667L25.4491 26.6667L26.54 14.6667H23.2742Z" fill={foregroundColor}/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M21.3333 12.0002V9.3335C21.3333 6.38798 18.9455 4.00016 16 4.00016C13.0545 4.00016 10.6667 6.38798 10.6667 9.3335V12.0002H21.3333ZM16 1.3335C11.5817 1.3335 8 4.91522 8 9.3335V14.6668H24V9.3335C24 4.91522 20.4183 1.3335 16 1.3335Z" fill={foregroundColor}/>
                  </svg>


                  
                  <span className="label" style={{ color: foregroundColor}}>
                    { cart.cart_items.length }
                  </span>
                </CartLinkBorder> : null
              )
            }
            <ButtonAWhite href="/cart">
              View Cart
            </ButtonAWhite>
          </ButtonArea>

          <ArrowButtonArea>
            <ArrowLink href="javascript:void(0);" foregroundColor={foregroundColor} onClick={this.handleClick.bind(this)}>
              <span className="label">
                Continue Shopping
              </span>
            </ArrowLink>
          </ArrowButtonArea>
        </Modal>
      </Back>
    )
  }
}

let mapStateToProps = state => {
  return {
    cartModal: state.cartModal,
    bwMode: state.bwMode,
    cart: state.cart,
    justBoughtLength: state.justBoughtLength
  }
}

export default connect(mapStateToProps)(CartAddedModal);