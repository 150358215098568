import React, { Component } from 'react'
import _ from 'lodash';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger } from '../components';
import { setCart, changeMobileMenuColors, changeLoginInfo } from '../actions';
import { connect } from 'react-redux';

import { Container, Gutter, HalfContainer, LeftColumn, RightColumn, Title, FormField, FormBorderWrapper, PrimaryButton, ButtonA, PaddingDiv, ButtonArea, FlashNotice } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components';

const SubTitle = styled.div`
  margin: 10px 0;
`;
class UserEditComponent extends Component {

  handleSubmit(e) {

  }

  render() {
    let { products, languages, typefacesPopup, bwMode, errors, flash_notice, flash_alert } = this.props;
    ;
    const tokenDom = document.querySelector("meta[name=csrf-token]");
    const csrfToken = tokenDom.content;

    return (
      <PaddingDiv>
        <SubTitle>
          Edit Information
        </SubTitle>
        {
          _.isUndefined(errors) ?
            null :
            <DeviseError {...errors} />
        }


        {
          flash_notice ?
            <FlashNotice bwMode="white">
              {flash_notice}
            </FlashNotice>
            : null
        }


        {
          flash_alert ?
            <FlashNotice bwMode="white">
              {flash_alert}
            </FlashNotice>
            : null
        }


        <Form
          onSubmit={this.handleSubmit.bind(this)}

          initialValues={{
            user: this.props.user
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (

            <form className="edit_user" id="edit_user" action="/users" acceptCharset="UTF-8" method="post">

              <input type="hidden" name="_method" value="put" />
              <input type="hidden" name="authenticity_token" value={csrfToken} />

              <FormBorderWrapper bwMode={bwMode}>
                <FormField bwMode={bwMode}>
                  <label>Email</label>
                  <Field
                    name="user[email]"
                    component="input"
                    type="email"

                    autoComplete="email"
                    placeholder="abc@universalthirst.com"
                  />
                </FormField>

                <FormField bwMode={bwMode}>
                  <label>Fullname</label>
                  <Field
                    name="user[fullname]"
                    component="input"
                    type="text"

                    autoComplete="fullname"
                    placeholder="Fullname"
                  />
                </FormField>

                <FormField bwMode={bwMode}>
                  <label>Company</label>
                  <Field
                    name="user[company]"
                    component="input"
                    type="text"

                    autoComplete="company"
                    placeholder="Company"
                  />
                </FormField>

                <FormField bwMode={bwMode}>
                  <label>Street Address 1</label>
                  <Field
                    name="user[addr_01]"
                    component="input"
                    type="text"

                    autoComplete="addr_01"
                    placeholder="Address 1"
                  />
                </FormField>
                <FormField bwMode={bwMode}>
                  <label>Street Address 2</label>
                  <Field
                    name="user[addr_02]"
                    component="input"
                    type="text"

                    autoComplete="addr_02"
                    placeholder="Address 2"
                  />
                </FormField>

                <FormField bwMode={bwMode}>
                  <label>City</label>
                  <Field
                    name="user[city]"
                    component="input"
                    type="text"

                    autoComplete="city"
                    placeholder="City"
                  />
                </FormField>

                <FormField bwMode={bwMode}>
                  <label>Country</label>
                  <Field
                    component="select"
                    validate={value => {

                      if (value === "-1" ) {
                        return "Required";
                      } else {
                        return (value ? undefined : 'Required');
                      }
                    }}
                    name="user[country]"
                  >
                    {({ input, meta }) => (
                      <div className="right">

                        <select {...input} placeholder="Select Country..." >
                        <option value="-1">Select Country...</option>
                          {
                            _.map(this.props.countries, country => {
                              return (
                                <option key={country[1]} value={country[1]}>
                                  { country[0] }
                                </option>
                              )
                            })
                          }
                        </select>
                        {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                </FormField>

                <FormField bwMode={bwMode}>
                  <label>State</label>
                  <Field
                    name="user[state]"
                    component="input"
                    type="text"

                    autoComplete="state"
                    placeholder="State"
                  />
                </FormField>

                <FormField bwMode={bwMode}>
                  <label>Zipcode</label>
                  <Field
                    name="user[zipcode]"
                    component="input"
                    type="text"

                    autoComplete="zipcode"
                    placeholder="Zipcode"
                  />
                </FormField>

                <FormField bwMode={bwMode}>
                  <label>Phone</label>
                  <Field
                    name="user[phone]"
                    component="input"
                    type="text"

                    autoComplete="phone"
                    placeholder="Phone"
                  />
                </FormField>
              </FormBorderWrapper>

              <Gutter h={20} />
              <ButtonArea>
                <div>
                  <PrimaryButton bwMode={bwMode} type="submit" disabled={submitting || pristine}>
                    Update
                  </PrimaryButton>

                  <ButtonA href="/users/update_password" bwMode={bwMode} type="button" >
                    ChangePassword
                  </ButtonA>
                </div>
              </ButtonArea>
            </form>
          )}
        />
      </PaddingDiv>
    )
  }
}

let mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps)(UserEditComponent);
