import React from 'react';
import _ from 'lodash';
import { MobileMenu, Menu, TruncatedNews, MenuTypefaces, MenuScripts, Footer, BackgroundColorChanger } from '../components';
import { BorderArea, DesktopHr, MenuRightGutter, MenuGutter, Container, Gutter, HalfContainer, LeftColumn, RightColumn, Title, PaddingDiv } from '../stylesheets/components';
import { changeLoginInfo, setCart, changeMobileMenuColors } from '../actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import media from '../stylesheets/media';
import { COLUMN_WIDTH } from '../constants/defaults';

const Fragment = React.Fragment;



const DescDiv = styled(PaddingDiv)`

  img {
    width: 100%;
    height: auto;
  }
  div, a, p {
    color: white;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }


  figcaption {
    font-size:0.8em;
    margin-bottom: 10px;
    margin-top: -5px;
  }

  .attachment__caption {
    display: none;
  }

  .attachment__caption--edited {
    display: block;
  }

  ${media.mobileLarge`
    padding: 10px 15px;
    border-bottom: none;
  `}
`;

const DateDiv = styled.div`

  padding: 0 24px;
  max-width: 600px;

  color: white;
  ${media.mobileLarge`
    padding: 10px 15px;
    border-bottom: none;
  `}
`;


class NewsArticle extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors("#5a01ff", "#FFFFFF"));

    this.hrRef = React.createRef();
    this.state = {
      hrTop: 500
    };
  }

  componentDidMount() {
    this.updateHrTop();
    window.addEventListener('resize', this.resizeHandler.bind(this));
    _.delay(() => {
      this.resizeHandler();

    }, 100);

    _.delay(() => {
      superEmbed();
    }, 1000);
    // let links = document.querySelectorAll("figure a");

    // if (links.length > 0) {
    //   _.each(links, l => {
    //     l.addEventListener("click", e => {
    //       e.preventDefault();
    //     });
    //   });
    // }
  }

  updateHrTop() {
    let hrElem = this.hrRef.current;
    console.log(hrElem.getBoundingClientRect().top);
    this.setState({
      hrTop: hrElem.getBoundingClientRect().top
    });
  }

  resizeHandler(e) {
    this.updateHrTop();
  }

  render() {
    let { selected_news_article, news_articles, products, languages, typefacesPopup, bwMode} = this.props;

    let filteredNewsArticles;

    filteredNewsArticles = news_articles;

    return (
      <Fragment>
        <Menu foregroundColor="#FFFFFF" isLogoutEnabled={true} highlightColor="#DB81FF" currentPage="News" />
        <MenuTypefaces foregroundColor="#FFFFFF" backgroundColor="#5900ff" products={products} />
        <MenuScripts foregroundColor="#FFFFFF" languages={languages} />

        {
          typefacesPopup ?
          null :
          <Fragment>
            <BackgroundColorChanger color="#5900ff" bwMode="black" />
            <Container bwMode={bwMode} className="no-webfont">
              <BorderArea bwMode={bwMode}>

                <HalfContainer>
                  <LeftColumn>
                    <MenuGutter />
                    <PaddingDiv>

                      {
                        _.isNull(selected_news_article) ?
                        <Fragment>
                          Cannot found the requested news.
                        </Fragment> :
                        <Title bwMode={bwMode}>
                          { selected_news_article.title }
                        </Title>

                      }
                    </PaddingDiv>
                    <DesktopHr ref={this.hrRef}  />
                    <DescDiv dangerouslySetInnerHTML={{ __html: selected_news_article.description }} />
                    <DateDiv>
                      { selected_news_article.updated_at }
                    </DateDiv>
                  </LeftColumn>
                  <RightColumn>
                    <MenuRightGutter />
                    <DesktopHr />
                    {
                      _.map(filteredNewsArticles, (news_article, i) => {
                        return (
                          <TruncatedNews key={i} {...news_article} last={i === filteredNewsArticles.length - 1} selected={news_article.id === selected_news_article.id }/>
                        )
                      })
                    }
                  </RightColumn>
                </HalfContainer>
              </BorderArea>


            </Container>
            <Footer foregroundColor="white" />
          </Fragment>
        }


        <MobileMenu isLogoutEnabled={true} backgroundColor="#5900ff" foregroundColor="white" highlightColor="#DB81FF" currentPage="News" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    typefacesPopup: state.typefacesPopup
  }
}

export default connect(mapStateToProps)(NewsArticle);
