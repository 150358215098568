import React, { Component } from 'react'
import { Form, Field, FormSpy } from 'react-final-form';
import _ from 'lodash';
import { MenuTypefaces, Menu, MenuScripts, BackgroundColorChanger, Cart, Footer, ErrorText, MobileMenu } from '../components';
import { connect } from 'react-redux';
import { changeLoginInfo, changeCsrfToken } from '../actions';
import countryList from 'country-list';
import axios from 'axios';
import { setCart, changeMobileMenuColors } from '../actions';
import { UsaStates } from 'usa-states';
import styled from 'styled-components';
import { MobileOnly, Container, Gutter, HalfContainer, LeftColumn, RightColumn, Title, FormField, FormBorderWrapper, PrimaryButton, ButtonA, PaddingDiv, ButtonArea, FinalButtonArea, OneLineDesktopGutter, MenuGutter, ButtonAPrice, BorderArea, DesktopHr, DesktopOnly } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import media from '../stylesheets/media';




const Fragment = React.Fragment;

const PaymentInfoHr = styled.div`
  display: block;
  margin:0;

  border: none;
  border-bottom: 1px solid white;
`;
const SaveforLaterArea = styled.div`
  margin: 15px 0;
  color: white;
  display: flex;
  align-items: center;
  label {
  }

  /* input[type=text] {
    width: 20px;
    height: 20px;
  } */

  a {
    color: white;
    text-decoration: underline;
  }
`;

const SameAsLicenseeArea = styled(SaveforLaterArea)`
  margin: 5px 0;
`



const AgreeArea = styled(SaveforLaterArea)`
  align-items: flex-start;

  input {
    margin-top: 5px;
  }
`;

const AgreeLabel = styled.div`
`


const CardImg = styled.img`

  ${media.mobileLarge`
    width: 100%;
  `}
`;

const Disclaimer = styled.div`
  margin: 20px 0;
  color: #828282;
  font-size:0.7em;
  line-height: 1.2;
`;

const ToCartLink = styled.a`
  display: flex;
  align-items: flex-start;
  font-size: 0.9em;
  div {
    color: white;
    margin-left: 2px;
    margin-top: 2px;
  }
`;


class OrderNew extends Component {
  constructor(props) {
    super(props);
    this.paymentRef = React.createRef();

    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
    // console.log("this.props.cart", this.props.cart_temp)
    this.state = {
      checkoutButtonDisabled: false,
      adyenPaymentInfo: null,
      order: this.props.order,
      save_info: true,
      originKey: this.props.origin_key,
      clientKey: this.props.client_key,
      isPaymentValid: false,
      orderMode: "licenseeInfo", // licenseeinfo, cardInfo
      paymentErrors: null,
      submitted: false,
      sameAsLicensee: false,
      formValues: null,
      payments_methods: null,
      env: this.props.env
    }

    // console.log(this.props.countries)

    this.usaStates = new UsaStates();
    this.props.dispatch(setCart(this.props.cart_temp));
    this.props.dispatch(changeCsrfToken(document.querySelector('meta[name="csrf-token"]').getAttribute('content')));
    this.props.dispatch(changeMobileMenuColors("#000000", "#FFFFFF"));
  }

  componentDidMount() {

    this.initPaymentMethods(this.props.payment_response);
  }

  initPaymentMethods(paymentResponse) {

    const configuration = {
      locale: "en_IS",
      environment: this.state.env,
      clientKey: this.state.originKey,
      originKey: this.state.clientKey,
      paymentMethodsResponse: paymentResponse,
      onChange: this.handleOnChange.bind(this), // Your function for handling onChange event
      onAdditionalDetails: this.handleOnAdditionalDetails.bind(this) // Your function for handling onAdditionalDetails event
    };


    this.checkout = new AdyenCheckout(configuration);
    const card = this.checkout.create("card", {
      styles: {
        base: {
          color: 'white',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          fontFamily: 'sans-serif'
        },
        error: {
          color: 'white'
        },
        placeholder: {
          color: '#757575'
        },
        validated: {
          color: 'white'
        }
      }
    }).mount(this.paymentRef.current);
  }

  handleOnChange(state, component) {
    if (state.isValid) {
      this.setState({
        isPaymentValid: state.isValid,
        adyenPaymentInfo: state.data
      });
    }
    // state.isValid // True or false. Specifies if all the information that the shopper provided is valid.
    // state.data // Provides the data that you need to pass in the `/payments` call.
    // component // Provides the active component instance that called this event.
  }

  handleOnAdditionalDetails(state, component) {
      // state.data // Provides the data that you need to pass in the `/payments/details` call.
      // component // Provides the active component instance that called this event.
  }


  async loadPaymentMethods(country, total){
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    const response = await fetch("/api/adyen_get_payment_methods.json", { method: "post", body: JSON.stringify({
      country: country,
      order_total: total
    }), headers });

    if (response.ok) {
      let data = await response.json();

      this.initPaymentMethods(data.payment_methods);
      this.payments_methods = data.payment_methods;

    } else {

    }

  }


  async handleFinalSubmit(e) {
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    this.setState({
      submitted: true
    });

    const response = await fetch("/orders", { method: "post", body: JSON.stringify({
      order: e.order,
      save_info: e.save_info,
      adyenPaymentMethod: this.state.adyenPaymentInfo.paymentMethod,
      browserinfo: this.state.adyenPaymentInfo.browserInfo
    }), headers });

    if (response.ok) {
      let data = await response.json();

      if (data.payment_action) {
        const configuration = {
          locale: "en_US",
          environment: this.state.env,
          clientKey: this.state.clientKey,
          originKey: this.state.originKey,
          paymentMethodsResponse: this.payment_methods,
          onChange: this.handleOnChange.bind(this), // Your function for handling onChange event
          onAdditionalDetails: this.handleOnAdditionalDetails.bind(this) // Your function for handling onAdditionalDetails event
        };

        this.checkout = new AdyenCheckout(configuration);
        this.checkout.createFromAction(data.payment_action).mount(this.paymentRef.current);

      } else  {

        if (data.status_code === "payment_order_success") {
          // payment done
          document.location.href = data.redirection_url;

        }
      }

    } else {
      let data = await response.json();

      if (data.status_code === "payment_failed") {
        this.setState({
          paymentErrors: data.adyen_response,
          submitted: false
        });

        // payment failed
      } else if (data.status_code === "payment_success_but_order_save_fail") {
        // informing to people using the pspReference
      }
      return data;

    }
  }

  handleSameAsLicensee(e){
    this.setState({
      sameAsLicensee: !this.state.sameAsLicensee
    }, () => {
      if (this.state.sameAsLicensee) {

        let { order } = this.state;
        let newOrder = this.copyLicenseeInfoToBillingAddress(order);
        console.log(newOrder);
        this.setState({
          order: newOrder
        });
      }
    })
  }

  copyLicenseeInfoToBillingAddress(order){

    // perhaps there are better options, but I couldn't find them..
    var newOrder = {
      ...order,
      fullname: document.querySelector("#order_fullname").value,
      addr_01: document.querySelector("#order_addr_01").value,
      addr_02: document.querySelector("#order_addr_02").value,
      city: document.querySelector("#order_city").value,
      state: document.querySelector("#order_state").value,
      country: document.querySelector("#order_country").value,
      zipcode: document.querySelector("#order_zipcode").value,
      phone: document.querySelector("#order_phone").value,
      billing_fullname: document.querySelector("#order_fullname").value,
      billing_addr_01: document.querySelector("#order_addr_01").value,
      billing_addr_02: document.querySelector("#order_addr_02").value,
      billing_city: document.querySelector("#order_city").value,
      billing_state: document.querySelector("#order_state").value,
      billing_country: document.querySelector("#order_country").value,
      billing_zipcode: document.querySelector("#order_zipcode").value,
      billing_phone: document.querySelector("#order_phone").value
    };
    return newOrder;
  }

  render() {

    let { products, languages, typefacesPopup, cart, current_user, bwMode } = this.props;
    let { orderMode, order, checkoutButtonDisabled, paymentErrors } = this.state;
    let countryNames = countryList.getNames();

    let isCartCheckoutable = false;

    if (cart) {
      isCartCheckoutable = cart.isCheckoutable;
    }

    order.email = current_user.email;




    return (
      <Fragment>
        <Menu foregroundColor="#FFFFFF" isLogoutEnabled={true} highlightColor="#FFFFFF" currentPage="Welcome" />
        <MenuTypefaces foregroundColor="#FFFFFF" backgroundColor="black" products={products} />
        <MenuScripts foregroundColor="#FFFFFF" languages={languages} />
        <BackgroundColorChanger color="black" bwMode="black" />

        <Container bwMode={bwMode} className="no-webfont">

          <Form
            onSubmit={this.handleFinalSubmit.bind(this)}
            initialValues={{ order: order, save_info: this.state.save_info, current_user: current_user }}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <BorderArea bwMode={bwMode}>
                    <HalfContainer>
                      <LeftColumn bwMode={bwMode}>

                        <MenuGutter />
                        <PaddingDiv>
                          <Title bwMode={bwMode}>
                            Checkout
                          </Title>
                        </PaddingDiv>
                      </LeftColumn>
                    </HalfContainer>
                  </BorderArea>

                  <BorderArea bwMode={bwMode}>
                    <DesktopHr bwMode={bwMode} />
                    <HalfContainer>
                      <LeftColumn bwMode={bwMode}>
                        {
                          cart ?
                          <PaddingDiv>
                            {
                              cart.cart_items.length > 0 ?
                                <Fragment>
                                  <h3>
                                    Licensee Information
                                  </h3>
                                  <Gutter h={10} />
                                  <FormBorderWrapper bwMode={bwMode}>
                                    <FormField bwMode={bwMode}>
                                      <label>Full Name*</label>
                                      <Field
                                        validate={value => {
                                          return (value ? undefined : 'Required')
                                        }}
                                        name="order[fullname]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input} id="order_fullname" type="text" autoComplete="fullname" placeholder="Full Name" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>

                                    <FormField bwMode={bwMode}>
                                      <label>Email*</label>
                                      <Field
                                        validate={value => {
                                          var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                                          if (value) {
                                            return ( re.test(String(value).toLowerCase()) ? undefined : "Not Valid Email" );
                                          } else {
                                            return (value ? undefined : 'Required');
                                          }
                                        }}
                                        name="order[email]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input} type="email" autoComplete="email" placeholder="abc@universalthirst.com" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>

                                    <FormField bwMode={bwMode}>
                                      <label>Company</label>
                                      <Field
                                        name="order[company]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input} id="order_company" type="text"  autoComplete="company" placeholder="Company" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>


                                    <FormField bwMode={bwMode}>
                                      <label>Street Address 1*</label>
                                      <Field
                                        validate={value => {
                                          return (value ? undefined : 'Required')
                                        }}
                                        name="order[addr_01]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input} id="order_addr_01" type="text"  autoComplete="addr_01" placeholder="Street Address 1" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>

                                    <FormField bwMode={bwMode}>
                                      <label>Street Address 2</label>
                                      <Field
                                        name="order[addr_02]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input} id="order_addr_02" type="text"  autoComplete="addr_02" placeholder="Street Address 2" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>
                                    <FormField bwMode={bwMode}>
                                      <label>City*</label>
                                      <Field
                                        validate={value => {
                                          // console.log("")
                                          return (value ? undefined : 'Required')
                                        }}
                                        name="order[city]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input} id="order_city" type="text" autoComplete="city" placeholder="City" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>
                                    <FormField bwMode={bwMode}>
                                      <label>Country*</label>
                                      <Field
                                        component="select"
                                        validate={value => {
                                          if (value === "-1" ) {
                                            return "Required";
                                          } else {
                                            this.loadPaymentMethods(value, Number(this.props.cart.total));
                                            return (value ? undefined : 'Required');
                                          }
                                        }}
                                        name="order[country]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">

                                            <select {...input} id="order_country" placeholder="Select Country...">
                                            <option value="-1">Select Country...</option>
                                              {
                                                _.map(this.props.countries, country => {
                                                  return (
                                                    <option key={country[1]} value={country[1]}>
                                                      { country[0] }
                                                    </option>
                                                  )
                                                })
                                              }
                                            </select>
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>
                                    <FormField bwMode={bwMode}>
                                      <label>State</label>

                                      <Field
                                        name="order[state]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input} id="order_state" type="text"  autoComplete="state" placeholder="State" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>
                                    <FormField bwMode={bwMode}>
                                      <label>Zipcode*</label>
                                      <Field
                                        validate={value => {
                                          return (value ? undefined : 'Required')
                                        }}
                                        name="order[zipcode]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input}  id="order_zipcode" type="text" autoComplete="zipcode" placeholder="Zipcode" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>
                                    <FormField bwMode={bwMode}>
                                      <label>Phone*</label>
                                      <Field
                                        validate={value => {
                                          return (value ? undefined : 'Required')
                                        }}
                                        name="order[phone]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input}  id="order_phone" type="text" autoComplete="phone" placeholder="phone" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>
                                  </FormBorderWrapper>

                                  <Gutter h={20} />
                                  <h3>
                                    Billing Address
                                  </h3>

                                  <SameAsLicenseeArea>
                                    <input onChange={this.handleSameAsLicensee.bind(this)} checked={this.state.sameAsLicensee} checkname="same_as_licensee" component="input" id="same_as_licensee" type="checkbox" />
                                    <label htmlFor="same_as_licensee">Same as Licensee Information</label>
                                  </SameAsLicenseeArea>

                                  <Gutter h={10} />
                                  <FormBorderWrapper bwMode={bwMode}>
                                    <FormField bwMode={bwMode}>
                                      <label>Full Name*</label>
                                      <Field
                                        validate={value => {
                                          return (value ? undefined : 'Required')
                                        }}
                                        name="order[billing_fullname]"
                                      >
                                        {({ input, meta }) => {
                                          return (
                                            <div className="right">
                                              <input {...input} type="text" autoComplete="billing_fullname" placeholder="Full Name" />
                                              {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                            </div>
                                          )
                                        }}
                                      </Field>
                                    </FormField>

                                    <FormField bwMode={bwMode}>
                                      <label>Street Address 1*</label>
                                      <Field
                                        validate={value => {
                                          return (value ? undefined : 'Required')
                                        }}
                                        name="order[billing_addr_01]"
                                      >
                                        {({ input, meta }) => {
                                          return (
                                            <div className="right">
                                              <input {...input} type="text"  autoComplete="billing_addr_01" placeholder="Street Address 1" />
                                              {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                            </div>
                                          )
                                        }}
                                      </Field>
                                    </FormField>

                                    <FormField bwMode={bwMode}>
                                      <label>Street Address 2</label>
                                      <Field
                                        name="order[billing_addr_02]"
                                      >
                                        {({ input, meta }) => {
                                          return (
                                            <div className="right">
                                              <input {...input} type="text"  autoComplete="billing_addr_02" placeholder="Street Address 2" />
                                              {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                            </div>
                                          )
                                        }}
                                      </Field>
                                    </FormField>
                                    <FormField bwMode={bwMode}>
                                      <label>City*</label>
                                      <Field
                                        initialValue={this.state.sameAsLicensee ? values.order.city : "" }
                                        validate={value => {
                                          // console.log("")
                                          return (value ? undefined : 'Required')
                                        }}
                                        name="order[billing_city]"
                                      >
                                        {({ input, meta }) => {
                                          return (
                                            <div className="right">
                                              <input {...input} type="text"   autoComplete="billing_city" placeholder="City" />
                                              {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                            </div>
                                          )
                                        }}
                                      </Field>
                                    </FormField>
                                    <FormField bwMode={bwMode}>
                                      <label>Country*</label>
                                      <Field
                                        component="select"
                                        validate={value => {
                                          if (value === "-1" ) {
                                            return "Required";
                                          } else {
                                            this.loadPaymentMethods(value, Number(this.props.cart.total));
                                            return (value ? undefined : 'Required');
                                          }
                                        }}
                                        name="order[billing_country]"
                                      >
                                        {({ input, meta }) => {
                                          return (
                                            <div className="right">

                                              <select {...input} placeholder="Select Country...">
                                              <option value="-1">Select Country...</option>
                                                {
                                                  _.map(this.props.countries, country => {
                                                    return (
                                                      <option key={country[1]} value={country[1]}>
                                                        { country[0] }
                                                      </option>
                                                    )
                                                  })
                                                }
                                              </select>
                                              {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                            </div>
                                          );
                                        }}
                                      </Field>
                                    </FormField>
                                    <FormField bwMode={bwMode}>
                                      <label>State</label>

                                      <Field
                                        name="order[billing_state]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input} type="text"  autoComplete="billing_state" placeholder="State" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>
                                    <FormField bwMode={bwMode}>
                                      <label>Zipcode*</label>
                                      <Field
                                        validate={value => {
                                          return (value ? undefined : 'Required')
                                        }}
                                        name="order[billing_zipcode]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input} type="text" autoComplete="billing_zipcode" placeholder="Zipcode" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>
                                    <FormField bwMode={bwMode}>
                                      <label>Phone*</label>
                                      <Field
                                        validate={value => {
                                          return (value ? undefined : 'Required')
                                        }}
                                        name="order[billing_phone]"
                                      >
                                        {({ input, meta }) => (
                                          <div className="right">
                                            <input {...input} type="text" autoComplete="billing_phone" placeholder="phone" />
                                            {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormField>
                                  </FormBorderWrapper>
                                  <AgreeArea>
                                    <Field
                                      validate={value => {
                                        return (value ? undefined : 'Required to check')
                                      }}
                                      type="checkbox"
                                      name="agree_terms">
                                      {({ input, meta }) => (
                                        <Fragment>
                                          <input {...input} id="agree_terms" type="checkbox" />
                                          <AgreeLabel>
                                            <label style={{ color: "white" }} htmlFor="agree_terms">I agree to <a href="/terms" target="_blank">the Terms and Conditions</a>, <a href="/privacy-policy" target="_blank">the Privacy Policy</a>, and <a href="/refund-policy" target="_blank">the Refund and Cancellation policy</a></label>

                                            {meta.error && meta.touched && <div style={{ color: "#828282", fontSize: "0.8em" }}><span className="error">{meta.error}</span></div>}
                                          </AgreeLabel>
                                        </Fragment>
                                      )}
                                    </Field>
                                  </AgreeArea>

                                  <SaveforLaterArea>
                                    <Field name="save_info" component="input" id="save_info" type="checkbox" />
                                    <label htmlFor="save_info">Save for later use</label>
                                  </SaveforLaterArea>

                                  <Disclaimer>
                                    All license purchases (desktop, web, and app) are a one-time payment for perpetual use, with no recurring fees. Prices for web fonts are based on number of unique visitors per month, but still only require a single payment. If the number unique visitors per month exceeds your license, we ask that you upgrade to the appropriate license.
                                  </Disclaimer>
                                  <DesktopOnly>
                                    <ToCartLink href="/cart">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M20.8125 11.7872C20.9375 11.9122 21 12.0841 21 12.3029C21 12.5216 20.9297 12.7013 20.7891 12.8419C20.6484 12.9825 20.4688 13.0529 20.25 13.0529H5.57812L8.76562 16.2872C9.14063 16.631 9.14844 16.9825 8.78906 17.3419C8.42969 17.7013 8.07813 17.6935 7.73438 17.3185L3.23438 12.8185C3.14062 12.756 3.07812 12.6779 3.04688 12.5841C3.01562 12.4904 3 12.3966 3 12.3029C3 12.2091 3.01562 12.1154 3.04688 12.0216C3.07812 11.9279 3.14062 11.8497 3.23438 11.7872L7.73438 7.28723C8.07813 6.91223 8.42969 6.90442 8.78906 7.2638C9.14844 7.62317 9.14063 7.97473 8.76562 8.31848L5.57812 11.5529H20.25C20.4688 11.5529 20.6562 11.631 20.8125 11.7872Z" fill="white" />
                                      </svg>

                                      <div>
                                        Back to Cart
                                      </div>
                                    </ToCartLink>
                                  </DesktopOnly>
                                </Fragment> : null
                            }
                          </PaddingDiv> : null
                        }
                      </LeftColumn>
                      <RightColumn>
                        <MobileOnly>
                          <PaymentInfoHr/>
                        </MobileOnly>
                        <PaddingDiv>

                          <h3>
                            Payment information
                          </h3>
                          <Gutter h={10} />
                          {
                            paymentErrors ?
                            <ErrorText {...paymentErrors} /> : null
                          }
                          <div ref={this.paymentRef}></div>

                          <Disclaimer>
                            Universal Thirst uses Adyen as a payment gateway, who is a PCI DSS Level 1 Service Provider, and we do not save any sensitive cardholder information such as card number and CVC number.
                          </Disclaimer>

                          <Gutter h={10} />
                          <CardImg src="/assets/card_logos.png" alt="Credit Card Logo" style={{ width: 293, height: 29}} />
                          <Gutter h={40} />
                          {
                            isCartCheckoutable ?
                              <FinalButtonArea>
                                <ButtonAPrice bwMode={bwMode}>
                                  €{numberWithDelimiter(cart.total)}
                                </ButtonAPrice>
                                <PrimaryButton bwMode={bwMode} type="submit" disabled={(!this.state.isPaymentValid || submitting || this.state.submitted )}>
                                  Pay
                                </PrimaryButton>
                              </FinalButtonArea> : null
                          }
                          <Gutter h={15} />
                          <MobileOnly>
                            <ToCartLink href="/cart">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M20.8125 11.7872C20.9375 11.9122 21 12.0841 21 12.3029C21 12.5216 20.9297 12.7013 20.7891 12.8419C20.6484 12.9825 20.4688 13.0529 20.25 13.0529H5.57812L8.76562 16.2872C9.14063 16.631 9.14844 16.9825 8.78906 17.3419C8.42969 17.7013 8.07813 17.6935 7.73438 17.3185L3.23438 12.8185C3.14062 12.756 3.07812 12.6779 3.04688 12.5841C3.01562 12.4904 3 12.3966 3 12.3029C3 12.2091 3.01562 12.1154 3.04688 12.0216C3.07812 11.9279 3.14062 11.8497 3.23438 11.7872L7.73438 7.28723C8.07813 6.91223 8.42969 6.90442 8.78906 7.2638C9.14844 7.62317 9.14063 7.97473 8.76562 8.31848L5.57812 11.5529H20.25C20.4688 11.5529 20.6562 11.631 20.8125 11.7872Z" fill="white" />
                              </svg>

                              <div>
                                Back to Cart
                              </div>
                            </ToCartLink>
                          </MobileOnly>

                        </PaddingDiv>

                      </RightColumn>
                    </HalfContainer>
                  </BorderArea>
                </form>
              )
            }}
          />

        </Container>
        <Footer />

        <MobileMenu isLogoutEnabled={true} backgroundColor="black" foregroundColor="white" highlightColor="#FFFFFF" currentPage="OrderNew" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    cart: state.cart,
    bwMode: state.bwMode,
    csrfToken: state.csrfToken
  }
}
export default connect(mapStateToProps)(OrderNew);
