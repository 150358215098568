import React, { Component } from 'react'
import { Form, Field } from 'react-final-form';
import _ from 'lodash';
import styled from 'styled-components';
import { MenuTypefaces, Menu, MenuScripts, BackgroundColorChanger, Cart, Footer, MobileMenu } from '../components';
import { connect } from 'react-redux';
import { changeLoginInfo } from '../actions';
import countryList from 'country-list';
import axios from 'axios';
import { setCart } from '../actions';
import { UsaStates } from 'usa-states';
import { Container, OneLineDesktopGutter, HalfContainer, LeftColumn, RightColumn, Title, FormField, FormBorderWrapper, PrimaryButton, ButtonA, PaddingDiv, DesktopHr, MenuGutter, FlashNotice, BorderArea } from '../stylesheets/components';

const Fragment = React.Fragment;

class CartPage extends Component {
  constructor(props){
    super(props);

    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));

    // console.log("this.props.cart", this.props)
    this.state = {
      checkoutButtonDisabled: false,
      braintreeInstance: null,
      order: this.props.order,
      save_info: true,
      client_token: this.props.client_token,
      orderMode: "licenseeInfo" // licenseeinfo, cardInfo
    }

    this.usaStates = new UsaStates();
    this.props.dispatch(setCart(this.props.cart_temp));
  }

  componentDidMount(){
    const tokenDom = document.querySelector("meta[name=csrf-token]")
    if (tokenDom) {
      const csrfToken = tokenDom.content
      axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
    }

  }



  onLicenseeInfoSubmit (values){


    this.setState({
      order: values.order,
      save_info: values.save_info,
      orderMode: "cardInfo"
    })

    braintree.dropin.create({
      authorization: this.state.client_token,
      container: '#bt-dropin',
      threeDSecure: true,
      paypal: {
        flow: 'vault'
      }
    }, (createErr, instance) => {

      this.setState({
        braintreeInstance: instance
      });

    });


  }

  handleFinalSubmit(e){
    // debugger;
    this.setState({
      checkoutButtonDisabled: true
    })
    this.state.braintreeInstance.requestPaymentMethod((err, payload) => {
      if (err) {
        // console.log('Error', err);

        this.setState({
          checkoutButtonDisabled: false
        })
        return;
      }

      let { order, save_info } = this.state;

      axios.post("/orders", {
        order: order,
        save_info: save_info,
        payment_method_nonce: payload.nonce
      }).then((response) => {
        if (response.data.success) {
          document.location.href = response.data.redirection_url;
        } else {

          this.setState({
            checkoutButtonDisabled: false
          });
        }
      }).catch((error) => {
        // console.log("errors!!!!");
        // console.log(error);

        this.setState({
          checkoutButtonDisabled: false
        });
      });

      // form.submit();
    });
  }



  handleBackClick(e){
    this.setState({
      orderMode: "licenseeInfo"
    })

  }



  render() {

    let { products, languages, typefacesPopup, cart, current_user, bwMode, flash } = this.props;
    let { orderMode, order, braintreeInstance, checkoutButtonDisabled } = this.state;
    let countryNames = countryList.getNames();

    // console.log("cart", cart);
    return (
      <Fragment>
        <Menu foregroundColor="#FFFFFF" isLogoutEnabled={true} highlightColor="#FFFFFF" currentPage="Welcome" />
        <MenuTypefaces foregroundColor="#FFFFFF" backgroundColor="black" products={products} />
        <MenuScripts foregroundColor="#FFFFFF" languages={languages} />
        {
          typefacesPopup ?
            null :
            <Fragment>
              <BackgroundColorChanger color="black" bwMode="black" />

              <Container className="no-webfont" bwMode={bwMode}>
                <BorderArea bwMode={bwMode}>
                  <MenuGutter />
                  <PaddingDiv>
                    <Title bwMode={bwMode}>
                        Fonts in Cart
                    </Title>
                  </PaddingDiv>
                </BorderArea>

                <BorderArea bwMode={bwMode}>
                  <DesktopHr bwMode={bwMode} />
                  <HalfContainer>
                    <LeftColumn bwMode={bwMode}>
                      <PaddingDiv>
                        {
                          flash ?
                          <FlashNotice bwMode="black">
                            { flash }
                          </FlashNotice>
                          : null
                        }


                        <Cart editable={orderMode !== "cardInfo"} licenses={this.props.licenses} checkoutButton={true} />

                      </PaddingDiv>
                    </LeftColumn>
                  </HalfContainer>
                </BorderArea>


              </Container>
              <Footer />
            </Fragment>
        }

        <MobileMenu isLogoutEnabled={true} backgroundColor="black" foregroundColor="white" highlightColor="#FFFFFF" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    cart: state.cart,
    bwMode: state.bwMode
  }
}
export default connect(mapStateToProps)(CartPage);
