import React, { Component } from 'react'
import _ from 'lodash';
import { Gutter } from '../stylesheets/components';

const Fragment = React.Fragment;
class DeviseError extends Component {
  render() {
    return (
      <div id="error_explanation">
        {/* <h2>
          { this.props.not_saved_message }
        </h2>
         */}
        <ul>
          {
            _.map(this.props.full_messages, (message, i) => {
              return (
                <li key={i}>
                  {message}.
                </li>
              )
            })
          }
        </ul>
        <Gutter h={10} />
      </div>
    )
  }
}

export default DeviseError;
