import React, { Component } from 'react'
import styled from 'styled-components';

const Fragment = React.Fragment;
const TextP = styled.div`
  padding: 10px 0;
  color: white;
`;

export default class ErrorText extends Component {
  render() {

    let { resultCode, refusalReason } = this.props;

    // ;
    return (
      <Fragment>
        {
          resultCode === "Refused" ?
          <TextP>
            Transaction Failed: The card information was refused from the payment gateway. Reason: {refusalReason }
          </TextP> : null
        }
        {
          resultCode !== "Refused" ?
          <TextP>
            Transaction Failed
          </TextP> : null
        }
      </Fragment>
    )
  }
}
