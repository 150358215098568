import React from 'react';
import _ from 'lodash';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger, PasswordEditComp, PasswordNewComp } from '../components';
import { changeLoginInfo } from '../actions';
import { connect } from 'react-redux';
import { Container, BorderArea, MenuGutter, DesktopHr, Gutter, HalfContainer, LeftColumn, RightColumn, Title, FormField, FormBorderWrapper, PrimaryButton, ButtonA, PaddingDiv, TwoLineDesktopGutter } from '../stylesheets/components';
import styled from 'styled-components';
import media from '../stylesheets/media';


const Fragment = React.Fragment;



class Password extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));

  }


  render() {
    let { products, languages, typefacesPopup, minimum_password_length, errors, bwMode } = this.props;
    console.log("this.props.reset_password_token", this.props.reset_password_token)
    return (
      <Fragment>
        <Menu isLogoutEnabled={true} />
        <MenuTypefaces foregroundColor="black" backgroundColor="white" products={products} />
        <MenuScripts languages={languages} />

        {
          typefacesPopup ?
            null :
            <Fragment>
              <BackgroundColorChanger color="#E0E0E0" bwMode="white" />
              <Container bwMode={bwMode} className="no-webfont">
                <BorderArea foregroundColor="black">
                  <MenuGutter />
                  <PaddingDiv>
                    <Title bwMode={bwMode}>
                      Reset Password
                    </Title>
                  </PaddingDiv>
                </BorderArea>
                <BorderArea foregroundColor="black">
                  <DesktopHr foregroundColor="black" />
                  <LeftColumn bwMode={bwMode}>



                    {
                      !_.isUndefined(this.props.reset_password_token) ?
                        <PasswordEditComp {...this.props} /> :
                        <PasswordNewComp {...this.props} />
                    }

                  </LeftColumn>
                </BorderArea>


              </Container>

              <Footer foregroundColor="black" />
            </Fragment>
        }

        <MobileMenu isLogoutEnabled={true} backgroundColor="#E0E0E0" foregroundColor="black" highlightColor="#000000" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode
  }
}

export default connect(mapStateToProps)(Password);