import React from 'react';
import _ from 'lodash';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger, UpdatePasswordComponent } from '../components';
import { setCart, changeMobileMenuColors, changeLoginInfo } from '../actions';
import { connect } from 'react-redux';
import { DesktopOnly, MobileOnly, FlashNotice, BorderArea, Container, Gutter, HalfContainer, DesktopGutter, MenuGutter, LeftColumn, RightColumn, Title, DesktopHr, PaddingDiv, MenuRightSingleGutter } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import styled from 'styled-components';

const Fragment = React.Fragment;


const OrderList = styled.a`
  padding: 10px 15px;
  font-size: 0.9em;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    border: none;
  }

  span.label {
    color: #828282;
  }

  div.elem {
    width: calc(50% - 10px);
  }
`;

const SubTitle = styled.div`
  margin: 10px 0;
`;

const OrderListContainer = styled.div`
  border: 1px solid black;
`;

const OrderListArrow = styled.div`
  padding-left: 10px;
`

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors("#E0E0E0", "#000000"));
  }

    render() {
      let { products, languages, user, orders, typefacesPopup, bwMode } = this.props;
      return (
        <Fragment>
          <Menu foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} isLogoutEnabled={true} highlightColor="#000000" currentPage="Welcome"/>
          <MenuTypefaces foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} backgroundColor="#E0E0E0" products={products} />
          <MenuScripts foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} languages={languages} />

          {
            typefacesPopup ?
            null :
            <Fragment>
              <BackgroundColorChanger color="#E0E0E0" bwMode="white" />
              <Container bwMode={bwMode} className="no-webfont">
                <BorderArea bwMode={bwMode}>
                  <MenuGutter />

                  <HalfContainer>
                    <LeftColumn bwMode={bwMode}>
                      <PaddingDiv>
                        <Title bwMode={bwMode}>
                          Update Password
                        </Title>
                      </PaddingDiv>
                    </LeftColumn>
                    <RightColumn>

                    </RightColumn>
                  </HalfContainer>
                </BorderArea>
                <BorderArea bwMode={bwMode}>
                  <DesktopHr bwMode={bwMode} />
                  <HalfContainer>
                    <LeftColumn bwMode={bwMode}>
                      <UpdatePasswordComponent {...this.props} />

                    </LeftColumn>
                    <RightColumn>
                        <MobileOnly>
                          <PaddingDiv>
                              <Title bwMode={bwMode}>
                                
                              </Title>
                          </PaddingDiv>
                        </MobileOnly>
                        <PaddingDiv>
                      </PaddingDiv>
                    </RightColumn>
                  </HalfContainer>
                </BorderArea>
              </Container>

              <Footer foregroundColor="#000000" />
            </Fragment>
          }

          <MobileMenu isLogoutEnabled={true} backgroundColor="#E0E0E0" foregroundColor="black" highlightColor="#000000" />
        </Fragment>
      );
    }
  };

  let mapStateToProps = state => {
    return {
      typefacesPopup: state.typefacesPopup,
      bwMode: state.bwMode
    }
  }

  export default connect(mapStateToProps)(UpdatePassword);
