import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ProductVariantPreview } from './';
import media from '../stylesheets/media';
import { changePreviewFontSize } from '../actions';
import { getFontVH } from '../utils';

const Fragment = React.Fragment;
const Area = styled.div`
  position: relative;
  height: 90vh;
  min-height: 640px;
  max-height: 1000px;
  user-select: none;
  /* overflow: hidden; */
  ${media.mobileLarge`
    height: 640px !important;
  `}
`;

const SliderArea = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

class ProductVariantPreviewArea extends Component {
  constructor(props){
    super(props);

    this.state = {
      paragraphHeight: 1500
    };
  }
  componentDidMount(){
    this.readParagraphHeight();
    this.props.dispatch(changePreviewFontSize(this.props.selectedTestCategory.value === 1 ? getFontVH() : 32));
  }

  readParagraphHeight(){

    this.intervalId = setInterval(() => {
      let downHeight = document.querySelector(".previewtextarea-down").offsetHeight;
      let upHeight = document.querySelector(".previewtextarea-up").offsetHeight;

      if (!downHeight) {
        downHeight = 1000;
      }
      if (!upHeight) {
        upHeight = 1000;
      }

      let finalHeight = 1000;

      if (upHeight > downHeight) {
        finalHeight = upHeight * 2;
      } else {
        finalHeight = downHeight * 2;
      }
      this.setState({
        paragraphHeight: finalHeight
      })
    }, 50);

  }

  componentWillUnmount(){
    clearInterval(this.intervalId);
  }

  determineProductVariantID(){
    let { upProductVariantName, downProductVariantName, upProductVariantSupportedLanguage,  downProductVariantSupportedLanguage } = this.props;

    let upVariant = _.find(this.props.product.variants, v => {

    // console.log("upVariant languages", _.map(v.languages, v => v.language_name), upProductVariantSupportedLanguage.label);
      return v.variant_name === upProductVariantName.label && _.includes(_.map(v.languages, v => v.language_name), upProductVariantSupportedLanguage.label);
    })

    let downVariant = _.find(this.props.product.variants, v => {
      return v.variant_name === downProductVariantName.label && _.includes(_.map(v.languages, v => v.language_name), downProductVariantSupportedLanguage.label);
    })



    return {
      upVariant,
      downVariant
    };

  }

  render() {
    let { upVariant, downVariant } = this.determineProductVariantID();
    let { selectedTestCategory } = this.props;
    let { paragraphHeight } = this.state;

    let heightStyleWord = {
      "height": "90vh",
      "min-height": "640px",
      "max-height": "1000px"
    }



    let heightStyleParagraph = {
      "height": "2500px",
      "min-height": "none",
      "max-height": "none"
    }

    if (selectedTestCategory.value > 0) {
      heightStyleParagraph['height'] = `${paragraphHeight + 400}px`;
    }
    return (
      <Area style={selectedTestCategory.value === 1 ? heightStyleParagraph : heightStyleParagraph }>
        <ProductVariantPreview variant ={upVariant} supportedLanguages={this.props.product.languages} testCategories={this.props.test_categories} variable_font={false} globalTestExamples={this.props.test_examples} key="up" {...upVariant} product={this.props.product} products={this.props.products} mode="up" />
        <ProductVariantPreview variant={downVariant} supportedLanguages={this.props.product.languages} testCategories={this.props.test_categories} variable_font={true} globalTestExamples={this.props.test_examples} key="down" {...downVariant} product={this.props.product} products={this.props.products} mode="down" />
      </Area>
    )
  }
}

let mapStateToProps = state => {
  return {
    selectedTestCategory: state.selectedTestCategory,
    upProductVariantName: state.upProductVariantName,
    downProductVariantName: state.downProductVariantName,
    upProductVariantSupportedLanguage: state.upProductVariantSupportedLanguage,
    downProductVariantSupportedLanguage: state.downProductVariantSupportedLanguage
  }
}

export default connect(mapStateToProps)(ProductVariantPreviewArea);
