import React from 'react';
import _ from 'lodash';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger, UserEditComponent } from '../components';
import { setCart, changeMobileMenuColors, changeLoginInfo } from '../actions';
import { connect } from 'react-redux';
import { Container, BorderArea, DesktopHr, MenuGutter, Gutter, HalfContainer, DesktopGutter, LeftColumn, RightColumn, Title, PaddingDiv, MenuRightSingleGutter } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import styled from 'styled-components';

const Fragment = React.Fragment;


const OrderList = styled.a`
  padding: 10px 15px;
  font-size: 0.9em;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    border: none;
  }
  
  span.label {
    color: #828282;
  }
  
  div.elem {
    width: calc(50% - 10px);
  }
`;

const SubTitle = styled.div`
  margin: 10px 0;
`;

const OrderListContainer = styled.div`
  border: 1px solid black;
`;


const DescDiv = styled.div`
  div, a, p, ol, li, ul {
    color: white;
  }

  a {
    text-decoration: underline;
  }
`;

class RefundPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors("#000000", "#FFFFFF"));
  }

  render() {
    let { languages, about, bwMode, typefacesPopup } = this.props;

    return (
      <Fragment>
        <Menu foregroundColor={typefacesPopup ? "#000000" : "#FFFFFF"} isLogoutEnabled={true} highlightColor="#FFFFFF" currentPage="" />
        <MenuTypefaces foregroundColor={typefacesPopup ? "#000000" : "#FFFFFF"} backgroundColor="#000000" />
        <MenuScripts foregroundColor={typefacesPopup ? "#000000" : "#FFFFFF"} languages={languages} />

        {
          typefacesPopup ?
            null :
            <Fragment>
              <BackgroundColorChanger color="#000000" bwMode="black" />
              <Container bwMode={bwMode} className="no-webfont">
                <BorderArea bwMode={bwMode}>
                  <MenuGutter />
                  <PaddingDiv>
                    <Title bwMode={bwMode}>
                      Refund Policy
                    </Title>
                  </PaddingDiv>
                </BorderArea>
                <BorderArea bwMode={bwMode}>
                  <DesktopHr bwMode={bwMode} />
                  <LeftColumn bwMode={bwMode} >
                    <PaddingDiv>
                      <DescDiv dangerouslySetInnerHTML={{ __html: about.refund }} />
                    </PaddingDiv>

                  </LeftColumn>
                </BorderArea>
              </Container>

              <Footer foregroundColor="#FFFFFF" />
            </Fragment>
        }
        <MobileMenu isLogoutEnabled={true} backgroundColor="black" foregroundColor="white" highlightColor="#FFFFFF" currentPage="" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode
  }
}

export default connect(mapStateToProps)(RefundPolicy);

