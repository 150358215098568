import { DEFAULT_WELCOME_STATE } from '../constants/defaults';
import _ from 'lodash';

export default function welcomeReducer(state = DEFAULT_WELCOME_STATE, action){

  switch(action.type) {
    case "WINDOW_RESIZE":
      return {
        ...state,
        windowWidth: action.payload.windowWidth,
        windowHeight: action.payload.windowHeight
      }
    case "CHANGE_DOWN_VARIABLES":
      return {
        ...state,
        downVariables: action.payload.downVariables
      }
    case "CHANGE_CART_MODAL":
      return {
        ...state,
        cartModal: action.payload.cartModal,
        justBoughtLength: action.payload.justBoughtLength
      }
    case "CHANGE_UP_VARIABLES":
      return {
        ...state,
        upVariables: action.payload.upVariables
      }
    case "CHANGE_CUSTOM_WORK_CONTAINER_HEIGHT":
      return {
        ...state,
        customWorkContainerHeight: action.payload.customWorkContainerHeight
      };
    case "CHANGE_CSRF_TOKEN": 
      return {
        ...state,
        csrfToken: action.payload.csrfToken
      }
    case "CHANGE_PREVIEW_FONT_SIZE":
      return {
        ...state,
        previewFontSize: action.payload.previewFontSize
      }
    case "CHANGE_SELECTED_TEST_CATEGORY":
      return {
        ...state,
        selectedTestCategory: action.payload.selectedTestCategory
      };
    case "CHANGE_MOBILE_MENU_COLORS":
      return {
        ...state,
        mobileMenuBackgroundColor: action.payload.mobileMenuBackgroundColor,
        mobileMenuForegroundColor: action.payload.mobileMenuForegroundColor
      }
    case "CHANGE_MOBILE_MENU_VISIBLE":
      return {
        ...state,
        mobileMenuVisible: action.payload.mobileMenuVisible
      }
    case 'CHANGE_UP_PRODUCT_VARIANT_NAME':
      return {
        ...state,
        upProductVariantName: action.payload.upProductVariantName
      }
    case 'CHANGE_DOWN_PRODUCT_VARIANT_NAME':
      return {
        ...state,
        downProductVariantName: action.payload.downProductVariantName
      }
    case 'CHANGE_UP_PRODUCT_VARIANT_SUPPORTED_LANGUAGE':
      return {
        ...state,
        upProductVariantSupportedLanguage: action.payload.upProductVariantSupportedLanguage
      }
    case 'CHANGE_DOWN_PRODUCT_VARIANT_SUPPORTED_LANGUAGE':
      return {
        ...state,
        downProductVariantSupportedLanguage: action.payload.downProductVariantSupportedLanguage
      }
    case 'CHANGE_UP_PRODUCT_VARIANT_ID':
      return {
        ...state,
        upProductVariantID: action.payload.upProductVariantID
      }
    case 'CHANGE_DOWN_PRODUCT_VARIANT_ID':
      return {
        ...state,
        downProductVariantID: action.payload.downProductVariantID
      }
    case 'SET_SCRIPTS_POPUP':
      return {
        ...state,
        scriptsPopup: action.payload.scriptsPopup,
        typefacesPopup: action.payload.typefacesPopup
      }
    case 'SET_TYPEFACES_POPUP':
      return {
        ...state,
        typefacesPopup: action.payload.typefacesPopup,
        scriptsPopup: !action.payload.typefacesPopup ? false : state.scriptsPopup
      }
    case 'SET_SELECTED_LANGUAGES':
      return {
        ...state,
        selectedLanguages: action.payload.selectedLanguages
      }
    case 'ADD_SELECTED_LANGUAGE':
      return {
        ...state,
        selectedLanguages: {
          ...state.selectedLanguages,
          [action.payload.id]: action.payload.selectedLanguage
        }
      }
    case 'REMOVE_SELECTED_LANGUAGE':
      let removeSelectedLanguages = {...state.selectedLanguages};
      
      delete removeSelectedLanguages[action.payload.id];
      return {
        ...state,
        selectedLanguages: removeSelectedLanguages
      };
    case "CHANGE_BW_MODE": 
      return {
        ...state,
        bwMode: action.payload.bwMode
      }
    case "SET_CART":
      return {
        ...state,
        cart: action.payload.cart
      }
    case "CHANGE_LOGIN_INFO":
      return {
        ...state,
        new_user_session_path: action.payload.new_user_session_path,
        current_user: action.payload.current_user,
        user_signed_in: action.payload.user_signed_in,
        destroy_user_session_path: action.payload.destroy_user_session_path
      }
    case "SIGN_OUT": 
      return {
        ...state,
        current_user: null,
        user_signed_in: false
      }
    default: 
      return {
        ...state
      };
  }

};