import React, { Component } from 'react'
import _ from 'lodash';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger } from '../components';
import { setCart, changeMobileMenuColors, changeLoginInfo } from '../actions';
import { connect } from 'react-redux';

import { Container, Gutter, HalfContainer, LeftColumn, RightColumn, Title, FormField, FormBorderWrapper, PrimaryButton, ButtonA, PaddingDiv, ButtonArea, FlashNotice } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components';

const SubTitle = styled.div`
  margin: 10px 0;
`;
class UpdatePasswordComponent extends Component {

  handleSubmit(e) {

  }


    render() {
      let { products, languages, typefacesPopup, bwMode, errors, flash_notice, flash_alert } = this.props;
      ;
      const tokenDom = document.querySelector("meta[name=csrf-token]");
      const csrfToken = tokenDom.content;

      return (
        <PaddingDiv>
          <SubTitle>

          </SubTitle>
          {
            _.isUndefined(errors) ?
              null :
              <DeviseError {...errors} />
          }


          {
            flash_notice ?
              <FlashNotice bwMode="white">
                {flash_notice}
              </FlashNotice>
              : null
          }


          {
            flash_alert ?
              <FlashNotice bwMode="white">
                {flash_alert}
              </FlashNotice>
              : null
          }


          <Form
            onSubmit={this.handleSubmit.bind(this)}

            initialValues={{
              user: this.props.user
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (

              <form className="edit_user" id="edit_user" action="/users" acceptCharset="UTF-8" method="post">

                <input type="hidden" name="_method" value="put" />
                <input type="hidden" name="authenticity_token" value={csrfToken} />

                <FormBorderWrapper bwMode={bwMode}>

                  <FormField bwMode={bwMode}>
                    <label>New Password</label>
                    <Field
                      name="user[password]"
                      component="input"
                      type="password"

                      autoComplete="new-password"
                    />
                  </FormField>

                  <FormField bwMode={bwMode}>
                    <label>Password confirmation</label>
                    <Field
                      name="user[password_confirmation]"
                      component="input"
                      type="password"

                      autoComplete="new-password"
                    />
                  </FormField>


                  <FormField bwMode={bwMode}>
                    <label>Current password</label>
                    <Field
                      name="user[current_password]"
                      component="input"
                      type="password"

                      autoComplete="current-password"
                    />
                  </FormField>

                </FormBorderWrapper>
                <p>should be at least 8 characters long and must contain each of the following: uppercase, lowercase, numbers and symbols.</p>
                <Gutter h={20} />
                <ButtonArea>
                  <div>
                    <PrimaryButton bwMode={bwMode} type="submit" disabled={submitting || pristine}>
                      Update
                    </PrimaryButton>
                    <ButtonA href="/users/profile" bwMode={bwMode} type="button" >
                      Back
                    </ButtonA>
                  </div>
                </ButtonArea>
              </form>
            )}
          />
        </PaddingDiv>
      )
    }
  }

  let mapStateToProps = state => {
    return {
    }
  }

export default connect(mapStateToProps)(UpdatePasswordComponent);
