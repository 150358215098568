import React from 'react';
import _ from 'lodash';
import { MobileMenu, Menu, MenuTypefaces, MenuScripts, Footer, BackgroundColorChanger } from '../components';
import { changeLoginInfo, changeBwMode, setCart, windowResize, changeMobileMenuColors} from '../actions';
import { connect } from 'react-redux';
import { BorderArea, Container, Gutter, HalfContainer, LeftColumn, RightAboutColumn } from '../stylesheets/components';
import styled from 'styled-components';
import media from '../stylesheets/media';
import { COLUMN_WIDTH } from '../constants/defaults';

const Fragment = React.Fragment;


const AboutDiv = styled.div`
  padding: 0 24px;
  margin-top: ${props => props.desktopMarginTop}px;
  max-width: ${COLUMN_WIDTH};


  ${media.mobileLarge`
    padding: 10px 15px;
    max-width: inherit;
    width: calc(100% - 30px);
  `}


  div {
    color: white;
  }

  ${media.mobileLarge`
    padding: 0 15px;
    margin-top: ${props => props.mobileMarginTop}px;
  `}
`;
const ContactDiv = styled.div`
  margin-left: 100px;
  div {
    color: white;
  }


  ${media.mobileLarge`
    margin-left: 75px;
  `}
`;

const PeopleDiv = styled.div`
  width: calc(50% - 24px);
  margin-top: 353px;
  border-right: 1px solid white;
  border-top: 1px solid white;
  padding: 24px;
  div {
    color: white;
  }
  ${media.mobileSmall`
    margin-top: 10px;`}
  ${media.mobileLarge`

    margin-top: 10px;
    border-right: none;
    width: calc(100% - 30px);
    padding: 15px;
  `}
`;

const CollaboDiv = styled.div`
  width: calc(50% - 24px);
  margin-top: 353px;
  border-top: 1px solid white;
  padding: 24px;
  div {
    color: white;
  }

  ${media.mobileLarge`
    margin-top: 10px;
    width: calc(100% - 30px);
    padding: 15px;
  `}
`;

const MobileHr = styled.hr`
  display: none;
  borderColor: white;
  ${media.mobileLarge`
    display: block;
  `}
`;


class About extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
    this.contactRef = React.createRef();
    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors("#000000", "#FFFFFF"));
  }

  componentDidMount(){
    this.changeColorlink();
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();
  }
  resizeHandler(e) {
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));

  }

  changeColorlink(){
    var links = this.contactRef.current.getElementsByTagName("a");
    for (var i = 0; i < links.length; i++) {
      if (links[i].href) {
        links[i].style.color = "#6030b8";
      }
    }
  }

  render() {
    let { products, languages, about, bwMode, typefacesPopup } = this.props;

    return (
      <Fragment>
        <Menu foregroundColor="#FFFFFF" isLogoutEnabled={true} highlightColor="#8040F6" currentPage="About" />
        <MenuTypefaces foregroundColor="#FFFFFF" backgroundColor="black" products={products} />
        <MenuScripts foregroundColor="#FFFFFF" languages={languages} />

        {
          typefacesPopup ?
          null :
          <Fragment>
            <BackgroundColorChanger color="black" bwMode="black" />

            <Container bwMode={bwMode} className="no-webfont">
              <BorderArea>
                <HalfContainer>
                  <LeftColumn>
                    <AboutDiv desktopMarginTop={350} mobileMarginTop={110} dangerouslySetInnerHTML={{ __html: about.intro_description }} />

                    <hr style={{ borderColor: "white "}} />
                    <Gutter h={20} />
                    <ContactDiv ref={this.contactRef} dangerouslySetInnerHTML={{ __html: about.contact_information }} />
                    <Gutter h={20} />
                    <hr style={{ borderColor: "white "}} />

                    <AboutDiv desktopMarginTop={24} mobileMarginTop={50}  dangerouslySetInnerHTML={{ __html: about.name_story_description }} />
                  </LeftColumn>
                  <RightAboutColumn>
                    <PeopleDiv>
                      <div>
                        People
                      </div>
                      <Gutter h={20} />
                      <div dangerouslySetInnerHTML={{ __html: about.people }} />
                    </PeopleDiv>
                    <MobileHr />
                    <CollaboDiv>
                      <div>
                        Clients and collaborators
                      </div>
                      <Gutter h={20} />
                      <div dangerouslySetInnerHTML={{ __html: about.clients_collaborators }} />
                    </CollaboDiv>
                  </RightAboutColumn>
                </HalfContainer>
              </BorderArea>


              </Container>

              <Footer />
            </Fragment>
        }


        <MobileMenu isLogoutEnabled={true} foregroundColor="white" backgroundColor="black" highlightColor="#8040F6" currentPage="About" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    typefacesPopup: state.typefacesPopup
  }
}
export default connect(mapStateToProps)(About);
