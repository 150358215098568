import React, { Component } from 'react'
import _ from 'lodash';
import axios from 'axios';
import { connect } from 'react-redux';
import { signOut } from '../actions';
import { MenuLink, MobileOnly, DesktopOnly} from '../stylesheets/components';
import styled from 'styled-components';

const Fragment = React.Fragment;
const MenuLinkMobile = styled(MenuLink)`
  padding-bottom: 8px;
`;
class UserLoginComponent extends Component {

  async handleLogout(url)  {
    try {
      const headers = {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }

      const response = await fetch(url, {method: "delete", headers});
      if (response.ok) {
        this.props.dispatch(signOut());
        document.location.href ="/";
      }

    } catch (error) {
      console.error(error);
    }
  }

  footerLogin(){

    let {windowWidth, mode, isLogoutEnabled, user_signed_in, current_user, destroy_user_session_path, new_user_registration_path } = this.props;

    if (user_signed_in) {
      return (
        <Fragment>
          <a href={`/users/profile`}>My Account</a>
          {
            windowWidth < 768 ?
              <br /> : <Fragment />
          }
          {
            isLogoutEnabled ?
            <a href="javascript:void(0);" onClick={this.handleLogout.bind(this, destroy_user_session_path)}>Sign Out</a> : null
          }
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <a href="/users/sign_in">Sign in</a> &nbsp;
          {
            windowWidth < 768 ?
            <br /> : <Fragment />
          }
          {/* <a href={new_user_registration_path}>Sign up</a> */}
        </Fragment>
      );
    }
  }
  render() {
    let { mode, bwMode, isLogoutEnabled, user_signed_in, current_user, destroy_user_session_path, new_user_registration_path, foregroundColor } = this.props;

    return (
      <Fragment>
        {
          mode === "footer" ?

          this.footerLogin()
          :
          <>
            <DesktopOnly>
              {
                user_signed_in ?
                <div>
                  <MenuLink bwMode={bwMode} firstmenuColor={foregroundColor} href={`/users/profile`}>My Account</MenuLink>

                  {
                    isLogoutEnabled ?
                    <MenuLink bwMode={bwMode} firstmenuColor={foregroundColor}  href="javascript:void(0);" onClick={this.handleLogout.bind(this, destroy_user_session_path)}>Sign Out</MenuLink> : null
                  }
                </div> :
                <div>
                    <MenuLink bwMode={bwMode} firstmenuColor={foregroundColor} href="/users/sign_in">Sign in</MenuLink>
                    {/* <MenuLink bwMode={bwMode} firstmenuColor={foregroundColor} href={new_user_registration_path}>Sign up</MenuLink> */}
                </div>
              }
            </DesktopOnly>
            <MobileOnly>
              {
                user_signed_in ?
                <div>
                  <MenuLinkMobile bwMode={bwMode} firstmenuColor={foregroundColor} href={`/users/${current_user.id}`}>My Account</MenuLinkMobile>

                  {
                    isLogoutEnabled ?
                    <MenuLinkMobile bwMode={bwMode} firstmenuColor={foregroundColor}  href="javascript:void(0);" onClick={this.handleLogout.bind(this, destroy_user_session_path)}>Sign Out</MenuLinkMobile> : null
                  }
                </div> :
                <div>
                    <MenuLinkMobile bwMode={bwMode} firstmenuColor={foregroundColor} href="/users/sign_in">Sign in</MenuLinkMobile>
                    {/* <MenuLink bwMode={bwMode} firstmenuColor={foregroundColor} href={new_user_registration_path}>Sign up</MenuLink> */}
                </div>
              }
            </MobileOnly>
          </>
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    user_signed_in: state.user_signed_in,
    current_user: state.current_user,
    bwMode: state.bwMode,
    windowWidth: state.windowWidth,
    destroy_user_session_path: state.destroy_user_session_path,
    new_user_registration_path: state.new_user_registration_path
  }
}

export default connect(mapStateToProps)(UserLoginComponent);
