import React from 'react';
import _ from 'lodash';
import { Form, Field, FormSpy } from 'react-final-form';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger, UserEditComponent } from '../components';
import { setCart, changeMobileMenuColors, changeLoginInfo } from '../actions';
import { connect } from 'react-redux';
import { FlashNotice, BorderArea, Container, Gutter, HalfContainer, DesktopGutter, MenuGutter, LeftColumn, RightColumn, Title, DesktopHr, ButtonA, PaddingDiv, ButtonAFill } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import styled from 'styled-components';
import media from '../stylesheets/media'

const Fragment = React.Fragment;


const OrderList = styled.a`
  padding: 10px 15px;
  font-size: 0.9em;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    border: none;
  }

  span.label {
    color: #828282;
  }

  div.elem {
    width: calc(50% - 10px);
  }


`;

const OrderListContainer = styled.div`
  border: 1px solid black;
`;

const UnderlineLink = styled.a`
  text-decoration: underline;
`;

const List = styled.div`
  padding: 10px 15px;
  font-size: 0.9em;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    border: none;
  }

  span.label {
    color: #828282;
  }

  ${media.mobileSmall`
    display: block;
  `}
`;

const SubTitle = styled.div`
  margin: 10px 0;
`;

const ListContainer = styled.div`
  border: 1px solid black;
`;

const ListLeft = styled.div`
  display: flex;

  ${media.mobileSmall`
    display: flex;
    justify-content: space-between;
  `}
  .title {
    /* width: */
    margin-right: 15px;
    width: 200px;

    ${media.mobileSmall`
      width: auto;

    `}
  }
  .elem {
    color: #828282;

  }
`;

const ListRight = styled.div`
  text-align: right;

  ${media.mobileSmall`
  text-align: left;
  `}
`

class TrialFonts extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors("#E0E0E0", "#000000"));
    this.consent = false;
  }
  handleNull(e) {
    return true;
  }
  checkConsent = (e) => {
    if(this.consent == false){
      alert('please Accept EULA agreement');
      e.preventDefault();
    }
  }
  changeConsent = (e) => {
    this.consent = this.consent == true ? false : true
  }
  render() {
    let { products, languages, user, orders, user_signed_in, typefacesPopup, bwMode, trial_products } = this.props;
    trial_products = _.filter(trial_products, tp => {
      return tp.trial_length > 0
    });
    return (
      <Fragment>
        <Menu foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} isLogoutEnabled={true} highlightColor="#000000" currentPage="Welcome"/>
        <MenuTypefaces foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} backgroundColor="#E0E0E0" products={products} />
        <MenuScripts foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} languages={languages} />

        {
          typefacesPopup ?
          null :
          <Fragment>
            <BackgroundColorChanger color="#E0E0E0" bwMode="white" />
            <Container bwMode={bwMode} className="no-webfont">
              <BorderArea bwMode={bwMode}>
                <MenuGutter />

                <HalfContainer>
                  <LeftColumn bwMode={bwMode}>
                    <PaddingDiv>
                      <Title bwMode={bwMode}>
                        Trial Fonts
                      </Title>
                    </PaddingDiv>
                  </LeftColumn>
                  <RightColumn>
                    <PaddingDiv>
                    </PaddingDiv>
                  </RightColumn>
                </HalfContainer>
              </BorderArea>
              <BorderArea bwMode={bwMode}>
                <DesktopHr bwMode={bwMode} />
                <HalfContainer> <LeftColumn bwMode={bwMode}>
                    <PaddingDiv>

                    Trial fonts are provided to be used in mockups before purchasing licensed files. Students are also welcome to download these to use in their class projects. Please note that they are not permitted to be used in any commercial work, self promotion of self initiated work, but only for testing purposes. Trial font packages include all weights and styles.
                    We provide trial fonts to members only. If you are already a member please <UnderlineLink href="/users/sign_in">login</UnderlineLink>, or if not please <UnderlineLink href="/users/sign_up">create an account</UnderlineLink>.
                      <Gutter h={20} />
                      {
                        !user_signed_in ?
                        null  :
                        <div>
                          <input type="checkbox" name='consent' id="consent" onChange={this.changeConsent} />
                          <label htmlFor="consent">By downloading the trial fonts, you agree to the terms set out in the <UnderlineLink href="/trial_eula">EULA agreement</UnderlineLink>.</label>
                          </div>
                      }



                    </PaddingDiv>
                  </LeftColumn>
                  <RightColumn>
                    <PaddingDiv>
                    {
                      trial_products.length > 0 ?
                        <ListContainer>
                          {
                            _.map(trial_products, (trial_product, i) => {
                              return (
                                <List key={i}>
                                  <ListLeft>
                                    <div className="title">
                                      {trial_product.product_name} Family
                                    </div>
                                    <div className="elem">
                                      {trial_product.trial_length} style{trial_product.trial_length > 1 ? "s" : ""}
                                    </div>
                                  </ListLeft>

                                  <ListRight>
                                    {
                                      user_signed_in ?
                                      <a href={`/products/${trial_product.id}/trial_download`} onClick={this.checkConsent}>
                                        Download
                                      </a> :
                                      <a href="/users/sign_in">
                                        Login to download
                                      </a>
                                    }
                                  </ListRight>
                                </List>
                              );
                            })
                          }
                        </ListContainer>
                        :
                        <Fragment>
                          No trial fonts.
                        </Fragment>
                        }
                    </PaddingDiv>
                  </RightColumn>
                </HalfContainer>
              </BorderArea>
            </Container>

            <Footer foregroundColor="#000000" />
          </Fragment>
        }
        <MobileMenu isLogoutEnabled={true} backgroundColor="#E0E0E0" foregroundColor="black" highlightColor="#000000" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode
  }
}

export default connect(mapStateToProps)(TrialFonts);
