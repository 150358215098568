import React, { Component } from 'react'
import styled from 'styled-components';
import media from '../stylesheets/media';
import { COLUMN_WIDTH } from '../constants/defaults';

const Fragment = React.Fragment;
const NewsLink = styled.a`
  display:block;
  position: relative;

  padding: 24px 32px;
  max-width: ${COLUMN_WIDTH};
  
  ${media.mobileLarge`
    padding: 10px 15px;
    max-width: inherit;
    width: calc(100% - 48px);
  `}
  
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    opacity: 1;
  }
  
  hr {
  }
  h4 {
    font-size: 1.78em;
    line-height: 1.1;
    margin-bottom: 12px;
    color: ${props => props.selected ? "#FFFFFF" : "white"};
    transition: 0.4s opacity;

    &:hover {
      opacity: 1;
    }
    
  }

  ${media.mobileLarge`
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right:15px;
    width: calc(100% - 80px);

    :first-child {
      padding-top:10px;
    }
  `}
`;

const DescDiv = styled.div`

  div {
    opacity: ${props => props.hovered ? 0.5 : 1};
    color: ${props => props.selected ? "#FFFFFF" : "white"};
    transition: 0.4s opacity;
    display: none;
  }

  div:first-child {
    display: block;
  }


  ${media.mobileLarge`
    display: none;
  `}



    &:hover {
      opacity: 1;
    }
    
`;

const DateDiv = styled.div`
  font-size: 1.0em;
  margin: 12px 0 0 0;
  color: ${props => props.selected ? "#FFFFFF" : "white"};
  transition: 0.4s opacity;

  ${media.mobileLarge`
    margin: 0;
    padding: 0;
  `}


  &:hover {
    opacity: 1;
  }
  
`;


const NewsHr = styled.hr`
  border:none;
  border-bottom: 1px solid white;
`;


const IndentHr = styled.hr`
  border:none;
  border-bottom: 1px solid white;
  width: 40px;
  position: absolute;
  left: 0; 
  top: 36px;
`;

class TruncatedNews extends Component {

  constructor(props){
    super(props);
    this.state = {
      hovered: false
    }
  }
  render() {
    let { hovered } = this.state;
    let { selected, last } = this.props;

    let style = {};

    if (selected) {
      style = { paddingLeft: 65 };
    }

    return (
      <Fragment>

        <NewsLink href={`/news/${this.props.id}`} style={style} onMouseEnter={() => {
          this.setState({
            hovered: true
          })
        }} onMouseLeave={() => {
          this.setState({
            hovered: false
          })
        }}>
          {
            selected ? 
            <IndentHr /> : null
          }

          <h4 style={{ opacity: hovered ? 0.5 : 1}}>
            {this.props.title}
          </h4>

          <DescDiv hovered={hovered} dangerouslySetInnerHTML={{ __html: this.props.description }} selected={selected}></DescDiv>

          <DateDiv style={{ opacity: hovered ? 0.5 : 1}} selected={selected}>
            {this.props.updated_at}
          </DateDiv>

        </NewsLink>
        {
          last ? 
          null :
          <NewsHr />
        }
      </Fragment>
    )
  }
}

export default TruncatedNews;