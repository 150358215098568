import React from 'react';
import _ from 'lodash';
import { MobileMenu, Menu, MenuTypefaces, MenuScripts, Footer, BackgroundColorChanger } from '../components';
import { changeLoginInfo, changeBwMode, setCart, windowResize, changeMobileMenuColors, setTypefacesPopup } from '../actions';
import { connect } from 'react-redux';
import { Container, Gutter, HalfContainer, LeftColumn, RightAboutColumn } from '../stylesheets/components';
import styled from 'styled-components';
import media from '../stylesheets/media';

const Fragment = React.Fragment;



const AboutDiv = styled.div`
  padding: 20px 40px;
  max-width: 600px;
  margin-top: ${props => props.desktopMarginTop}px;

  div {
    color: white;
  }

  ${media.mobileLarge`
    padding: 0 15px;
    margin-top: ${props => props.mobileMarginTop}px;
  `}
`;
const ContactDiv = styled.div`
  margin-left: 100px;
  div {
    color: white;
  }


  ${media.mobileLarge`
    margin-left: 75px;
  `}
`;

const PeopleDiv = styled.div`
  width: calc(50% - 40px);
  margin-top: 750px;
  border-right: 1px solid white;
  border-top: 1px solid white;
  padding: 40px;
  div {
    color: white;
  }
  ${media.mobileLarge`
    margin-top: 10px;
    border: none;
    width: 100%;
    padding: 15px;
  `}
`;

const CollaboDiv = styled.div`
  width: calc(50% - 41px);
  margin-top: 750px;
  border-top: 1px solid white;
  padding: 40px;
  div {
    color: white;
  }

  ${media.mobileLarge`
    margin-top: 10px;
    border: none;
    width: 100%;
    padding: 15px;
  `}
`;

const MobileHr = styled.hr`
  display: none;
  ${media.mobileLarge`
    display: block;
  `}
`;


class Products extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));

    this.contactRef = React.createRef();
    this.props.dispatch(setTypefacesPopup(true));
    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors("#000000", "#FFFFFF"));
  }

  componentDidMount() {
    // this.changeColorlink();

    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();
  }

  resizeHandler(e) {
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));

  }


  render() {
    let { products, languages, about, bwMode, typefacesPopup } = this.props;

    return (
      <Fragment>
        <Menu foregroundColor="#FFFFFF" isLogoutEnabled={true} highlightColor="#8040F6" currentPage="Typefaces" />
        <MenuTypefaces foregroundColor="#FFFFFF" products={products} />
        <MenuScripts foregroundColor="#FFFFFF" languages={languages} currentPage="Products" />
        <BackgroundColorChanger color="black" bwMode="black" />
        {/* <Footer foregroundColor="white"/> */}
        <MobileMenu isLogoutEnabled={true} backgroundColor="black" foregroundColor="white" highlightColor="#8040F6" currentPage="Typefaces" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    typefacesPopup: state.typefacesPopup
  }
}
export default connect(mapStateToProps)(Products);
