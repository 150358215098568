import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CartItem } from './';
import { numberWithDelimiter } from '../utils';
import {  Gutter, ButtonA, ButtonAPrice, PrimaryButton, ButtonAWhite, FinalButtonArea } from '../stylesheets/components'
import media from '../stylesheets/media';


const CartContainer = styled.div`
  border:1px solid ${props => props.bwMode === "black" ? "white" : "black"};

  /* div, p, a{
    color: ${props => props.bwMode === "black" ? "white" : "black"};
  } */

  div.title {
    color: ${props => props.bwMode === "black" ? "white" : "black"};
  }
`;

const Fragment = React.Fragment;
const Header = styled.div`

  display: flex;
  border-bottom: 1px solid ${props => props.bwMode === "black" ? "white" : "black"};


  div.title {
    width: 40%;
  }
  div.licenses {
    width: 50%;


  }

`;


const TotalArea = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${props => props.bwMode === "black" ? "white" : "black"};
  padding-top: 10px;
  margin-top: 10px;


  div {
    color: ${props => props.bwMode === "black" ? "white" : "black"};
  }
`;


const Disclaimer = styled.div`
  margin: 20px 0;
  color: #828282;
  font-size:0.8em;
  line-height: 1.2;
`;

const Empty = styled.p`
  padding: 20px;
`;

class Cart extends Component {
  render() {
    let { cart, checkoutButton, bwMode } = this.props;

    return (
      <Fragment>
        <h3>
          Your font licenses:
        </h3>
        <Gutter h={15} />
        <CartContainer bwMode={bwMode}>

          {
            cart.cart_items.length > 0 ?
            <Fragment>

              {
                _.map(cart.cart_items, item => {
                  return (
                    <CartItem {...item} editable={this.props.editable} licenses={this.props.licenses} key={item.id} />
                  )
                })
              }

            </Fragment> :
            <Empty>
              Cart is Empty.
            </Empty>
          }

        </CartContainer>
        <Disclaimer>
All licenses (desktop, web and app) require a one-time payment for perpetual use, with no recurring fees. Web fonts require a single payment, which is based on the number of unique visitors per month. If the number of monthly unique visitors exceeds the terms of your license, we ask that you upgrade to the appropriate license. Thanks, from the UT team.        </Disclaimer>
        <TotalArea bwMode={bwMode}>

          <div>
            Total Amount
          </div>
          <div>
            €{numberWithDelimiter(cart.total)}
          </div>
        </TotalArea>
        <Gutter h={20} />
        {
          cart.isCheckoutable && checkoutButton ?
            <FinalButtonArea>
              <ButtonAPrice bwMode={bwMode}>
                €{numberWithDelimiter(cart.total)}
              </ButtonAPrice>
              <ButtonAWhite bwMode={bwMode} href="/order/new">Checkout</ButtonAWhite>
            </FinalButtonArea> : null
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    cart: state.cart,
    bwMode: state.bwMode
  }
}

export default connect(mapStateToProps)(Cart);
