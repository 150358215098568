import React, { Component } from 'react'
import styled from 'styled-components';
import media from '../stylesheets/media';
import { connect } from 'react-redux';
import { Gutter, PaddingDiv } from '../stylesheets/components';
import { changeCustomWorkContainerHeight } from '../actions';
import { COLUMN_WIDTH } from '../constants/defaults';

const CustomLayoutContainer = styled.div`
  position: absolute;
  left: 24px;
  top: ${props => props.hrTop + 10}px;
  width: calc(100vw - 80px);

  div.relative-wrapper {
    position: relative;
  }

  ${media.mobileLarge`
    display: none;
  `}
`;

const CenterPhotoArea = styled.div`
  clear:both;
  display: flex;
  justify-content: center;


  p.caption {
    color:${props => props.foregroundColor};
    font-size: 0.8em;
  }
`;


const CenterPhotoImg = styled.img`

`;


const PhotoArea = styled.div`
  float: ${props => props.floatMode};
  margin-right: ${props => props.floatMode === "right" ? 0 : "24px"};
  margin-left: ${props => props.floatMode === "left" ? 0 : "24px"};
  margin-bottom: 24px;
  width: ${COLUMN_WIDTH};


  ${media.smallDesktop`
      margin-right: 0;
      margin-left: 0;
  `}

  
  p.caption {
    font-size: 0.8em;
    color:${props => props.foregroundColor};
  }
`;

const PhotoImg = styled.img`
  width: 100%;
`;

const CustomDescDiv = styled(PaddingDiv)`
  padding: 20px 0;
  margin-right: calc((100vw – 64px) / 23 * 1 - 55px);
  float:left;

/* 
  @media (max-width: 2559px) {
    margin-right: 2.2vw;
  }
  @media (max-width: 2048px) {
    margin-right: 1.69vw;
  } */
  ${media.smallDesktop`

    margin-right: calc((100vw – 64px) / 23 * 1 - 20px);
  `}

  ${media.mobileLarge`
    padding: 10px 15px;
  `}

  div, p, a {
    color: ${props => props.foregroundColor};


    img {
      width: 100%;
      height: auto;
    }
    
  }

`;

const HeroPhoto = styled.div`
  position: absolute;
  /* width: calc((((100vw – 64px) / 23) * 11)); */
  width: calc((((100vw – 64px) / 23) * 12) - 40px);
  right: 0;
  top: -300px;


  ${media.smallDesktop`
    top: -250px;
  `}

  img {
    width: 100%;
    height: auto;
  }


  p.caption {
    color:${props => props.foregroundColor};
    font-size: 0.8em;
  }
`;


const HeroPhotoGutter = styled.div`
  float: left;
  width: ${COLUMN_WIDTH};
  height: ${props => props.h - 300}px;

  ${media.smallDesktop`
    height: ${props => props.h - 250}px;
  `}

`;

class CustomWorkLayout extends Component {
  constructor(props){
    super(props);
    this.containerRef = React.createRef();
    this.heroRef = React.createRef();

    this.state = {
      heroImageHeight: 500
    };

  }

  componentDidMount() {
    this.updateContainerHeight();
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();

    // var i = 0;
    // this.intervalId = setInterval(() => {
    //   this.updateContainerHeight();
    //   i++;
    //   console.log("update");
    //   if (i >= 100) {
    //     clearInterval(this.intervalId);
    //   }
    // }, 1000);
  }

  componentWillUnmount() {
    // clearInterval(this.intervalId);
    
  }

  updateContainerHeight(){

    let container = this.containerRef.current;
    this.props.dispatch(changeCustomWorkContainerHeight(container.getBoundingClientRect().height + 50));

    let heroImg = this.heroRef.current;
    // console.log(heroImg.getBoundingClientRect().height + 50);
    this.setState({
      heroImageHeight: heroImg.getBoundingClientRect().height + 40
    })
  }

  resizeHandler(e) {
    this.updateContainerHeight();
  }

  handleImageLoadComplete(e) {
    this.updateContainerHeight();
  }

  render() {
    let { custom_work, hrTop } = this.props;
    let { heroImageHeight } = this.state;
    let heroPhoto = _.find(custom_work.custom_work_photos, p => p.hero);
    let restPhotos = _.filter(custom_work.custom_work_photos, p => !p.hero);

    // console.log(custom_work.foreground_color);
    return (
      <CustomLayoutContainer ref={this.containerRef} hrTop={hrTop}>
        <div className="relative-wrapper">
          {
            heroPhoto ? 
            <HeroPhoto>
              <img src={heroPhoto.photo} ref={this.heroRef} alt="universal first custom work" onLoad={this.handleImageLoadComplete.bind(this)} /><br />
              <p className="caption" foregroundColor={custom_work.foreground_color}>
                {heroPhoto.caption}
              </p>
            </HeroPhoto> : null
          }
          <CustomDescDiv foregroundColor={custom_work.foreground_color} dangerouslySetInnerHTML={{ __html: custom_work.description }} />
          <HeroPhotoGutter h={heroImageHeight} />
          {
            _.map(restPhotos, p => {

              if (p.float_mode === "center") {
                return (
                  <CenterPhotoArea key={p.id} floatMode={p.float_mode} foregroundColor={custom_work.foreground_color}>
                    <CenterPhotoImg src={p.photo} onLoad={this.handleImageLoadComplete.bind(this)} /><br />
                    <p className="caption">
                      {p.caption}
                    </p>
                  </CenterPhotoArea>
                );
              } else {
                return (
                  <PhotoArea key={p.id} floatMode={p.float_mode} foregroundColor={custom_work.foreground_color}>
                    <PhotoImg src={p.photo} onLoad={this.handleImageLoadComplete.bind(this)} /><br />
                    <p className="caption">
                      {p.caption}
                    </p>
                  </PhotoArea>
                );
              } 
              
            })
          }
        </div>

      </CustomLayoutContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    customWorkContainerHeight: state.customWorkContainerHeight
  }
}

export default connect(mapStateToProps)(CustomWorkLayout);