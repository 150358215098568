import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';

const ImageContainer = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  width: 150%;
  transform: translate(0, -50%);
`;

class RotateImage extends Component {
  constructor(props){
    super(props);

    this.state = {
      imgWidth: 1024,
      imgHeight: 768
    };
   
  }


  handleOnLoad(e) {
    this.setState({
      imgWidth: e.target.naturalWidth,
      imgHeight: e.target.naturalHeight
    });
  }

  setStyle() {
    let { windowWidth, windowHeight, angle } = this.props;
    let { imgWidth, imgHeight } = this.state;
    let styleResult = {};
  
    if ((windowWidth * 0.5) / imgWidth > windowHeight / imgHeight) {
      styleResult = {
        width: "50vw",
        height: "auto",
        transform: `translate(0, -50%) rotate(${angle}deg)`
      }

    } else {
      styleResult = {
        width: "auto",
        height: "50vh",
        transform: `translate(0, -50%) rotate(${angle}deg)`
      }
    }
    return styleResult;
  }


  render() {
    let styleResult = this.setStyle();
    return (
      <ImageContainer mode={this.props.mode} src={this.props.src} onLoad={this.handleOnLoad.bind(this)} style={styleResult} alt={this.props.alt} />
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight
  }
};

export default connect(mapStateToProps)(RotateImage);