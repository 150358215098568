import React from 'react';
import _ from 'lodash';
import { MenuTypefaces, Menu, MenuScripts, BackgroundColorChanger } from '../components';
import { changeLoginInfo } from '../actions';
import { connect } from 'react-redux';
import { Container } from '../stylesheets/components';

const Fragment = React.Fragment;
class BuyingGuide extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
  }

  render() {
    let { products, languages, typefacesPopup } = this.props;
    return (
      <Fragment>
        <Menu isLogoutEnabled={true} />
        <MenuTypefaces products={products} />
        <MenuScripts languages={languages} />

        {
          typefacesPopup ? 
          null :
          <Fragment>
            <BackgroundColorChanger color="white" bwMode="white" />
            <Container className="no-webfont">
              Buying Guide
            </Container>
          </Fragment>
        }
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup
  }
}

export default connect(mapStateToProps)(BuyingGuide);