import React from 'react';
import _ from 'lodash';
import { MobileMenu, Footer, Menu, CustomWorkThumb, MenuTypefaces, MenuScripts, BackgroundColorChanger } from '../components';
import { changeMobileMenuColors, changeLoginInfo, setCart, windowResize } from '../actions';
import { connect } from 'react-redux';
import { BorderArea, MenuArticleGutter, MenuGutter, Container, Gutter, HalfContainer, LeftColumn, RightColumn } from '../stylesheets/components';
import styled from 'styled-components';

const Fragment = React.Fragment;
const BlackLine = styled.hr`
  border: none;
  border-bottom: 1px solid black;
  margin: 0;
`;

class CustomWorks extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));

    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors("#ffdc0b", "#000000"));
  }
   
  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();

  }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));
    
  }

  render() {
    let { custom_works, windowWidth, products, languages, typefacesPopup, bwMode } = this.props;
    
    let customWorksLeft = _.filter(custom_works, (c, i) => i % 2 === 0);
    let customWorksRight = _.filter(custom_works, (c, i) => i % 2 !== 0);
    
    return (
      <Fragment>

        <Menu foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} isLogoutEnabled={true} highlightColor="#13ac5b" currentPage="Custom Work" />
        <MenuTypefaces foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} backgroundColor="#FFEC7D" products={products} />
        <MenuScripts foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} languages={languages} />



        {
          typefacesPopup ? 
          null :
          <Fragment>
            <Container className="no-webfont">
              <BackgroundColorChanger color="#FFEC7D" bwMode="white" />
              <BorderArea bwMode={bwMode}>
                <HalfContainer>
                    <LeftColumn bwMode={bwMode}>
                      <MenuGutter />
                      {
                        _.map(customWorksLeft, (custom_work, i) => {
                          return (
                            <Fragment>
                              <CustomWorkThumb key={i} {...custom_work} />
                              {
                                customWorksLeft.length - 1 > i ?
                                <BlackLine />
                                : 
                                (
                                  windowWidth < 768 ?
                                  <BlackLine /> : null
                                )
                              }
                            </Fragment>
                          )
                        })
                      }
                    </LeftColumn>
                    <RightColumn>
                      <MenuArticleGutter />
                      {
                        _.map(customWorksRight, (custom_work, i) => {
                          return (
                            <Fragment>
                              <CustomWorkThumb key={i} {...custom_work} />
                              {
                                customWorksRight.length - 1 > i ?
                                <BlackLine /> :  null
                                
                              }
                            </Fragment>
                          )
                        })
                      }
                    </RightColumn>
                  </HalfContainer>
              </BorderArea>
                
              </Container>
              <Footer foregroundColor="#000000" />
          </Fragment>
        }
        <MobileMenu isLogoutEnabled={true} backgroundColor="#FFEC7D" foregroundColor="black" highlightColor="#13ac5b" currentPage="Custom Work" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode,
    windowWidth: state.windowWidth
  }
}

export default connect(mapStateToProps)(CustomWorks);