import React, { Component } from 'react'
import { Slider } from '@material-ui/core';
import { changeUpProductVariantName, changeDownProductVariantName, changeUpProductVariantSupportedLanguage, changeDownProductVariantSupportedLanguage } from '../actions';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Select from 'react-select';
import { TestStringEmpty } from '../stylesheets/components';
import media from '../stylesheets/media';
import { VariableController } from './';

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;

  top: ${props => props.mode === "up" ? "calc(50% - 60px)" : "calc(100% - 60px)"};
  left: 50%;
  z-index: ${props => props.mode === "up" ? 10 : 9};
  transform: translate(-50%, 0);


  ${media.mobileLarge`
    width: calc(100% - 30px);
    padding: 0 15px;
    justify-content: space-between;
    margin-top: ${props => props.mode === "down" ? "10px" : 0};
    margin-bottom: ${props => props.mode === "up" ? "35px" : 0};
  `}
`;

const SelectContainer = styled.div`
  width: 250px;
  margin-right: 20px;

  &.test-category-select {
    ${media.mobileLarge`
      display: none;
    `}
  }


  &.language-select {
    ${media.mobileLarge`
      width: calc(50% - 10px);
      margin:0;
    `}
  }
`;

const SliderContainer = styled.div`
  width: 250px;
  margin-top: 5px;


  ${media.mobileLarge`
    display: none;
  `}
`;

const VariantTitle = styled.div`
  width: 250px;
  margin: 0 auto;

  ${media.mobileLarge`
    width: calc(50% - 10px);
    margin: 0;
  `}

`;


class PreviewToolbar extends Component {
  constructor(props){
    super(props);
    this.state = {
      variantTitleWidth: 141,
      languageTitleWidth: 50
    }
    this.variantTitleTestStringRef = React.createRef();
    this.languageTitleTestStringRef = React.createRef();
  }
  onSliderChange(e){

  }


  componentDidMount(){
    _.delay(() => {
      this.setState({
        variantTitleWidth: this.variantTitleTestStringRef.current.offsetWidth,
        languageTitleWidth: this.languageTitleTestStringRef.current.offsetWidth
      });
    }, 100);

  }


  handleVariantChange(e) {
    if (this.props.mode === "up") {
      console.log(e);
      this.props.dispatch(changeUpProductVariantName(e));
    } else {
      this.props.dispatch(changeDownProductVariantName(e));
    }

    _.delay(() => {
      this.setState({
        variantTitleWidth: this.variantTitleTestStringRef.current.offsetWidth
      });
    }, 100);
  }

  handleLanguageChange(e){
    if (this.props.mode === "up") {
      this.props.dispatch(changeUpProductVariantSupportedLanguage(e));
    } else {
      this.props.dispatch(changeDownProductVariantSupportedLanguage(e));
    }

    // this.props.onSupportedLanguageChange(e);
    _.delay(() => {
      this.setState({
        languageTitleWidth: this.languageTitleTestStringRef.current.offsetWidth
      });
    }, 100);
  }

  render() {
    let { bwMode, variantOptions, upProductVariantSupportedLanguage, downProductVariantSupportedLanguage, upProductVariantName, downProductVariantName } = this.props;
    let { variantTitleWidth, languageTitleWidth } = this.state;
    let selectStyles = {
      control: (provided, state) => {
        return {
          ...provided,
          transition: "none",
          border: 'none !important',
          boxShadow: "none",
          background: "transparent",
          overflow: 'visible',
          "&:hover": {
            border: "none"
          }
        };
      },
      singleValue: (provided, state) => ({
        ...provided,
        color: bwMode === "white" ? "black" : "white",
        border: "none",
        marginLeft: 0,
        borderRadius: 0,
        overflow: 'visible',
        "&:after": {
          content: `url(/assets/arrow_${bwMode === "black" ? "white" : "black"}.svg)`,
          paddingLeft: 10,
          top: -2,
          position: "relative"
        }
      }),
      indicatorSeparator: (provided, state) => ({
        display: "none"
      }),
      menu: (provided, state) => {
        return {
          ...provided,
          borderRadius: 16,
          padding: 5,
          margin: 0,
          zIndex: 999,
          width: 200,
          // maxHeight: 264,
          left: -15,
          overflow: "hidden",
          boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.75)",
          backgroundColor: bwMode === "black" ? "white" : "black",
          color: bwMode === "black" ? "black" : "white"
        }
      },
      menuList: (provided, state) => {
        return {
          ...provided,
          overflowX: "hidden"
        }
      },
      option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        border: "none",
        paddingLeft: 11,
        backgroundImage: `linear-gradient(to right, ${this.props.bwMode} 25%, rgba(255,255,255,0) 0%)`,
        backgroundPosition: "bottom",
        backgroundSize: "4px 1px",
        backgroundRepeat: "repeat-x",
        '&:hover, &:active': {
          backgroundColor: bwMode === "black" ? "white" : "black"
        },
        backgroundColor: this.props.bwMode === "black" ? "white" : "black",
        color: bwMode === "black" ? "black" : "white",
        "&:first-child": {
          paddingTop: 0
        },
        "&:last-child": {
          backgroundImage: "none",
          paddingBottom: 0,
          marginBottom: 0
        }
      })
    }

    let selectedSupportedLanguage = this.props.mode === "up" ? upProductVariantSupportedLanguage : downProductVariantSupportedLanguage;

    let selectedVaraintValue = this.props.mode === "up" ? upProductVariantName : downProductVariantName;

    // debugger;

    return (
      <BarContainer mode={this.props.mode}>
        <TestStringEmpty ref={this.variantTitleTestStringRef}>
          { selectedVaraintValue.label }
        </TestStringEmpty>
        <TestStringEmpty ref={this.languageTitleTestStringRef}>
          { selectedSupportedLanguage.label }
        </TestStringEmpty>
        <VariantTitle bwMode={bwMode} style={{width: variantTitleWidth }}>
          <Select
            components={{ DropdownIndicator:() => null, IndicatorsContainer:() => null }}
            styles={selectStyles}
            isSearchable={false}
            onChange={this.handleVariantChange.bind(this)}
            value={selectedVaraintValue}
            options={variantOptions}>
          </Select>
        </VariantTitle>
        <SelectContainer className="language-select" style={{width: languageTitleWidth }}>
          <Select
            components={{ DropdownIndicator:() => null, IndicatorsContainer:() => null }}
            styles={selectStyles}
            isSearchable={false}
            onChange={this.handleLanguageChange.bind(this)}
            value={selectedSupportedLanguage}
            options={this.props.supportedLanguages}>
          </Select>
        </SelectContainer>
        {
          this.props.variableFont ?
          <VariableController mode={this.props.mode} />
          : null
        }
      </BarContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    upProductVariantSupportedLanguage: state.upProductVariantSupportedLanguage,
    downProductVariantSupportedLanguage: state.downProductVariantSupportedLanguage,
    upProductVariantName: state.upProductVariantName,
    downProductVariantName: state.downProductVariantName
  }
}

export default connect(mapStateToProps)(PreviewToolbar);
