import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import {  MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger, CenterStretchedImage, MobileMenu } from './';
import { arc } from 'd3-shape';
import media from '../stylesheets/media';
import invert from 'invert-color';
import _ from 'lodash';
import FontFaceObserver from 'fontfaceobserver';

// "Magnify" scroll area with this factor
const scrollFactor = 3;

const ScrollBox = styled.div`
  height: 0;
  scroll-snap-align: start;
`;

const BigFauxBox = styled.div`
  height: ${scrollFactor}00vh;
  scroll-snap-align: none;
`;

const SmallFauxBox = styled.div`
  height: 100vh;
  scroll-snap-align: none;
`;

const ScrollContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;

  .svg-defs {
    position: absolute;
    top: 0;
  }

  &.snap {
    scroll-snap-type: y proximity;
  }
`;

const MainPageSticky = styled.div`
  position: sticky;
  top: 0;
  height: 0;

  &.bottom {
    z-index: 1;
  }

  &.top {
    z-index: 2;
  }

  &:not(.bottom):not(.top) * {
    animation-play-state: paused !important;
  }
`;

const MainPageContainer = styled.div`
  position: absolute;
  left:0;
  top:0;
  width: 100vw;
  height: 100vh;
  background-color: black;
`;

const Left = styled.div`
  position: absolute;
  background-color: ${props => props.backgroundColor};
  left: 0;
  top: 0;
  width: 50%;
  height: 100vh;
  overflow:hidden;
  border-right: 1px solid ${props => props.foregroundColor};
  cursor: pointer;
`;

const Right = styled.div`
  position: absolute;
  background-color: ${props => props.backgroundColor};
  right: 0;
  top: 0;
  width: calc(50% - 1px);
  height: 100vh;
  overflow:hidden;
  cursor: pointer;
`;


const ProductName = styled.div`
  position:absolute;
  top: 84%;
  right: calc(50% + 50px);
  display: flex;
  justify-content: space-between;
  font-size:1.0em;
  color: ${props => props.color};
`;

const ArrowButton = styled.a`
  position:absolute;
  top: 84%;
  left: 50%;
  transform: translate(-50%,-13px);
`;

const LanguageName = styled.div`
  position:absolute;
  top: 84%;
  left: calc(50% + 50px);
  font-size:1.0em;

  ${media.mobileLarge`
    width: calc(50% - 65px);
    /* text-overflow: ellipsis; */
    overflow: hidden;
  `}

`;

const LeftText = styled.div`
  font-size: 12.0em;
  position: absolute;
  left: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  text-align: center;
  line-height: 1.0;
  div {
    color: ${props => props.color};
    font-family: ${props => props.fontFamily }, 'AdobeBlank';
    font-weight: ${props => props.fontWeight };
    font-style: ${props => props.fontStyle };
  }
  color: ${props => props.color};
  text-align: center;
  z-index: 5;
  opacity: 0;
  transition: opacity 1s;

  .fonts-loaded & {
    opacity: 1;
  }


  ${media.padLandscape`
    font-size: 7.0em;
    left: 92%;
  `}


  ${media.mobileLarge`
    font-size: 7.0em;
    left: 95%;
  `}

  ${media.mobileSmall`
    font-size: 12.0em;
    left: 80%;
  `}
`;

const RightText = styled.div`
  font-size: 12.0em;
  position: absolute;
  left: 0;
  transform: translate(-50%, -50%);
  top: 50%;
  text-align: center;
  line-height: 1.0;
  div {
    color: ${props => props.color};
    font-family: ${props => props.fontFamily}, 'AdobeBlank';
    font-weight: ${props => props.fontWeight};
    font-style: ${props => props.fontStyle };
  }
  color: ${props => props.color};

  text-align: center;
  z-index: 5;
  opacity: 0;
  transition: opacity 1s;

  .fonts-loaded & {
    opacity: 1;
  }

  ${media.padLandscape`
    font-size: 7.0em;
    left: 15%;
  `}

  ${media.mobileLarge`
    font-size: 7.0em;
    left: 10%;
  `}

  ${media.mobileSmall`
    font-size: 12.0em;
    left: 20%;
  `}
`;


class MainPageRenderer extends Component {
  constructor(props){
    super(props);

    this.state = {
      angle: 0,
      containerIdx: 0,
      shouldSnap: false,
      boxSize: window.innerHeight * scrollFactor,
      isLoading: true
    };

    // Setting a very long timeout here, as there is no
    // point showing the content when the font hasn't
    // loaded. If font loading fails after this time,
    // content will remain hidden!
    const timeout = 10000; // milliseconds
    const font = new FontFaceObserver("Ilai");
    font.load(null, timeout).then(() => {
      this.setState({isLoading: false})
    }, err => {
      console.error('Failed to load fonts!', err)
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize, { passive: true });
  }

  getClipPaths() {
    const { angle } = this.state;
    const clipLeftPath = arc()
      .innerRadius(0)
      .outerRadius(3000)
      .startAngle(Math.PI)
      .endAngle(Math.PI + Number(angle))();
    const clipRightPath = arc()
        .innerRadius(0)
        .outerRadius(3000)
        .startAngle(0)
        .endAngle(angle)();
    return {clipLeftPath, clipRightPath};
  }

  handleResize = _.throttle((e) => {
    this.setState({
      boxSize: window.innerHeight * scrollFactor
    });
  }, 50);

  handleScroll = _.throttle((e) => {
    if(!e.target) return; // If synthetic event has been destroyed
    const { boxSize } = this.state;
    const finalProgress = e.target.scrollTop / boxSize;
    const angle = (finalProgress - Math.floor(finalProgress)) * Math.PI;
    const shouldSnap = finalProgress - Math.floor(finalProgress) > 0.5;

    this.setState({
      angle: angle,
      containerIdx: Math.floor(finalProgress),
      shouldSnap: shouldSnap
    });
  }, 10);

  handleBackClick(mp){
    document.location.href = `/products/${mp.product.permalink}`;
  }

  render() {
    let { mainPages, windowWidth, windowHeight, typefacesPopup, products, languages, main_pages, bwMode } = this.props;
    let { containerIdx, shouldSnap, isLoading } = this.state;

    let scrollBoxes = [];
    for (let i = 0; i < mainPages.length-1; i++) {
      scrollBoxes.push(
        <React.Fragment key={`scrollboxes_${i}`}>
          <BigFauxBox key={`fb${i}`} />
          <ScrollBox key={`sb${i}`} />
        </React.Fragment>
      )
    }

    return (
      <ScrollContainer style={{ height: windowHeight }} className={`mainpage-container ${shouldSnap ? 'snap' : ''}`} onScroll={this.handleScroll.bind(this)}>
        {
          _.map(mainPages, (mp, i) => {
            const variantIds = _.map(_.filter(mp.product.variants, v => !v.complete), v => v.id);

            let mainPageClass = isLoading ? "" : "fonts-loaded ";
            if (i === containerIdx) {
              mainPageClass += "bottom";
            } else if (i === containerIdx + 1) {
              mainPageClass += "top";
            }

            return (
              <MainPageSticky className={mainPageClass} key={i}>
                <Menu foregroundColor={mp.color} currentPage="Welcome" isLogoutEnabled={true} />
                <MenuTypefaces foregroundColor={mp.color} backgroundColor={mp.left_background_color} products={products} />
                <MenuScripts foregroundColor={mp.color} languages={languages} />
                <EmblemArea>
                  <Emblem fill={mp.color} />
                </EmblemArea>
                <MainPageContainer style={{ height: windowHeight}} key={mp.id}>
                  <Left className={mainPageClass} style={{ height: windowHeight}} foregroundColor={mp.color} backgroundColor={mp.left_background_color} onClick={this.handleBackClick.bind(this, mp)}>
                    {
                      mp.left_image ? <CenterStretchedImage src={windowWidth < 768 ? mp.left_mobile_image : mp.left_image} alt="left" /> : null
                    }
                    <LeftText
                      fontFamily={ mp.left_product_variant.product_name }
                      fontWeight={ mp.left_product_variant.weight }
                      fontStyle={ mp.left_product_variant.style }
                      color={mp.color} dangerouslySetInnerHTML={{ __html: windowWidth < 768 ? mp.left_mobile_text : mp.left_text }} />
                  </Left>
                  <Right className={mainPageClass} style={{ height: windowHeight}} backgroundColor={mp.right_background_color} onClick={this.handleBackClick.bind(this, mp)}>
                    {
                      mp.right_image ? <CenterStretchedImage src={windowWidth < 768 ? mp.right_mobile_image : mp.right_image} alt="right" /> : null
                    }

                    <RightText
                      fontFamily={mp.right_product_variant.product_name}
                      fontWeight={mp.right_product_variant.weight}
                      fontStyle={mp.right_product_variant.style}
                      color={mp.color} dangerouslySetInnerHTML={{ __html: windowWidth < 768 ? mp.right_mobile_text : mp.right_text }} />
                  </Right>
                  {
                    _.map(variantIds, vid => {
                      return (
                        <link rel="stylesheet" key={vid} href={`/api/product_variants/${vid}.css`} />
                      );
                    })
                  }
                  <style dangerouslySetInnerHTML={{ __html: mp.css }} />

                  <ProductName color={mp.color}>
                    {mp.product.product_name}
                  </ProductName>
                  <ArrowButton href={`/products/${mp.permalink}`}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
                      <circle cx="24" cy="24" r="24" fill={mp.color} />
                      <path d="M15.1875 23.7872C15.0625 23.9122 15 24.0841 15 24.3029C15 24.5216 15.0703 24.7013 15.2109 24.8419C15.3516 24.9825 15.5312 25.0529 15.75 25.0529H30.4219L27.2344 28.2872C26.8594 28.631 26.8516 28.9825 27.2109 29.3419C27.5703 29.7013 27.9219 29.6935 28.2656 29.3185L32.7656 24.8185C32.8594 24.756 32.9219 24.6779 32.9531 24.5841C32.9844 24.4904 33 24.3966 33 24.3029C33 24.2091 32.9844 24.1154 32.9531 24.0216C32.9219 23.9279 32.8594 23.8497 32.7656 23.7872L28.2656 19.2872C27.9219 18.9122 27.5703 18.9044 27.2109 19.2638C26.8516 19.6232 26.8594 19.9747 27.2344 20.3185L30.4219 23.5529H15.75C15.5312 23.5529 15.3438 23.631 15.1875 23.7872Z" fill={invert(mp.color, true)} />
                    </svg>
                  </ArrowButton>
                  <LanguageName>
                    {
                      _.map(mp.product.languages, (l, idx) => {
                        let len = mp.product.languages.length;
                        return (
                          <span style={{color: mp.color}} key={l.language_name}>{l.language_name}{idx === len - 1 ? "" : <span>,&nbsp;</span>}</span>
                        )
                      })
                    }
                  </LanguageName>
                </MainPageContainer>
              </MainPageSticky>
            )
          })
        }

        { scrollBoxes }
        <SmallFauxBox style={{ height: windowHeight }} />
      </ScrollContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight
  };
}
export default connect(mapStateToProps)(MainPageRenderer);
