import React from 'react';
import _ from 'lodash';
import { MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger, DeviseError, MobileMenu } from '../components';
import { changeLoginInfo, windowResize } from '../actions';
import { connect } from 'react-redux';
import { Container, Gutter, HalfContainer, BorderArea, LeftColumn, RightColumn, Title, FormField, FormBorderWrapper, PrimaryButton, ButtonA, PaddingDiv, ButtonArea, DesktopHr, OneLineDesktopGutter } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import media from '../stylesheets/media';

const Fragment = React.Fragment;
const ForgotPasswordArea = styled.div`
  text-align: right;
  ${media.mobileSmall`
    text-align: left;
  `}
`;
const ButtonAreaMainBtns = styled.div`
  ${media.mobileSmall`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: flex-start;

    button {
      margin-bottom: 5px;
    }
  `}
`;

const MenuGutter = styled(Gutter)`
  height: 350px;

  ${media.mobileSmall`
    height: 100px;
  `}
`;
class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
    
  }
  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();
  }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));
    
  }
  
  handleSubmit(e) {
    // debugger;
  }

  render() {
    let { products, languages, user, windowWidth, typefacesPopup, errors, bwMode } = this.props;

    const tokenDom = document.querySelector("meta[name=csrf-token]");
    const csrfToken = tokenDom.content;

    return (
      <Fragment>
        <Menu isLogoutEnabled={true} />
        <MenuTypefaces foregroundColor="black" backgroundColor="white" products={products} />
        <MenuScripts languages={languages} />

        {
          typefacesPopup ?
            null :
            <Fragment>
              <BackgroundColorChanger color="#E0E0E0" bwMode="white" />

              <Container bwMode={bwMode} className="no-webfont">
                <BorderArea foregroundColor="black">
                  <MenuGutter />
                  <PaddingDiv>
                    <Title bwMode={bwMode}>
                      Welcome!
                    </Title>
                  </PaddingDiv>
                </BorderArea>
                <BorderArea foregroundColor="black">
                  <DesktopHr foregroundColor="black" />
                  <LeftColumn bwMode={bwMode}>


                    <PaddingDiv>

                      {
                        _.isUndefined(errors) ?
                          null :
                          <DeviseError {...errors} />
                      }

                      {
                        _.isUndefined(this.props.flash.alert) ? null :
                          <div>
                            {this.props.flash.alert}
                            <Gutter h={6} />
                          </div>
                      }

                      {
                        _.isUndefined(this.props.flash.notice) ? null :
                          <div>
                            {this.props.flash.notice}
                            <Gutter h={6} />
                          </div>
                      }

                      <Form
                        onSubmit={this.handleSubmit.bind(this)}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                          <form method="post" action={this.props.session_create_path} onSubmit={this.handleSubmit.bind(this)}>
                            <input type="hidden" name="authenticity_token" value={csrfToken} />
                            <FormBorderWrapper bwMode={bwMode}>
                              <FormField bwMode={bwMode}>
                                <label>Email</label>
                                <Field
                                  name="user[email]"
                                  component="input"
                                  type="email"
                                  autoFocus={true}
                                  autoComplete="username"
                                  placeholder="abc@universalthirst.com"
                                />
                              </FormField>
                              <FormField bwMode={bwMode}>
                                <label>Password</label>
                                <Field
                                  name="user[password]"
                                  component="input"
                                  type="password"
                                  autoComplete="current-password"
                                  placeholder="*******"
                                />
                              </FormField>
                              
                            </FormBorderWrapper>
                            <Gutter h={20} />
                            
                            <ButtonArea>
                              <ButtonAreaMainBtns>
                                <PrimaryButton bwMode={bwMode} type="submit" disabled={submitting || pristine}>
                                  Login
                                </PrimaryButton>
                                <ButtonA bwMode={bwMode} href={this.props.new_registration_path}>
                                  Create Account
                                </ButtonA>
                              </ButtonAreaMainBtns>
                              <ForgotPasswordArea>
                                <a href={this.props.new_password_path}>Forgot password?</a>
                              </ForgotPasswordArea>
                            </ButtonArea>
                          </form>
                        )}
                      />      
                    </PaddingDiv> 
                  </LeftColumn>
                  <RightColumn>
                  </RightColumn>
                </BorderArea>


              </Container>
              <Footer foregroundColor="black" />
            </Fragment>
        }

        <MobileMenu isLogoutEnabled={true} backgroundColor="#E0E0E0" foregroundColor="black" highlightColor="#000000" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode,
    windowWidth: state.windowWidth
  }
}

export default connect(mapStateToProps)(SignIn);