import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { LanguageBox } from './';
import { changeBwMode, setScriptsPopup } from '../actions';
import media from '../stylesheets/media';

const Fragment = React.Fragment;

const LANGUAGES_BACKUP = [{"id":7,"language_name":"Arabic","products":[5]},{"id":9,"language_name":"Bangla","products":[3,8]},{"id":4,"language_name":"Devanagari","products":[3]},{"id":5,"language_name":"Gurmukhi","products":[3]},{"id":1,"language_name":"Latin","products":[3,4,8]},{"id":6,"language_name":"Tamil","products":[2]}];

const ScriptButtonArea = styled.div`
  position: absolute;
  right: 20px;
  top: 24px;
  z-index: 5;
  width: 200px;
  text-align: right;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 38px;
  
  a {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    opacity: 0;
    padding-top: 2px;
    &:hover {
      opacity: 1; 
    }
    
    font-size:1.8em;
    line-height:1.0;
    transition: color 0.2s;
    color: ${props => props.foregroundColor ? props.foregroundColor : (props.bwMode === "black" ? "white" : "black") };
  }


  ${media.mobileLarge`
    display: none;
  `}
`;

const ScriptArea = styled.div`
  position: fixed;
  background-color: black;
  top: 0;
  right: 0;
  z-index:2;
  border-left: 1px solid white;
  width: 260px;
  min-height:100vh;

  h3 {
    color: white;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;
  }
`;

const LanguageAnimateSpan = styled.span`
  display:block;
  transition: opacity .2s;

  font-size:1.8em;
  line-height:1.0;
  padding-top: 2px;

  padding-bottom: 5px;
  transition: color 0.2s;
  color: ${props => props.foregroundColor ? props.foregroundColor : (props.bwMode === "black" ? "white" : "black") };
`;


class MenuScripts extends Component {
  constructor(props){
    super(props);
    this.state = {
      current: 0,
      next: 1,
      firstMargin: 0,
      linkshow: false,
      fontLoaded: false
    };
  }
  
  checkFontLoad(){
    this.fontInterval = setInterval(() => {
      if (document.fonts.check("12px 'UT Sans'")){
        clearInterval(this.fontInterval);
        
        this.setState({
          fontLoaded: true
        })
      }
    }, 100);
  }

  componentWillUnmount(){
    clearInterval(this.fontInterval);
  }

    
  handleToggleScripts(e){
    // let { currentPage } = this.props;
    // let finalPopupMode = !this.props.scriptsPopup;
    // let bwMode = finalPopupMode ? "black" : "white";

    // if (currentPage === "Products") {
    //   this.props.dispatch(setScriptsPopup(finalPopupMode, true));
    // } else {
    //   this.props.dispatch(setScriptsPopup(finalPopupMode));
    // }
    // this.props.dispatch(changeBwMode(bwMode));
  }

  componentDidMount(){
    this.startTimer();
    this.checkFontLoad();
  }

  startTimer(){
    let { languages } = this.props;
    let i = 1;
      
    if (!languages) {
      languages = LANGUAGES_BACKUP;
    }

    
    this.setTime = setInterval(() => {
      this.setState({
        firstMargin: -39
      });

      setTimeout(() => {
        var current = i % languages.length;
        var next = i % languages.length + 1;
        if (next >= languages.length ) {
          next = 0;
        }
        this.setState({
          current: current,
          next: next,
          firstMargin: 0
        })
        
        i++;
      }, 800);
      
    }, 3000);
  }

  componentWillUnmount(){
    clearInterval(this.setTime);
    clearInterval(this.fontInterval);
  }

  // handleMouseEnter(e){
  //   this.setState({
  //     linkshow: true
  //   })
  // }

  // handleMouseLeave(e){
  //   this.setState({
  //     linkshow: false
  //   })
  // }

  render() {
    let { bwMode, languages, scriptsPopup, foregroundColor } = this.props;
    let { current, next, firstMargin, linkshow, fontLoaded } = this.state;
    
    if (!languages) {
      languages = LANGUAGES_BACKUP;
    }

    let marginTopStyle = {
      transition:  firstMargin !== 0 ? "margin-top 0.4s" : "none",
      marginTop: firstMargin,
      opacity: linkshow ? 0 : 1
    };
    
    return (
      <Fragment>
        <ScriptButtonArea style={{ opacity: fontLoaded ? 1 : 0, right: scriptsPopup ? 280 : 20 }} bwMode={bwMode} foregroundColor={foregroundColor}>
          <div className="right loop">
            <LanguageAnimateSpan style={marginTopStyle} bwMode={bwMode} foregroundColor={foregroundColor}>
              { 
                languages[current].language_name 
              }
            </LanguageAnimateSpan>
            <LanguageAnimateSpan style={{ opacity: linkshow ? 0 : 1 }} bwMode={bwMode} foregroundColor={foregroundColor}>
              {
                languages[next].language_name
              }
            </LanguageAnimateSpan>
          </div>
        </ScriptButtonArea>
        {
          scriptsPopup ? 
          <ScriptArea>
            <h3>
              See Typefaces.
            </h3>
            {
              _.map(languages, l => {
                return (
                  <LanguageBox key={l.id} {...l} />
                )
              })
            }
          </ScriptArea> : null
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    scriptsPopup: state.scriptsPopup
  }
}

export default connect(mapStateToProps)(MenuScripts);