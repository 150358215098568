import React, { Component } from 'react'
import styled from 'styled-components';
import { setCart } from '../actions';
import axios from 'axios';
import { numberWithDelimiter } from '../utils';
import { connect } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';
import media from '../stylesheets/media';
const CartItemContainer = styled.div`

  border-bottom: 1px solid ${props => props.bwMode === "black" ? "white" : "black"};
  padding: 15px;
  /* justify-content: space-between; */


  &:last-child {
    border-bottom: none;
  }

  div.title-area {
    display: flex;

  }

  a.delete-btn {
    margin-right: 8px;
    margin-top: 2px;
  }
  div.title {
  }

  div.licenses {

    display: flex;
    justify-content: space-between;
    margin-top: 10px;


    ${media.mobileLarge`
      display: block;
    `}
  }
  div.delete-price {
    display: flex;
    margin-left: 15px;
    
    div.price {
      margin-right: 15px;
      color: white;

      ${media.mobileLarge`
        margin: 0;
      `}
    }

    a {
      color: white;
    }
  }
`;

const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-image: linear-gradient(to right, white 25%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x; 

  padding-bottom: 5px;
  margin-bottom: 5px;

  ${media.mobileLarge`
    padding-bottom: 15px;
  `}
`;

const LicenseArea = styled.div`
  
  width: 33.33%;
  background:
    repeating-linear-gradient(to bottom,transparent 0 4px,white 4px 5px) 0/1px 100% no-repeat;

  padding-bottom: 0;
  padding-left: 15px;
  /* background-color: ${props => props.bwMode === "black" ? "white" : "black"}; */

  &:first-child {
    background: none;
    padding-left: 0;
  }

  ${media.mobileLarge`
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
    background: none;
    padding-left:0;
    padding-bottom: 15px;
    background-image: linear-gradient(to right,white 25%,rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;

    &:last-child {
      margin-bottom: 0;
      background:none;
      padding-bottom: 0;
    }
    &:first-child {
      margin-top: 2px;
      background-image: linear-gradient(to right,white 25%,rgba(255,255,255,0) 0%);
      background-position: bottom;
      background-size: 4px 1px;
      background-repeat: repeat-x;
      padding-left: 0;
    }
  `}

  a {
    color: ${props => props.bwMode === "black" ? "white" : "black"};
  }


`;

const SelectArea = styled.div`
  ${media.mobileLarge`
    width: 50%;
    margin-top: 2px;
  `}
`;


const LicenseTitle = styled.h4`
  margin-bottom:0;
  color: #828282;
`;

const LanguageLabel = styled.span`
  &:after {
    content: ", "
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
`;

class CartItem extends Component {
  handleDeleteCartClick(id) {
    axios.delete(`/api/cart_items/${id}.json`).then((response) => {
      if (response.data.success) {
        this.props.dispatch(setCart(response.data.cart));
      }
    })

  }

  handleUpdateCartItemsNumberLicense(id, selectedNumberLicense){
    if (_.isNull(id)) {
      
      this.handleCreateCartItemsNumberLicense(selectedNumberLicense);

    } else {

      if (!_.isNull(selectedNumberLicense.value.id)) {

        let params = {
          cart_items_number_license: {
            cart_item_id: this.props.id,
            number_license_id: selectedNumberLicense.value.id
          }
        };


        axios.patch(`/api/cart_items_number_licenses/${id}.json`, params).then((response) => {
          if (response.data.success) {
            this.props.dispatch(setCart(response.data.cart));
          }
        })

      } else {
        this.handleDeleteCartItemsNumberLicense(id);

      }
    }
    
  }

  handleDeleteCartItemsNumberLicense(id){
    axios.delete(`/api/cart_items_number_licenses/${id}.json`).then((response) => {
      if (response.data.success) {
        this.props.dispatch(setCart(response.data.cart));
      }
    })
  }

  handleCreateCartItemsNumberLicense(selectedNumberLicense){

    // let leastExpensiveNumberLicense = _.first(license.number_licenses);
    let params = {
      cart_items_number_license: {
        cart_item_id: this.props.id,
        number_license_id: selectedNumberLicense.value.id
      }
    };

    axios.post(`/api/cart_items_number_licenses.json`, params).then((response) => {
      if (response.data.success) {
        this.props.dispatch(setCart(response.data.cart));
      }
    })
  }

  render() {
    let { bwMode } = this.props;

    return (
      <CartItemContainer bwMode={bwMode}>
        <TitleArea bwMode={bwMode}>
          <div className="title-area">
            {
              this.props.editable ?
                <a className="delete-btn" href="javascript:void(0);" onClick={this.handleDeleteCartClick.bind(this, this.props.id)}>
                  <img src="/assets/delete_cart_btn.svg" alt="delete" />
                </a>
                : null
            }
            <div className="title">
              {this.props.product_name} {this.props.variant_name} |&nbsp;
              { 
                _.map(this.props.languages, l => {
                  return (
                    <LanguageLabel key={l.id}>
                      { l.language_name }
                    </LanguageLabel>
                  );
                })
              }
            </div>
          </div>
          
          <div className="delete-price">
            <div className="price">
              €{numberWithDelimiter(this.props.item_total)}
            </div>
           
          </div>
        </TitleArea>
        
        {
          this.props.editable ? 
          <div className="licenses">
            {
              _.map(this.props.licenses, l => {
                // debugger;
                let options = _.map(l.number_licenses, nl => {
                  return {
                    label: `${nl.number_license_name}`,
                    value: nl
                  };
                })
                
                options.unshift({
                  label: "None",
                  value: {
                    id: null
                  }
                });
                // console.log(options);
                // debugger;



                let selectedCartItemNumberLicense = _.find(this.props.cart_items_number_licenses, cinl => {
                  return cinl.license_name === l.license_name
                });

                let selectedOption = null;
                let onChangeFunc = null;

                if (!_.isUndefined(selectedCartItemNumberLicense)) {

                  let idx = _.findIndex(options, option => {
                    return option.value.id === selectedCartItemNumberLicense.number_license_id;
                  });

                  selectedOption = options[idx];
                  onChangeFunc = this.handleUpdateCartItemsNumberLicense.bind(this, selectedCartItemNumberLicense.id);
                } else {
                  onChangeFunc = this.handleUpdateCartItemsNumberLicense.bind(this, null);
                  selectedOption = {
                    label: "None",
                    value: {
                      id: null
                    }
                  };
                }

                let selected = !_.isUndefined(selectedCartItemNumberLicense);

                return (
                  <LicenseArea bwMode={bwMode} key={l.id} >
                    <LicenseTitle>
                      {l.license_name} License
                    </LicenseTitle>
                    {/* 
                      <a href="javascript:void(0);" onClick={this.handleCreateCartItemsNumberLicense.bind(this, l)}>
                        {
                          selected ?
                            "■" : "□ Add "
                        }
                        {l.license_name} License
                    </a> */}

                    {/* {
                      selected ?
                        <a href="javascript:void(0);" onClick={this.handleDeleteCartItemsNumberLicense.bind(this, selectedCartItemNumberLicense.id)}>
                          &nbsp;x
                    </a> : null
                    } */}
                    <SelectArea>
                      <Select
                        components={{ DropdownIndicator:() => null, IndicatorsContainer:() => null }}
                        styles={{
                          valueContainer: (provided, state) => ({
                            ...provided,
                            padding: "2px 0"
                          }),
                          control: (provided, state) => ({
                            ...provided,
                            border: 'none !important',
                            boxShadow: "none",
                            backgroundColor: this.props.bwMode === "black" ? "black" : "white",
                            color: this.props.bwMode === "black" ? "white" : "black",
                            "&:hover": {
                              border: "none"
                            }
                          }),
                          singleValue: (provided, state) => ({
                              ...provided,
                              color: "white",
                              border: "none",
                              marginLeft: 0,
                              borderRadius: 0,
                              "&:after": {
                                content: `url(/assets/arrow_${this.props.bwMode === "black" ? "white" : "black"}.svg)`,
                                paddingLeft: 10,
                                top: -2,
                                position: "relative"      
                              }
                            }),
                          indicatorSeparator: (provided, state) => ({
                            display: "none"
                          }),
                          menu: (provided, state) => {
                              return {
                                ...provided,
                                borderRadius: 16,
                                padding: 5,
                                margin: 0,
                                zIndex: 999,
                                width: 200,
                                // maxHeight: 264,
                                left: -16,
                                overflow: "hidden",
                                boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.75)",
                                backgroundColor: bwMode === "black" ? "white" : "black",
                                color: bwMode === "black" ? "black" : "white"
                              }
                            },
                            menuList: (provided, state) => {
                              return {
                                ...provided,
                                overflowX: "hidden"
                              }
                            },
                            option: (provided, state) => ({
                              ...provided,
                              borderRadius: 0,
                              border: "none",
                              paddingLeft: 11,
                              backgroundImage: `linear-gradient(to right, ${this.props.bwMode} 25%, rgba(255,255,255,0) 0%)`,
                              backgroundPosition: "bottom",
                              backgroundSize: "4px 1px",
                              backgroundRepeat: "repeat-x",                    
                              '&:hover, &:active': {
                                backgroundColor: bwMode === "black" ? "white" : "black"
                              },
                              backgroundColor: this.props.bwMode === "black" ? "white" : "black",
                              color: this.props.bwMode === "black" ? "black" : "white",
                              "&:first-child": {
                                paddingTop: 0
                              },  
                              "&:last-child": {
                                backgroundImage: "none",
                                paddingBottom: 0,
                                marginBottom: 0
                              }
                            })
                        }}
                        isSearchable={false}
                        onChange={onChangeFunc}
                        value={selectedOption}
                        options={options}>
                      </Select> 
                    </SelectArea>
                    
                  </LicenseArea>
                );
              })
            }
          </div> :
          <div className="licenses">
            {
              _.map(this.props.cart_items_number_licenses, cinl => {
                return (
                  <div>
                    {cinl.license_name} / {cinl.number_license_name}
                  </div>
                )
              })
            }
          </div>
        }
        

       
        

      </CartItemContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode
  }
}
export default connect(mapStateToProps)(CartItem);