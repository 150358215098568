import React, { Component } from 'react'
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 50vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;

  &.wrapper-left {
    left:0;
  }

  &.wrapper-right {
    left: 50vw;
  }
`;
const Fragment = React.Fragment;
const MainPageSticky = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;


  &.bottom {
    z-index: 1;
  }

  &.top {
    z-index: 2;
  }

  &:not(.bottom):not(.top) * {
    animation-play-state: paused !important;
  }
`;


const StaticBackground = styled.div`

  position: absolute;
  top: 50%;
  left: ${props => props.mode === "left" ? "100%" : 0};
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 100vw;
  height: 100vh;
  z-index: 2;

  background-size: cover;
  /* background-position: ${props => props.mode === "left" ? "-25vw" : "25vw"} 0;

  ${media.mobileSmall`
    background-position: ${props => props.mode === "left" ? "-50vw" : "50vw"} 0;
  `} */
  background-position: center;
`;

const RightRotateTypeArea = styled.div`
  font-size: 12.0em;
  width: 700px;
  position: relative;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  z-index:5;
  div {
    color: ${props => props.color};
    font-family: ${props => props.fontFamily }, 'AdobeBlank';
    font-weight: ${props => props.fontWeight };
    font-style: ${props => props.fontStyle };
  }
  color: ${props => props.color};
  transition: opacity 1s;

  .fonts-loaded & {
    opacity: 1;
  }
`;


const LeftRotateTypeArea = styled.div `
  font-size: 12.0em;
  width: 700px;
  position: relative;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  line-height: 1;
  text-align: center;
  cursor: pointer;
  z-index: 5;

  div {
    color: ${props => props.color};
    font-family: ${props => props.fontFamily }, 'AdobeBlank';
    font-weight: ${props => props.fontWeight };
    font-style: ${props => props.fontStyle };
  }
  color: ${props => props.color};
  transition: opacity 1s;

  .fonts-loaded & {
    opacity: 1;
  }
`;

const TypeArea = styled.div `
  font-size: 30vh;
  width: 100vw;
  height: 50vh;
  position: relative;
  text-align: center;
  top: 25vh;
  line-height: 50vh;

/*   background: blue;
  opacity: 0.5; */

  &.type-left {

  }
  &.type-right {
    margin-left: -50vw;
  }

  &.below {
    top: 25vh;
  }
`;

const RotateInner = styled.div`

  position: relative;
  width: 50vw;
  height: 100vh;
  overflow: hidden;

  &.left {
    margin: 0vh 0 0 auto;
    transform-origin: 100% 50%;
    transform: translate(-100%, -50%);
    height: 3000px;
    width: 3000px;
    left: 100%;
    top: 50%;
    background-color: ${props => props.backgroundColor};


  }



  &.right {
    margin: 0vh 0 0 auto;

    transform-origin: 0 50%;
    transform: translate(0, -50%);
    height: 3000px;
    width: 3000px;
    left: 0;
    top: 50%;

    background-color: ${props => props.backgroundColor};
    transform-origin: 0 50%;
  }
`;

export default class MainpageStickyComp extends Component {
  constructor(props){
    super(props);
    this.state = {
      fontLoaded: false
    }
  }
  componentDidMount(){
    this.fontCheck();
  }

  fontCheck(){

    let { mp  } = this.props;
    let rightFontName = (mp.right_product_variant.product_name + '-' +mp.right_product_variant.variant_name).replace(/ /g,'')
    let leftFontName = (mp.left_product_variant.product_name + '-' +mp.left_product_variant.variant_name).replace(/ /g,'')


    // if (mp.left_product_variant.variable_font) {
    //   leftFontName = leftFontName + "-variable";
    // }
    //
    // if (mp.right_product_variant.variable_font) {
    //   rightFontName = rightFontName + "-variable";
    // }

    this.fontInterval = setInterval(() => {
      if (
        document.fonts.check(`12px '${leftFontName}'`) &&
        document.fonts.check(`12px '${rightFontName}'`)
      ){
        clearInterval(this.fontInterval);
        this.setState({
          fontLoaded: true
        })
      }
    }, 100);
  }

  componentWillUnmount(){
    clearInterval(this.fontInterval);

  }

  handleBackClick(e){
    let { mp  } = this.props;
    document.location.href = `/products/${mp.product.permalink}`;
  }

  render() {
    let { windowWidth, angle, mp, mainPageClass, status, i, variantIds } = this.props;
    let { fontLoaded } = this.state;
    let rightFontName = (mp.right_product_variant.product_name + '-' +mp.right_product_variant.variant_name).replace(/ /g,'')
    let leftFontName = (mp.left_product_variant.product_name + '-' +mp.left_product_variant.variant_name).replace(/ /g,'')
    return (
      <MainPageSticky className={mainPageClass} key={i}>
        {
          status === "bottom" ?
          <Fragment>
            <Wrapper className="wrapper-left"
              onClick={this.handleBackClick.bind(this)}
              style={{
                background: mp.left_background_color,
                // borderRight: `1px solid ${mp.color}`
              }}>
              {
                mp.right_image ?
                <StaticBackground mode="left" style={{
                  transform: `translate(-50%, -50%)`,
                  backgroundImage: `url(${screen.width < 768 ? mp.left_mobile_image : mp.left_image})`
                }}>
                </StaticBackground> : null
              }

              <LeftRotateTypeArea
                className="type-left"
                style={{
                  opacity: fontLoaded ? 1 : 0,
                  transform: `translate(-50%, -50%)`
                }}
                onClick={this.handleBackClick.bind(this)}
                fontFamily={ leftFontName }
                fontWeight={ mp.left_product_variant.variable_font ? "inherit" : mp.left_product_variant.weight }
                fontStyle={ mp.left_product_variant.style }
                color={mp.color} dangerouslySetInnerHTML={{ __html: windowWidth < 768 ? mp.left_mobile_text : mp.left_text }}
              />

            </Wrapper>
            <Wrapper
              onClick={this.handleBackClick.bind(this)}
              className="wrapper-right" style={{

              background: mp.right_background_color,
              borderLeft: `1px solid ${mp.color}`
            }}>

              {
                mp.right_image ?
                <StaticBackground mode="right" style={{
                  transform: `translate(-50%, -50%)`,
                  backgroundImage: `url(${screen.width < 768 ? mp.right_mobile_image : mp.right_image})`
                }}>
                </StaticBackground> : null
              }
              <RightRotateTypeArea
                className="type-right"
                style={{

                  opacity: fontLoaded ? 1 : 0,
                  transform: `translate(-50%, -50%)`,
                  // borderLeft: angle === 0 ? "none" : `1px solid ${mp.color}`
                }}
                onClick={this.handleBackClick.bind(this)}
                fontFamily={rightFontName}
                fontWeight={ mp.right_product_variant.variable_font ? "inherit" : mp.right_product_variant.weight }
                fontStyle={mp.right_product_variant.style}
                color={mp.color} dangerouslySetInnerHTML={{ __html: windowWidth < 768 ? mp.right_mobile_text : mp.right_text }}
              />
            </Wrapper>
          </Fragment> : null
        }
        {
          status === "top" ?
          <Fragment>
            <Wrapper className="wrapper-left" style={{
              borderRight: `1px solid ${mp.color}`
            }}>


              <RotateInner
                className="left"
                style={{
                  transform: `translate(-100%, -50%) rotate(${angle}deg)`,

                  // borderRight: angle === 0 ? "none" : `1px solid ${mp.color}`
                }}
                foregroundColor={mp.color} backgroundColor={mp.left_background_color}
              >
                {
                  mp.left_image ?
                  <StaticBackground  mode="left" style={{
                    transform: `translate(-50%, -50%) rotate(${-angle}deg)`,
                    backgroundImage: `url(${screen.width < 768 ? mp.left_mobile_image : mp.left_image})`
                  }}>
                  </StaticBackground> : null
                }


                <LeftRotateTypeArea
                  className="type-left"
                  style={{
                    opacity: fontLoaded ? 1 : 0,
                    transform: `translate(-50%, -50%) rotate(${-angle}deg)`
                  }}
                  fontFamily={ leftFontName }
                  fontWeight={ mp.left_product_variant.variable_font ? "inherit" : mp.left_product_variant.weight }
                  onClick={this.handleBackClick.bind(this)}
                  fontStyle={ mp.left_product_variant.style }
                  color={mp.color} dangerouslySetInnerHTML={{ __html: windowWidth < 768 ? mp.left_mobile_text : mp.left_text }}
                />
              </RotateInner>
            </Wrapper>
            <Wrapper className="wrapper-right" style={{borderLeft: `1px solid ${mp.color}`}}>
              <RotateInner
                className="right"
                angle={angle}
                backgroundImage={screen.width < 768 ? mp.right_mobile_image : mp.right_image}
                backgroundColor={mp.right_background_color}
                style={{
                  transform: `translate(0, -50%) rotate(${angle}deg)`
                }}
              >
                {
                  mp.right_image ?
                  <StaticBackground  mode="right" style={{
                    transform: `translate(-50%, -50%) rotate(${-angle}deg)`,
                    backgroundImage: `url(${screen.width < 768 ? mp.right_mobile_image : mp.right_image})`
                  }}>
                  </StaticBackground> : null
                }
                <RightRotateTypeArea
                  className="type-right"
                  style={{
                    opacity: fontLoaded ? 1 : 0,
                    transform: `translate(-50%, -50%) rotate(${-angle}deg)`
                  }}
                  fontFamily={ rightFontName }
                  fontWeight={ mp.right_product_variant.variable_font ? "inherit" : mp.right_product_variant.weight }
                  onClick={this.handleBackClick.bind(this)}
                  fontStyle={mp.right_product_variant.style}
                  color={mp.color} dangerouslySetInnerHTML={{ __html: windowWidth < 768 ? mp.right_mobile_text : mp.right_text }}
                />
              </RotateInner>
            </Wrapper>
          </Fragment> : null
        }
        <link rel="stylesheet" href={`/api/product_variants/${mp.left_product_variant.id}.css`} />
        <link rel="stylesheet" href={`/api/product_variants/${mp.right_product_variant.id}.css`} />

        {/*
          load all variant is too slow
          {
          _.map(variantIds, vid => {
            return (
              <link rel="stylesheet" key={vid} href={`/api/product_variants/${vid}.css`} />
            );
          })
        } */}
        <style dangerouslySetInnerHTML={{ __html: mp.css }} />
      </MainPageSticky>
    )
  }
}
