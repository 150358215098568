import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { changeMobileMenuVisible } from '../actions';
import { MenuLink, CartLink } from '../stylesheets/components';
import { UserLoginComponent } from './';

const MobileBack = styled.div`
  background: ${props => props.backgroundColor};
  position:fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  opacity: 0.75;
  opacity: 0.75;
`

const MobileMenuContainer = styled.div`
  background: ${props => props.backgroundColor};
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: calc(100vw - 30px);
  border-bottom: 1px solid ${props => props.foregroundColor};
`;

const CloseBtnArea = styled.div`
  position: absolute;
  right: 7px;
  top: 17px;
`;

const CartLinkMobileMenu = styled(CartLink)`
  /* display: block; */
  position: absolute;
  right: 43px;
  top: 17px;
  line-height: 1;
`;

const MenuLinkMobile = styled(MenuLink)`
  padding-bottom: 8px;
`;
class MobileMenu extends Component {

  handleCloseBtnClick(e) {
    this.props.dispatch(changeMobileMenuVisible(false));
  }

  render() {
    let { cart, isLogoutEnabled, mobileMenuVisible, bwMode, currentPage, highlightColor, foregroundColor, backgroundColor } = this.props;
    let cart_items_available = false;

    if (cart) {
      if (cart.cart_items.length > 0) {
        cart_items_available = true;
      }
    }

    return (
      <>
        <MobileMenuContainer foregroundColor={foregroundColor} style={{ display: mobileMenuVisible ? "block" : "none" }} backgroundColor={backgroundColor}>

          <MenuLinkMobile firstmenuColor={foregroundColor} highlightColor={highlightColor} active={currentPage === "Typefaces"} href="/products">Typefaces</MenuLinkMobile>
          <MenuLinkMobile firstmenuColor={foregroundColor} highlightColor={highlightColor} active={currentPage === "About"} href="/about">About</MenuLinkMobile>
          <MenuLinkMobile firstmenuColor={foregroundColor} highlightColor={highlightColor} target="_blank" href="https://gazette.universalthirst.com">Gazette</MenuLinkMobile>
          <MenuLinkMobile firstmenuColor={foregroundColor} highlightColor={highlightColor} active={currentPage === "Custom Work"} href="/custom-work">Custom Work</MenuLinkMobile>
          <MenuLinkMobile firstmenuColor={foregroundColor} highlightColor={highlightColor} active={currentPage === "FAQ"} href="/faq">FAQ</MenuLinkMobile>
          {/* <MenuLinkMobile firstmenuColor={foregroundColor} highlightColor={highlightColor} active={currentPage === "Trial Fonts"} href="/trial-fonts">Trial Fonts</MenuLinkMobile> */}
          <UserLoginComponent foregroundColor={foregroundColor} isLogoutEnabled={isLogoutEnabled} />

          <CartLinkMobileMenu mode="mobile" href="/cart" color={foregroundColor} bwMode={bwMode}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M7.5 10L8 21M12 10V21M12 21H19.0868C19.604 21 20.0359 20.6056 20.0827 20.0905L21 10H3L3.91732 20.0905C3.96415 20.6056 4.39601 21 4.91321 21H12ZM16.5 10L16 21" stroke={backgroundColor} strokeWidth="2"/>
              <path d="M7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V10H7V7Z" stroke={backgroundColor} strokeWidth="2"/>
            </svg>


              {
                cart_items_available ?
                  <span className="label" style={{ color: backgroundColor }}>
                    {cart.cart_items.length}
                  </span> : null
              }
            </CartLinkMobileMenu>
          <CloseBtnArea>
            <a href="javascript:void(0);" onClick={this.handleCloseBtnClick.bind(this)}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M9 9L23 23" stroke={foregroundColor} strokeWidth="2" />
                <path d="M23 9L9 23" stroke={foregroundColor} strokeWidth="2" />
              </svg>
            </a>
          </CloseBtnArea>
        </MobileMenuContainer>
        {
          mobileMenuVisible ?
          <MobileBack backgroundColor={backgroundColor}/> : null
        }
      </>
    );
  }
}

let mapStateToProps = state => {
  return {
    cart: state.cart,
    mobileMenuVisible: state.mobileMenuVisible,
    // mobileMenuBackgroundColor: state.mobileMenuBackgroundColor,
    // foregroundColor: state.foregroundColor
  };
}

export default connect(mapStateToProps)(MobileMenu);
