import React from 'react';
import _ from 'lodash';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, FaqLink, BackgroundColorChanger, Footer } from '../components';
import { changeLoginInfo, setCart, changeMobileMenuColors, windowResize } from '../actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PaddingDiv, BorderArea, DesktopHr, Container, Gutter, DesktopGutter, HalfContainer, LeftColumn, RightColumn, Title, MenuGutter } from '../stylesheets/components';
import media from '../stylesheets/media';


const Fragment = React.Fragment;
const Link = styled.a`
  display:block;
  margin: 8px 0;
  color: ${props => props.selected ? props.highlightColor : "white"};
`;
const FaqDescDiv = styled.div`
  h4 {
    color: white;
    font-size: 1.8em;
    line-height: 1.3;
    margin-bottom: 20px;
  }

  div {
    color: white;
    font-size: 1.0em;
    line-height: 1.4;
  }
`;

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));

    this.state = {
      selectedCategory: _.first(this.props.faq_categories).id,
      selectedFaq: _.first(this.props.faqs.filter((faq) => faq.category == _.first(this.props.faq_categories).id)),

    };
    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors("#5900ff", "#FFFFFF"));
    this.faqCategorySelected = this.faqCategorySelected.bind(this)
    this.faqSelected = this.faqSelected.bind(this)

  }

  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();
  }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));

  }
  faqSelected(faq){
    this.setState({
      selectedFaq: faq
    })
  }

  faqCategorySelected(category){
    this.setState({
      selectedCategory: category.id,
      selectedFaq: _.first(this.props.faqs.filter((faq) => faq.category == category.id))
    })
  }

  render() {
    let { faqs,faq_categories, products, languages, typefacesPopup, bwMode, windowWidth } = this.props;

    let { selectedFaq } = this.state;
    let {selectedCategory} = this.state;

    return (
      <Fragment>
        <Menu foregroundColor="#FFFFFF" isLogoutEnabled={true} highlightColor="#FFDC0B" currentPage="FAQ" />
        <MenuTypefaces foregroundColor="#FFFFFF" products={products} backgroundColor="#5900ff" />
        <MenuScripts foregroundColor="#FFFFFF" languages={languages} />



        {
          typefacesPopup ?
          null :
            <Fragment>
              <BackgroundColorChanger color="#5900ff" bwMode="black" />

              <Container className="no-webfont">
                <BorderArea bwMode={bwMode}>
                  <MenuGutter />
                  <PaddingDiv>
                    <Title bwMode={bwMode}>
                    FAQ
                    </Title>
                  </PaddingDiv>
                </BorderArea>
                <BorderArea bwMode={bwMode}>
                  <DesktopHr bwMode={bwMode} />
                  <HalfContainer>
                    <LeftColumn>
                      <PaddingDiv>
                        {
                          _.map(faq_categories, (category, i) => {
                            return <div > <Link href="javascript:void(0);" onClick={()=> this.faqCategorySelected(category)}>
                              { selectedCategory == category.id ? "— " : ""}{category.name}
                            </Link>
                            <div style={{'margin-left': '20px'}}>

                            {
                              (selectedCategory == category.id) &&
                              _.map(faqs, (faq, i) => {
                                if(faq.category == category.id) {
                                return (
                                  <FaqLink {...faq} mobile={windowWidth < 600} selected={selectedFaq == faq} highlightColor="#FFDC0B" onClick={() => this.faqSelected(faq)} />
                                )
                              }
                              })
                            }
                            </div>
                            </div>
                          })
                        }

                      </PaddingDiv>
                    </LeftColumn>
                    <RightColumn>
                      <PaddingDiv>

                        {
                          windowWidth < 600 ?
                          null :
                          <Fragment>
                            {
                              !_.isNull(selectedFaq) ?
                              <FaqDescDiv>
                                <h4>
                                  {selectedFaq.question}
                                </h4>
                                <div dangerouslySetInnerHTML={{ __html: selectedFaq.answer }}>
                                </div>
                              </FaqDescDiv> : null
                            }
                          </Fragment>
                        }
                      </PaddingDiv>
                    </RightColumn>
                  </HalfContainer>
                </BorderArea>
              </Container>
              <Footer />
          </Fragment>
        }


        <MobileMenu isLogoutEnabled={true} backgroundColor="#5900ff" foregroundColor="white" highlightColor="#FFDC0B" currentPage="FAQ" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode,
    windowWidth: state.windowWidth
  }
}

export default connect(mapStateToProps)(Faq);
