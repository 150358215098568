import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import media from '../stylesheets/media';
import { changeSelectedTestCategory, changePreviewFontSize } from '../actions';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { getFontVH, getFontSizeMin, getFontSizeMax } from '../utils';
import { TestString } from '../stylesheets/components';

const BarLeft = styled.div`
  position:absolute;
  top: calc(50% - 16px);
  left: 20px;
  z-index: 10;
  display: flex;
  align-items: center;


  ${media.mobileLarge`
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 15px;
    width: calc(100% - 30px);
  `}

`;

const Fragment = React.Fragment;

const SelectContainer = styled.div`
  width: 300px;
  margin-right:10px;

  ${media.mobileLarge`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  `}
`;

const TestCategoryContainer = styled.div`
  width: 139px;
  ${media.mobileLarge`
    display: none;
  `}
`;

const SliderArea = styled.div`
  width: calc(100% - 700px);
  position: absolute;
  left: calc(50% + 15px);
  top: calc(50% - 15px);
  z-index: 1;
  transform: translate(-50%, 0);

`;

const InvisibleSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    width: 80,
    height: 32,
    border: `1px solid white`,
    backgroundColor: 'black',
    marginTop: -16,
    marginLeft: -40,
    borderRadius: 400,
    '&:focus,&:hover,&$active': {
      boxShadow: 'none',
    }
    // border: `1px solid ${this.props.bwMode === "white" ? "black" : "white"}`
  },
  label: {
    color: "black"
  },
  valueLabel: {
    top: 0,
    left: 53,
    transform: "translate(-50%, 0) !important",
    width: 60,
    color: "white",
    fontFamily: "UT Sans, sans-serif",
    fontSize: 18,
    textAlign: "center",
    '& *': {
      background: 'transparent',
      color: 'white',
    },
  },
  track: {
    display: "none"
  },
  rail: {
    display: "none"
  },
  mark: {
    display: "none"
  },
  markActive: {
    display: "none"
  },
})(Slider);

class PreviewSelectors extends Component {
  constructor(props){
    super(props);

    this.state = {
      fontSize: 80,
      productSelectWidth: 141,
      testCategorySelectWidth: 100,
      currentProduct: {
        value: this.props.product.permalink,
        label: this.props.product.product_name
      }
    }

    this.testStringRef = React.createRef();
    this.testCategoryStringRef = React.createRef();
    this.barleftRef = React.createRef();
  }


  onFontChange(e){
    this.setState({
      currentProduct: e,
    }, ev => {
      _.delay(() => {
        this.setState({
          productSelectWidth: this.testStringRef.current.offsetWidth
        });
        document.location.href = `/products/${e.value}`;
      }, 100);
    });
  }

  componentDidMount(){
    _.delay(() => {
      this.setState({
        productSelectWidth: this.testStringRef.current.offsetWidth,
        testCategorySelectWidth: this.testCategoryStringRef.current.offsetWidth
      });
    }, 100);

  }


  handleTestCategoryChange(e) {

    this.props.dispatch(changeSelectedTestCategory(e));
    _.delay(() => {
      this.setState({
        testCategorySelectWidth: this.testCategoryStringRef.current.offsetWidth
      });
    }, 100);
  }


  render() {
    let { bwMode, windowWidth, selectedTestCategory, previewFontSize } = this.props;
    // console.log("previewFontSize", previewFontSize);
    let { productSelectWidth, testCategorySelectWidth } = this.state;
    let productOptions = _.map(this.props.products, p => {
      return {
        value: p.permalink,
        label: p.product_name
      };
    });


    let selectStyles = {
      control: (provided, state) => {
        return {
          ...provided,
          transition: "none",
          border: `1px solid ${bwMode === "white" ? "black" : "white"}`,
          boxShadow: "none",
          padding: "0px 10px 0 16px",
          borderRadius: 16,
          minHeight: 32,
          background: bwMode === "white" ? "white" : "black",
          "&:hover": {
            border: `1px solid ${bwMode === "white" ? "black" : "white"}`
          }
        };
      },
      singleValue: (provided, state) => ({
        ...provided,
        color: bwMode === "white" ? "black" : "white",
        border: "none",
        marginLeft: 0,
        borderRadius: 0,
        "&:after": {
          content: `url(/assets/arrow_${bwMode === "black" ? "white" : "black"}.svg)`,
          paddingLeft: 10,
          top: -2,
          position: "relative"
        }
      }),
      indicatorSeparator: (provided, state) => ({
        display: "none"
      }),
      indicatorContainer: (provided, state) => {
        return {
          ...provided,
          padding: 0
        }
      },
      menu: (provided, state) => {
        return {
          ...provided,
          top: "calc(100% + 8px)",
          borderRadius: 16,
          padding: 5,
          margin: 0,
          zIndex: 999,
          width: 150,
          boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.75)",
          backgroundColor: bwMode === "black" ? "white" : "black",
          color: bwMode === "black" ? "black" : "white"

        }
      },
      option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        border: "none",
        paddingLeft: 11,
        backgroundImage: `linear-gradient(to right, ${this.props.bwMode} 25%, rgba(255,255,255,0) 0%)`,
        backgroundPosition: "bottom",
        backgroundSize: "4px 1px",
        backgroundRepeat: "repeat-x",
        '&:hover, &:active': {
          backgroundColor: bwMode === "black" ? "white" : "black"
        },
        backgroundColor: this.props.bwMode === "black" ? "white" : "black",
        color: bwMode === "black" ? "black" : "white",
        "&:first-child": {
          paddingTop: 0
        },
        "&:last-child": {
          backgroundImage: "none",
          paddingBottom: 0,
          marginBottom: 0
        }
      })
    }

    let barLeftWidth;

    if (this.barleftRef.current) {

      barLeftWidth = this.barleftRef.current.offsetWidth + 160;
    } else {
      barLeftWidth = 400;
    }

    return (
      <Fragment>
        <BarLeft ref={this.barleftRef}>
          <TestString ref={this.testStringRef}>
            { this.state.currentProduct.label }
          </TestString>
          <TestString ref={this.testCategoryStringRef}>
            { selectedTestCategory.label }
          </TestString>
          <SelectContainer style={{width: productSelectWidth}}>
            <Select
              components={{ DropdownIndicator:() => null, IndicatorsContainer:() => null }}
              autosize={true}
              styles={{
                control: (provided, state) => {
                  return {
                    ...provided,
                    transition: "none",
                    padding: "0px 10px 0 16px",
                    borderRadius: 16,
                    backgroundColor: bwMode === "white" ? "black" : "white",
                    border: 'none',
                    lineHeight: 1,
                    minHeight: 32
                  };
                },
                singleValue: (provided, state) => ({
                  ...provided,
                  color: bwMode === "white" ? "white" : "black",
                  border: "none",
                  marginLeft: 0,
                  borderRadius: 0,
                  "&:after": {
                    content: `url(/assets/arrow_${bwMode === "white" ? "white" : "black"}.svg)`,
                    paddingLeft: 10,
                    top: -2,
                    position: "relative"
                  }
                }),
                indicatorSeparator: (provided, state) => ({
                  display: "none"
                }),
                menu: (provided, state) => {
                  return {
                    ...provided,
                    borderRadius: 16,
                    top: "calc(100% + 8px)",
                    padding: 5,
                    margin: 0,
                    zIndex: 999,
                    width: 200,
                    boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.75)",
                    backgroundColor: bwMode === "black" ? "white" : "black",
                    color: bwMode === "black" ? "black" : "white"
                  }
                },
                menuList: (provided, state) => {
                  return {
                    ...provided,
                    overflowX: "hidden"
                  }
                },
                option: (provided, state) => ({
                  ...provided,
                  borderRadius: 0,
                  border: "none",
                  paddingLeft: 11,
                  backgroundImage: `linear-gradient(to right, ${this.props.bwMode} 25%, rgba(255,255,255,0) 0%)`,
                  backgroundPosition: "bottom",
                  backgroundSize: "4px 1px",
                  backgroundRepeat: "repeat-x",
                  '&:hover, &:active': {
                    backgroundColor: bwMode === "black" ? "white" : "black"
                  },
                  backgroundColor: this.props.bwMode === "black" ? "white" : "black",
                  color: bwMode === "black" ? "black" : "white",
                  "&:first-child": {
                    paddingTop: 0
                  },
                  "&:last-child": {
                    backgroundImage: "none",
                    paddingBottom: 0,
                    marginBottom: 0
                  }
                })
              }}
              isSearchable={false}
              onChange={this.onFontChange.bind(this)}
              value={this.state.currentProduct}
              options={productOptions}>
            </Select>
          </SelectContainer>

          <TestCategoryContainer className="test-category-select" style={{width: testCategorySelectWidth}}>
            <Select styles={selectStyles}
              components={{ DropdownIndicator:() => null, IndicatorsContainer:() => null }}
              isSearchable={false}
              onChange={this.handleTestCategoryChange.bind(this)}
              value={selectedTestCategory}
              options={this.props.testCategories}>
            </Select>
          </TestCategoryContainer>


        </BarLeft>
        {
          windowWidth > 768 ?
          <SliderArea
            style={{
              width: `calc(100% - ${230 + barLeftWidth}px)`
            }}>
              <InvisibleSlider
              min={selectedTestCategory.value === 1 ? getFontSizeMin() : 8 }
              max={selectedTestCategory.value === 1 ? getFontSizeMax() : 30 }
              aria-labelledby="discrete-slider-small-steps"
              onChange={(e, value) => {
                this.props.dispatch(changePreviewFontSize(value));
              }}
              bwmode={this.props.bwMode}
              value={this.props.previewFontSize}
              valueLabelFormat={v => `${v.toFixed(0)}pt`}
              valueLabelDisplay="on"
              />
          </SliderArea> : null
        }

      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    selectedTestCategory: state.selectedTestCategory,
    previewFontSize: state.previewFontSize
  }
}

export default connect(mapStateToProps)(PreviewSelectors);
