import React from 'react';
import _ from 'lodash';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger, UserEditComponent } from '../components';
import { setCart, changeMobileMenuColors, changeLoginInfo } from '../actions';
import { connect } from 'react-redux';
import { DesktopOnly, MobileOnly, FlashNotice, BorderArea, Container, Gutter, HalfContainer, DesktopGutter, MenuGutter, LeftColumn, RightColumn, Title, DesktopHr, PaddingDiv, MenuRightSingleGutter } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import styled from 'styled-components';

const Fragment = React.Fragment;


const OrderList = styled.a`
  padding: 10px 15px;
  font-size: 0.9em;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    border: none;
  }
  
  span.label {
    color: #828282;
  }
  
  div.elem {
    width: calc(50% - 10px);
  }
`;

const SubTitle = styled.div`
  margin: 10px 0;
`;

const OrderListContainer = styled.div`
  border: 1px solid black;
`;

const OrderListArrow = styled.div`
  padding-left: 10px;
`

class User extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors("#E0E0E0", "#000000"));
  }

  render() {
    let { products, languages, user, orders, typefacesPopup, bwMode } = this.props;
    return (
      <Fragment>
        <Menu foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} isLogoutEnabled={true} highlightColor="#000000" currentPage="Welcome"/>
        <MenuTypefaces foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} backgroundColor="#E0E0E0" products={products} />
        <MenuScripts foregroundColor={typefacesPopup ? "#FFFFFF" : "#000000"} languages={languages} />

        {
          typefacesPopup ? 
          null :
          <Fragment>
            <BackgroundColorChanger color="#E0E0E0" bwMode="white" />
            <Container bwMode={bwMode} className="no-webfont">
              <BorderArea bwMode={bwMode}>
                <MenuGutter />

                <HalfContainer>
                  <LeftColumn bwMode={bwMode}>
                    <PaddingDiv>
                      <Title bwMode={bwMode}>
                        My Account
                      </Title>
                    </PaddingDiv>
                  </LeftColumn>
                  <RightColumn>
                    <PaddingDiv>
                      <DesktopOnly>
                        <Title bwMode={bwMode}>
                          Order History
                        </Title>  
                      </DesktopOnly>
                    </PaddingDiv>
                  </RightColumn>
                </HalfContainer>
              </BorderArea>
              <BorderArea bwMode={bwMode}>
                <DesktopHr bwMode={bwMode} />
                <HalfContainer>
                  <LeftColumn bwMode={bwMode}>
                    <UserEditComponent {...this.props} />
                  
                  </LeftColumn>
                  <RightColumn>
                      <MobileOnly>
                        <PaddingDiv>
                            <Title bwMode={bwMode}>
                              Order History
                            </Title>
                        </PaddingDiv>
                      </MobileOnly>
                      <PaddingDiv>
                        <SubTitle>
                          Past Orders
                        </SubTitle>
                        {
                          orders.length > 0 ?
                            <OrderListContainer>
                              {
                                _.map(orders, (order, i) => {
                                  return (
                                    <OrderList key={i} href={`/order/${order.public_uid}`}>
                                      <div className="elem">
                                        <span className="label">Order #</span> {order.public_uid}
                                      </div>
                                      <div className="elem">
                                        €{numberWithDelimiter(order.total)} <span className="label">{order.payment_completed_at}</span>
                                      </div>

                                      <OrderListArrow>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M3.1875 11.7872C3.0625 11.9122 3 12.0841 3 12.3029C3 12.5216 3.07031 12.7013 3.21094 12.8419C3.35156 12.9825 3.53125 13.0529 3.75 13.0529H18.4219L15.2344 16.2872C14.8594 16.631 14.8516 16.9825 15.2109 17.3419C15.5703 17.7013 15.9219 17.6935 16.2656 17.3185L20.7656 12.8185C20.8594 12.756 20.9219 12.6779 20.9531 12.5841C20.9844 12.4904 21 12.3966 21 12.3029C21 12.2091 20.9844 12.1154 20.9531 12.0216C20.9219 11.9279 20.8594 11.8497 20.7656 11.7872L16.2656 7.28723C15.9219 6.91223 15.5703 6.90442 15.2109 7.2638C14.8516 7.62317 14.8594 7.97473 15.2344 8.31848L18.4219 11.5529H3.75C3.53125 11.5529 3.34375 11.631 3.1875 11.7872Z" fill="black" />
                                        </svg>
                                      </OrderListArrow>
                                    </OrderList>
                                  );
                                })
                              }
                            </OrderListContainer>
                            :
                            <Fragment>
                              No past orders.
                            </Fragment>
                        } 
                    </PaddingDiv>
                  </RightColumn>
                </HalfContainer>
              </BorderArea>
            </Container>
                  
            <Footer foregroundColor="#000000" />
          </Fragment>
        }

        <MobileMenu isLogoutEnabled={true} backgroundColor="#E0E0E0" foregroundColor="black" highlightColor="#000000" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode
  }
}

export default connect(mapStateToProps)(User);

