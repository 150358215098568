import React from 'react';
import _ from 'lodash';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger, UserEditComponent } from '../components';
import { setCart, changeMobileMenuColors, changeLoginInfo } from '../actions';
import { connect } from 'react-redux';
import { Container, BorderArea, Gutter, HalfContainer, DesktopGutter, MenuGutter, LeftColumn, RightColumn, Title, DesktopHr, PaddingDiv, MenuRightSingleGutter } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import styled from 'styled-components';

const Fragment = React.Fragment;

const DescDiv = styled.div`
  div, a, p, ol, li, ul {
    color: white;
  }

  a {
    text-decoration: underline;
  }
`;


class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors("#000000", "#FFFFFF"));
  }

  render() {
    let { languages, about, bwMode, typefacesPopup } = this.props;

    return (
      <Fragment>
        <Menu foregroundColor={typefacesPopup ? "#000000" : "#FFFFFF"} isLogoutEnabled={true} highlightColor="#FFFFFF" currentPage="" />
        <MenuTypefaces foregroundColor={typefacesPopup ? "#000000" : "#FFFFFF"} backgroundColor="#000000" />
        <MenuScripts foregroundColor={typefacesPopup ? "#000000" : "#FFFFFF"} languages={languages} />

        {
          typefacesPopup ?
            null :
            <Fragment>
              <BackgroundColorChanger color="#000000" bwMode="black" />
              <Container bwMode={bwMode} className="no-webfont">
                <BorderArea bwMode={bwMode}>
                  <MenuGutter />
                  <PaddingDiv>
                    <Title bwMode={bwMode}>
                      Terms
                    </Title>
                  </PaddingDiv>
                </BorderArea>
                <BorderArea bwMode={bwMode}>
                    <DesktopHr bwMode={bwMode} />
                    <LeftColumn bwMode={bwMode}>
                      <PaddingDiv>
                        <DescDiv dangerouslySetInnerHTML={{ __html: about.terms }} />
                      </PaddingDiv>

                    </LeftColumn>
                  </BorderArea>
                </Container>

              <Footer foregroundColor="#FFFFFF" />
            </Fragment>
        }
        <MobileMenu isLogoutEnabled={true} backgroundColor="black" foregroundColor="white" highlightColor="#FFFFFF" currentPage="" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode
  }
}

export default connect(mapStateToProps)(Terms);

