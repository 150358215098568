import _ from 'lodash';

export const checkWebFontLoad = () => {
  var fontInterval = setInterval(() => {
    if (document.fonts.check("12px 'UT Sans'")){
      clearInterval(fontInterval);
      _.each(document.querySelectorAll('.no-webfont'), el => {
        el.classList.remove('no-webfont');
      });
    }
  }, 100);
}



export const constrain = (n, low, high) => {
  return Math.max(Math.min(n, high), low);
};

export const convVariableToStr = (variables) => {

  let resultStrArray = [];
  let resultStr;

  _.each(variables, (v, k) => {
    resultStrArray.push(`'${k}' ${v.value}`);
  })

  resultStr = resultStrArray.join(", ");
  // console.log(resultStr);
  return resultStr;
}

export const getFontSizeMin = () => {
  // let containerHeight = window.innerHeight * 0.9;
  // return constrain(containerHeight * 0.4, 128, 200);
  return 64;
}

export const getFontSizeMax = () => {
  // let containerHeight = window.innerHeight * 0.9;
  // return constrain(containerHeight * 0.4, 128, 200);
  return 180;
}

export const getFontVH = () => {
  let containerHeight = (window.innerHeight * 0.9) * 0.5;
  return 128
  // return constrain(containerHeight * 0.4, 128, 200);
  // if (window.innerHeight < 500) {
  //   return window.innerHeight * 0.13;
  // } else if (window.innerHeight >= 500 && window.innerHeight < 800) {
  //   return window.innerHeight * 0.12;
  // } else {
  //   return window.innerHeight * 0.19;
  // }

}

export const radiansToDegrees = (radians) => {
  var pi = Math.PI;
  return radians * (180/pi);
};



export const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  };
}

export const describeArc = (x, y, radius, startAngle, endAngle) => {

  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M", start.x, start.y,
    "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
  ].join(" ");

  return d;
}


export const numberWithDelimiter = (number, delimiter, separator) => {
  try {
    var delimiter = delimiter || ",";
    var separator = separator || ".";

    var parts = number.toString().split('.');
    parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + delimiter);
    return parts.join(separator);
  } catch(e) {
    return number
  }
};

export const calculateItemTotal = (variant) => {
  let itemTotal = 0;

  _.each(variant.selectedNumberLicenses, nl => {
    let licenseTotal = 0;

    let product_variants_number_license = _.find(variant.product_variants_number_licenses, pvnl => {
      return pvnl.number_license_id === nl.id;
    })
    if (!_.isUndefined(product_variants_number_license)) {
      licenseTotal = product_variants_number_license.price;
    }
    itemTotal = itemTotal + licenseTotal;
  });

  return itemTotal;
}

export const calculateTotal = (selectedVariants) => {
  let itemTotal = 0;
  _.each(selectedVariants, variant => {
    _.each(variant.selectedNumberLicenses, nl => {
      let licenseTotal = 0;

      let product_variants_number_license = _.find(variant.product_variants_number_licenses, pvnl => {
        return pvnl.number_license_id === nl.id;
      })
      if (!_.isUndefined(product_variants_number_license)) {
        licenseTotal = product_variants_number_license.price;
      }
      itemTotal = itemTotal + licenseTotal;
    });

  })

  return itemTotal;
}

export const calculateDiscountRate = (variants, completeVariant) => {
  let sum = _.sumBy(_.filter(variants, v => { return !v.complete }), v => v.price);
  let completePrice = completeVariant.price;

  let rate = (1 - (completePrice / sum)) * 100;
  return Math.ceil(rate);

}
