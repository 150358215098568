import React from 'react';
import _ from 'lodash';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger, DeviseError } from '../components';
import { changeLoginInfo } from '../actions';
import { connect } from 'react-redux';
import { Container, Gutter, HalfContainer, LeftColumn, RightColumn, Title, FormField, FormBorderWrapper, PrimaryButton, ButtonA, PaddingDiv, ButtonArea, TwoLineDesktopGutter, BorderArea, MenuGutter, DesktopHr } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';
import axios from 'axios';
import { throws } from 'assert';

const Fragment = React.Fragment;

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));

    ;
  }

  handleSubmit(e) {
    // debugger;
  }

  render() {
    let { products, languages, user, orders, typefacesPopup, minimum_password_length, errors, bwMode } = this.props;

    const tokenDom = document.querySelector("meta[name=csrf-token]");
    const csrfToken = tokenDom.content;

    return (
      <Fragment>
        <Menu isLogoutEnabled={true} />
        <MenuTypefaces foregroundColor="black" backgroundColor="white" products={products} />
        <MenuScripts languages={languages} />

        {
          typefacesPopup ?
            null :
            <Fragment>
              <BackgroundColorChanger color="#E0E0E0" bwMode="white" />

              <Container bwMode={bwMode} className="no-webfont">
                <BorderArea foregroundColor="black">
                  <MenuGutter />
                  <PaddingDiv>
                    <Title bwMode={bwMode}>
                      Create Account
                    </Title>
                  </PaddingDiv>
                </BorderArea>
                <BorderArea foregroundColor="black">
                  <DesktopHr foregroundColor="black" />
                  <LeftColumn bwMode={bwMode}>


                    <PaddingDiv>

                      {
                        _.isUndefined(errors) ?
                          null :
                          <DeviseError {...errors} />
                      }
                      <label>Password should be at least 8 characters long and must contain each of the following: uppercase, lowercase, numbers and symbols.</label>

                      <Form
                        onSubmit={this.handleSubmit.bind(this)}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                          <form method="post" action={this.props.registration_path} onSubmit={this.handleSubmit.bind(this)}>
                            <input type="hidden" name="authenticity_token" value={csrfToken} />
                            <FormBorderWrapper bwMode={bwMode}>
                              <FormField bwMode={bwMode}>
                                <label>Email</label>
                                <Field
                                  name="user[email]"
                                  component="input"
                                  type="email"
                                  autoFocus={true}
                                  autoComplete="email"
                                  placeholder="abc@universalthirst.com"
                                />
                              </FormField>
                              <FormField bwMode={bwMode}>
                                <label>Fullname</label>
                                <Field
                                  name="user[fullname]"
                                  component="input"
                                  type="text"
                                  autoFocus={false}
                                  autoComplete="fullname"
                                  placeholder="John Doe"
                                />
                              </FormField>
                              <FormField bwMode={bwMode}>
                                <label>Password</label>
                                <Field
                                  name="user[password]"
                                  component="input"
                                  type="password"
                                  autoComplete="current-password"
                                  placeholder="******"
                                />
                              </FormField>
                              <FormField bwMode={bwMode}>
                                <label>Password Confirmation</label>
                                <Field
                                  name="user[password_confirmation]"
                                  component="input"
                                  type="password"
                                  autoComplete="current-password"
                                  placeholder="******"
                                />
                              </FormField>
                            </FormBorderWrapper>
                            <Gutter h={20} />

                            <ButtonArea>
                              <div>
                                <PrimaryButton bwMode={bwMode} type="submit" disabled={submitting || pristine}>
                                  Submit
                                </PrimaryButton>
                                {/* <ButtonA bwMode={bwMode} href={this.props.new_session_path}>
                                  Login
                                </ButtonA> */}
                              </div>
                              <div>
                                <a href={this.props.new_password_path}>Forgot password?</a>
                              </div>
                            </ButtonArea>
                          </form>
                        )}
                      />
                    </PaddingDiv>
                  </LeftColumn>
                </BorderArea>


              </Container>
              <Footer foregroundColor="black" />
            </Fragment>
        }

        <MobileMenu isLogoutEnabled={true} backgroundColor="#E0E0E0" foregroundColor="black" highlightColor="#000000" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode
  }
}

export default connect(mapStateToProps)(SignUp);
