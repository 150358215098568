import React, { Component } from 'react'
import styled from 'styled-components';

const Link = styled.a`
  display:block;
  margin: 8px 0;
  color: ${props => props.selected ? props.highlightColor : "white"};
`;

const Answer = styled.div`
  color: white;
  margin-bottom: 20px;
  div {
        color: #fff !important; 
    }
`;
const Fragment = React.Fragment;


class FaqLink extends Component {
  createMarkup() {
    return {__html: this.props.answer};
  }

  render() {
    let { selected, mobile, highlightColor } = this.props;

    return (
      <Fragment>
        <Link href="javascript:void(0);" selected={selected} highlightColor={highlightColor} onClick={this.props.onClick}>
          {this.props.question}
        </Link>
        {
          selected && mobile ?
          <Answer style={{"color": "white"}} dangerouslySetInnerHTML={this.createMarkup()}>
          </Answer>
          : null

        }

      </Fragment>
    )
  }
}

export default FaqLink;
