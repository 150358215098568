import React from 'react';
import _ from 'lodash';
import { MenuTypefaces, Menu, MenuScripts, NewMainPageRenderer, MobileMenu, Emblem} from '../components';
import { changeLoginInfo, windowResize, setCart, changeBwMode } from '../actions';
import { connect } from 'react-redux';
import {  TypefaceArea } from "../stylesheets/components";
import styled from 'styled-components';
import media from '../stylesheets/media';

const Fragment = React.Fragment;



class Welcome extends React.Component {

  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();

  }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));

  }

  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
    this.props.dispatch(setCart(this.props.cart));
  }


  render() {
    let { main_pages, products, bwMode, typefacesPopup, languages, scriptsPopup } = this.props;
    console.log(this.props)
    return (
      <Fragment>

        <NewMainPageRenderer mainPages={main_pages} />

      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    scriptsPopup: state.scriptsPopup,
    bwMode: state.bwMode,
    windowHeight: state.windowHeight
  }
}

export default connect(mapStateToProps)(Welcome);
