import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { changeBwMode, setTypefacesPopup } from '../actions';
import { TypefacePreview, Footer } from './';
import { Link } from 'react-router-dom';
import { TypefaceArea, Gutter, CartLink } from "../stylesheets/components";
import media from '../stylesheets/media';
import invert from 'invert-color';

const Fragment = React.Fragment;

const TypefaceButtonArea = styled.div`
  position: absolute;
  left: ${props => props.scriptsPopup ? "calc(50% - 240px)" : "50%"};
  top: 24px;
  transform: translate(-50%, 0);
  z-index: 5;
  display: flex;
  align-items: center;
  
  a.title {
    font-size:1.8em;
    line-height:1.0;
    transition: color 0.2s;
    color: ${props => props.foregroundColor ? props.foregroundColor : (props.bwMode === "black" ? "white" : "black")};
    margin-right:10px;
  }

  ${media.mobileLarge`
    display: none;
  `}
`;



const CartButtonMobileArea = styled.div`
  display: none;

  ${media.mobileLarge`
  
    display: block;
    position: absolute;
    top: 17px;
    right: 43px;
    z-index: 5;
  `}

`;


class MenuTypefaces extends Component {
  constructor(props){
    super(props);

    this.state = {
      fontLoaded: false
    };
  }
  componentDidMount(){
    this.checkFontLoad();
  }

  componentWillUnmount(){
    clearInterval(this.fontInterval);
  }

  checkFontLoad(){
    this.fontInterval = setInterval(() => {
      if (document.fonts.check("12px 'UT Sans'")){
        clearInterval(this.fontInterval);
        
        this.setState({
          fontLoaded: true
        })
      }
    }, 100);
  }

  handleToggleTypeface(e){
    let finalPopupMode = !this.props.typefacesPopup;
    if (finalPopupMode) {
      this.props.dispatch(changeBwMode("black"));
    }
    this.props.dispatch(setTypefacesPopup(finalPopupMode));

  }

  render() {
    let { products, bwMode, typefacesPopup, selectedLanguages, cart, foregroundColor, backgroundColor, scriptsPopup } = this.props;
    let { fontLoaded } = this.state;
    
    let filteredProducts = products;
    let keyLanguages = _.keys(selectedLanguages);
    if (keyLanguages.length > 0) {
      
      
      filteredProducts = _.filter(products, p => {
        let productLanguageIds = _.map(p.languages, l => { return String(l.id) });
        let diff = _.difference(productLanguageIds, keyLanguages);
        // console.log(productLanguageIds, keyLanguages, diff, productLanguageIds.length, diff.length, productLanguageIds.length > diff.length);
        return productLanguageIds.length > diff.length;
      })
    }

    let cart_items_available = false;
    
    if (cart) {
      if (cart.cart_items.length > 0) {
        cart_items_available = true;
      }
    } 

    let cartBackgroundColor;

    if (typefacesPopup){  
      cartBackgroundColor = "black";
    } else {

      cartBackgroundColor = backgroundColor;
      // if (foregroundColor) {
      //   if (backgroundColor) {
      //   } else {
      //     cartBackgroundColor = invert(foregroundColor, true);
      //   }
      // } else {
      //   cartBackgroundColor = bwMode === "white" ? "white" : "black";
      // }
    }
    
    return (
      <Fragment>
        <CartButtonMobileArea>
          <CartLink mode="mobile" href="/cart" color={foregroundColor} bwMode={bwMode}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M7.5 10L8 21M12 10V21M12 21H19.0868C19.604 21 20.0359 20.6056 20.0827 20.0905L21 10H3L3.91732 20.0905C3.96415 20.6056 4.39601 21 4.91321 21H12ZM16.5 10L16 21" stroke={cartBackgroundColor} strokeWidth="2"/>
            <path d="M7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V10H7V7Z" stroke={cartBackgroundColor} strokeWidth="2"/>
          </svg>


            {
              cart_items_available ?
                <span className="label" style={{ color: cartBackgroundColor }}>
                  {cart.cart_items.length}
                </span> : null
            }
          </CartLink>
        </CartButtonMobileArea>
        <TypefaceButtonArea style={{ opacity: fontLoaded ? 1 : 0 }} foregroundColor={foregroundColor} bwMode={bwMode} scriptsPopup={scriptsPopup}>
          <a className="title" href="/products">
            Typefaces
          </a>&nbsp;
          {
            _.isNull(cart) ? 
            null : 

            (
              cart.cart_items.length > 0 ? 
              <CartLink href="/cart" color={foregroundColor} bwMode={bwMode}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M7.5 10L8 21M12 10V21M12 21H19.0868C19.604 21 20.0359 20.6056 20.0827 20.0905L21 10H3L3.91732 20.0905C3.96415 20.6056 4.39601 21 4.91321 21H12ZM16.5 10L16 21" stroke={cartBackgroundColor} strokeWidth="2"/>
                  <path d="M7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7V10H7V7Z" stroke={cartBackgroundColor} strokeWidth="2"/>
                </svg>

                
                <span className="label" style={{ color: cartBackgroundColor}}>
                  { cart.cart_items.length }
                </span>
              </CartLink> : null
            )
          }
        </TypefaceButtonArea>
        {
          typefacesPopup ?
        <Fragment>

            <TypefaceArea scriptsPopup={scriptsPopup}>
              {
                filteredProducts.length > 0 ?

                _.map(filteredProducts, (p, i) => {
                  return (
                    <TypefacePreview key={i} last={i === filteredProducts.length - 1} {...p} windowHeight={this.props.windowHeight} />
                  )
                }) :
                <Gutter h={180} />
              }
              <Footer foregroundColor="white" />
            </TypefaceArea>
          </Fragment> : null
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    typefacesPopup: state.typefacesPopup,
    selectedLanguages: state.selectedLanguages,
    cart: state.cart,
    windowHeight: state.windowHeight,
    scriptsPopup: state.scriptsPopup
  }
};

export default connect(mapStateToProps)(MenuTypefaces);