import React from 'react';
import _ from 'lodash';
import { MenuTypefaces, MobileMenu, Menu, MenuScripts, Footer, BackgroundColorChanger } from '../components';
import { changeLoginInfo } from '../actions';
import { connect } from 'react-redux';
import { numberWithDelimiter } from '../utils';
import styled from 'styled-components';
import { DesktopHr, MenuGutter, Container, Gutter, HalfContainer, PaddingDiv, LeftColumn, RightColumn, Title, ButtonAWhite, BorderArea } from '../stylesheets/components';

const OrderList = styled.a`
  padding: 10px 0;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
`;

const CartItemContainer = styled.div`

  display: flex;
  /* justify-content: space-between; */

  div.title {
    width: 40%;
  }
  div.licenses {
    width: 50%;
  }
  div.delete-price {
    display: flex;

    div.price {
      margin-right: 15px;
    }
  }
`;


const OrderItemContainer = styled.div`

  /* justify-content: space-between; */
  margin-bottom: 50px;
  div.title {
    width: 40%;
  }
  div.licenses {
    width: 50%;
  }
  div.delete-price {
    display: flex;

    div.price {
      margin-right: 15px;
    }
  }
`;

const CompletePg = styled.p`
  color: white;
  a {
    color: #8040F6;
  }
`

const Header = styled.div`

  width: 100%;
  display: flex;
  border-bottom: 1px solid black;

  div.title {
    width: 40%;
  }
  div.licenses {
    width: 50%;
  }

`;

const Fragment = React.Fragment;

class OrdersCreate extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
  }

  render() {
    let { products, languages, user, order, bwMode, typefacesPopup, current_user } = this.props;
    return (
      <Fragment>
        <Menu foregroundColor="#FFFFFF" isLogoutEnabled={true} highlightColor="#FFFFFF" currentPage="Welcome" />
        <MenuTypefaces foregroundColor="white" backgroundColor="black" products={products} />
        <MenuScripts foregroundColor="#FFFFFF" languages={languages} />

        {
          typefacesPopup ?
            null :
            <Fragment>
              <BackgroundColorChanger color="black" bwMode="black" />

              <Container bwMode={bwMode} className="no-webfont">
                <BorderArea bwMode={bwMode}>
                  <HalfContainer>
                    <LeftColumn bwMode={bwMode}>
                      <MenuGutter />
                      <PaddingDiv>
                        <Title bwMode={bwMode}>
                          Thank You
                        </Title>
                      </PaddingDiv>
                    </LeftColumn>
                  </HalfContainer>
                </BorderArea>
                <BorderArea bwMode={bwMode}>
                  <DesktopHr bwMode={bwMode} />
                  <HalfContainer>
                    <LeftColumn bwMode={bwMode}>
                      <PaddingDiv>
                        <CompletePg>
                        Thank you for purchasing fonts at Universal Thirst.<br/>
                        The fonts are now available to download on your account page where you can also access your order history.<br/><br/>

                        A receipt will be sent to your registered email shortly. If you have any questions, please don't hesitate to write to us.<br/><br/>

                        Enjoy the fonts and we look forward to seeing what you make with them!<br/><br/>
                        </CompletePg>
                        <CompletePg>
                          <a href="mailto:hello@universalthirst.com">hello@universalthirst.com</a><br/>
                          Instagram&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://twitter.com/universalthirst" target="_blank">@universalthirst</a><br/>
                          Twitter&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://instagram.com/universalthirst" target="_blank">@universalthirst</a>
                        </CompletePg>

                        <Gutter h={40}/>
                        <ButtonAWhite bwMode={bwMode} href={`/order/${order.public_uid}/download`}>Download Fonts</ButtonAWhite>
                        <ButtonAWhite bwMode={bwMode} href={'/'}>Back to Home</ButtonAWhite>
                      </PaddingDiv>
                    </LeftColumn>

                    <RightColumn>
                    </RightColumn>
                  </HalfContainer>
                </BorderArea>
              </Container>
            </Fragment>
        }

        <Footer />
        <MobileMenu isLogoutEnabled={true} backgroundColor="black" foregroundColor="white" highlightColor="#FFFFFF" currentPage="OrderCreate" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    typefacesPopup: state.typefacesPopup
  }
}

export default connect(mapStateToProps)(OrdersCreate);
