export const windowResize = (dimension) => {
  return {
    type: 'WINDOW_RESIZE',
    payload: {
      windowWidth: dimension.width,
      windowHeight: dimension.height
    }
  }
}

export const changeUpVariables = (upVariables) => {
  return {
    type: "CHANGE_UP_VARIABLES",
    payload: {
      upVariables: upVariables
    }
  }
}

export const changeCartModal = (cartModal, justBoughtLength) => {
  return {
    type: "CHANGE_CART_MODAL",
    payload: {
      cartModal: cartModal,
      justBoughtLength: justBoughtLength
    }
  }
}


export const changeDownVariables = (downVariables) => {
  return {
    type: "CHANGE_DOWN_VARIABLES",
    payload: {
      downVariables: downVariables
    }
  }
}

export const changeCustomWorkContainerHeight = (customWorkContainerHeight) => {
  return {
    type: "CHANGE_CUSTOM_WORK_CONTAINER_HEIGHT",
    payload: {
      customWorkContainerHeight: customWorkContainerHeight
    }
  }
}

export const changeCsrfToken = (csrfToken) => { 
  return {
    type: 'CHANGE_CSRF_TOKEN',
    payload: {
      csrfToken: csrfToken
    }
  }
}

export const changePreviewFontSize = (previewFontSize) => {
  return {
    type: "CHANGE_PREVIEW_FONT_SIZE",
    payload: {
      previewFontSize: previewFontSize
    } 
  }
}

export const changeSelectedTestCategory = (selectedTestCategory) => {
  return {
    type: "CHANGE_SELECTED_TEST_CATEGORY",
    payload: {
      selectedTestCategory: selectedTestCategory
    }
  }
}
export const changeMobileMenuColors = (backgroundColor, foregroundColor) => {
  return {
    type: "CHANGE_MOBILE_MENU_COLORS",
    payload: {
      mobileMenuBackgroundColor: backgroundColor,
      mobileMenuForegroundColor: foregroundColor
    }
  }
}

export const changeMobileMenuVisible = (mobileMenuVisible) => {
  return {
    type: "CHANGE_MOBILE_MENU_VISIBLE",
    payload: {
      mobileMenuVisible: mobileMenuVisible
    }
  }
}

export const changeUpProductVariantName = (upProductVariantName) => {
  return {
    type: "CHANGE_UP_PRODUCT_VARIANT_NAME",
    payload: {
      upProductVariantName: upProductVariantName
    }
  }
}

export const changeDownProductVariantName = (downProductVariantName) => {
  return {
    type: "CHANGE_DOWN_PRODUCT_VARIANT_NAME",
    payload: {
      downProductVariantName: downProductVariantName
    }
  }
}

export const changeUpProductVariantSupportedLanguage = (upProductVariantSupportedLanguage) => {
  return {
    type: "CHANGE_UP_PRODUCT_VARIANT_SUPPORTED_LANGUAGE",
    payload: {
      upProductVariantSupportedLanguage: upProductVariantSupportedLanguage
    }
  }
}

export const changeDownProductVariantSupportedLanguage = (downProductVariantSupportedLanguage) => {
  return {
    type: "CHANGE_DOWN_PRODUCT_VARIANT_SUPPORTED_LANGUAGE",
    payload: {
      downProductVariantSupportedLanguage: downProductVariantSupportedLanguage
    }
  }
}


export const changeUpProductVariantID = (upProductVariantID) => {
  return {
    type: "CHANGE_UP_PRODUCT_VARIANT_ID",
    payload: {
      upProductVariantID: upProductVariantID
    }
  }
}

export const changeDownProductVariantID = (downProductVariantID) => {
  return {
    type: "CHANGE_DOWN_PRODUCT_VARIANT_ID",
    payload: {
      downProductVariantID: downProductVariantID
    }
  }
}

export const setScriptsPopup = (scriptsPopup, typefacesPopup) => {
  return {
    type: 'SET_SCRIPTS_POPUP',
    payload: {
      scriptsPopup: scriptsPopup,
      typefacesPopup: typefacesPopup ? typefacesPopup : scriptsPopup
    }
  }
}

export const setTypefacesPopup = (typefacesPopup) => {
  return {
    type: 'SET_TYPEFACES_POPUP',
    payload: {
      typefacesPopup: typefacesPopup
    }
  }
}

export const setSelectedLanguages = (selectedLanguages) => {
  return {
    type: 'SET_SELECTED_LANGUAGES',
    payload: {
      selectedLanguages: selectedLanguages
    }
  }
}

export const addSelectedLanguage = (id, selectedLanguage) => {
  return {
    type: 'ADD_SELECTED_LANGUAGE',
    payload: {
      id: id,
      selectedLanguage: selectedLanguage
    }
  }
}

export const removeSelectedLanguage = (id) => {
  return {
    type: 'REMOVE_SELECTED_LANGUAGE',
    payload: {
      id: id
    }
  }
}

export const changeBwMode = (bwMode) => {
  return {
    type: "CHANGE_BW_MODE",
    payload: {
      bwMode: bwMode
    }
  }
}

export const setCart = (cart) => {
  return {
    type: "SET_CART",
    payload: {
      cart: cart
    }
  }
}

export const signOut = () => {
  return {
    type: "SIGN_OUT",
    payload: {
    }
  }
}

export const changeLoginInfo = (
  new_user_session_path, 
  current_user,
  user_signed_in,
  destroy_user_session_path
) => {
  return {
    type: 'CHANGE_LOGIN_INFO',
    payload: {
      new_user_session_path,
      current_user,
      user_signed_in,
      destroy_user_session_path
    }
  }
}