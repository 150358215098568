import React, { Component } from 'react'

export default class TwitterIcon extends Component {
  render() {
    return (
      <svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1">
        <g id="UT" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Universal-Thirst-/-About" transform="translate(-1350.000000, -1567.000000)" fill={this.props.foregroundColor}>
            <g id="Group-8" transform="translate(-10.000000, 1439.000000)">
              <g id="Group-3" transform="translate(38.000000, 124.000000)">
                <g id="Group" transform="translate(1322.000000, 3.000000)">
                  <path d="M21.51562,6.2734375 C21.54688,6.3984375 21.5625,6.6015575 21.5625,6.8828175 C21.5625,8.5390675 21.25,10.1874875 20.625,11.8281255 C20,13.4687585 19.11719,14.9687435 17.97656,16.3281255 C16.83593,17.6875065 15.35938,18.7890585 13.54688,19.6328125 C11.73437,20.4765667 9.734386,20.8984375 7.546875,20.8984375 C4.796861,20.8984375 2.281261,20.1640698 0,18.6953125 C0.3437517,18.7265625 0.7343728,18.7421875 1.171875,18.7421875 C3.453136,18.7421875 5.5,18.0390695 7.3125,16.6328125 C6.218745,16.6328125 5.257817,16.3125035 4.429688,15.6718755 C3.601558,15.0312465 3.031252,14.2265675 2.71875,13.2578125 C3.031252,13.2890625 3.328124,13.3046875 3.609375,13.3046875 C4.046877,13.3046875 4.484373,13.2578125 4.921875,13.1640625 C4.171871,13.0078115 3.5,12.6953145 2.90625,12.2265625 C2.312497,11.7578105 1.843752,11.1875035 1.5,10.5156275 C1.156248,9.8437475 0.984375,9.1171875 0.984375,8.3359375 L0.984375,8.2421875 C1.671878,8.6484375 2.406246,8.8671875 3.1875,8.8984375 C1.718743,7.8984375 0.984375,6.5234475 0.984375,4.7734375 C0.984375,3.8984375 1.218748,3.0703175 1.6875,2.2890575 C2.906256,3.8203175 4.398429,5.0390575 6.164062,5.9453175 C7.929696,6.8515675 9.81249,7.3515575 11.8125,7.4453175 C11.75,7.0703075 11.71875,6.6953175 11.71875,6.3203175 C11.71875,4.9765575 12.19531,3.8203175 13.14844,2.8515575 C14.10157,1.8828075 15.24999,1.3984375 16.59375,1.3984375 C18.03126,1.3984375 19.23437,1.9140575 20.20312,2.9453175 C21.32813,2.7265575 22.375,2.3359375 23.34375,1.7734375 C22.96875,2.9296975 22.25001,3.8359375 21.1875,4.4921875 C22.125,4.3671875 23.0625,4.1015675 24,3.6953175 C23.3125,4.6953175 22.48438,5.5546875 21.51562,6.2734375 Z" id="twitter_icon"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
