import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addSelectedLanguage, removeSelectedLanguage } from '../actions';
import styled from 'styled-components';

const LanguageLink = styled.a`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  color:${props => props.isSelected ? "black" : "white"};
  background-color:${props => props.isSelected ? "white" : "black"};
  font-size:1.444em;
  line-height: 1.1;
  border-bottom: 1px solid ${props => props.isSelected ? "black" : "white"};

  &:hover {
    opacity: 1;
    color: ${props => props.isSelected ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"};
    border-bottom: 1px solid ${props => props.isSelected ? "black" : "white"};
  }
`;
class LanguageBox extends Component {
  handleClick(e){
    let { selectedLanguages } = this.props;
    if (!selectedLanguages[this.props.id]) {
      this.props.dispatch(addSelectedLanguage(this.props.id, {
        id: this.props.id,
        language_name: this.props.language_name
      }));
    } else {
      this.props.dispatch(removeSelectedLanguage(this.props.id));
    }
  }

  isSelected(){
    let { selectedLanguages } = this.props;
    return selectedLanguages[this.props.id];
  }
  render() {
    return (
      <LanguageLink isSelected={this.isSelected()} href="javascript:void(0);" onClick={this.handleClick.bind(this)}>
        { this.props.language_name }
      </LanguageLink>
    )
  }
}

let mapStateToProps = state => {
  return {
    selectedLanguages: state.selectedLanguages
  }
}

export default connect(mapStateToProps)(LanguageBox);
