import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PreviewToolbar, BwBuyBar, PreviewSelectors } from './';
import TextareaAutosize from 'react-textarea-autosize';
import { Gutter } from '../stylesheets/components';
import Select from 'react-select';
import { changeUpProductVariantID, changeDownProductVariantID, changePreviewFontSize, changeUpVariables, changeDownVariables } from '../actions';
import media from '../stylesheets/media';
import _ from 'lodash';
import { getFontVH, convVariableToStr } from '../utils';
import FontFaceObserver from 'fontfaceobserver';

const BorderWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: calc(50% - 1px);
  min-height: 320px;
  max-height: 500px;
  border-bottom: 1px solid ${props => props.bwMode === "white" ? "black" : "white"};
  display: flex;
  align-items: center;
  &:last-child {
    height: calc(50% + 1px);
  }

  ${media.mobileLarge`
    height: 320px;
  `}
`;

const PreviewInput = styled.input`
  font-family: ${props => props.product_name};
  border: none;
  width: 70vw;
  background: transparent;
  text-align: center;
`;

const PreviewTextArea = styled(TextareaAutosize)`
  line-height:1.3;
  background: transparent !important;
  margin:0 auto;
  display: block;
  transition: 0.4s opacity;



  ${media.mobileLarge`
    font-size: 140px !important;
    width: calc(100% - 10px) !important;
    height: auto !important;
    white-space: nowrap;
  `}

  /* ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 16px;
    height: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 0.5);
    border: 9px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(150, 150, 150, 0.8);
  }
  ::-webkit-scrollbar-thumb:active {
    background: rgba(150, 150, 150, 0.8);
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 36px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  } */

`;

const PreviewTextAreaParagraph = styled(TextareaAutosize)`
  background: transparent !important;
  margin:0 auto;
  display: block;

  ${media.mobileLarge`
    font-size: 140px !important;
    width: calc(100% - 10px) !important;
    height: auto !important;
  `}
/*
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 16px;
    height: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 0.5);
    border: 9px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(150, 150, 150, 0.8);
  }
  ::-webkit-scrollbar-thumb:active {
    background: rgba(150, 150, 150, 0.8);
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 36px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  } */

`;

const PreviewContainer = styled.div`
  width: 70vw;
  margin: 0 auto;
  /* height: 25vh; */
  overflow: hidden;

  ${props => props.testCategory === 1 ?
    "align-items: center;" : ""
  }

  /* margin-top: 50px; */

  ${media.mobileLarge`
    width: calc(100% - 20px);
    margin: 0 auto;
  `};
`;


const GradientMask = styled.div`
  position: absolute;
  bottom: 21%;
  width: 100%;
  height: 40px;
  background: ${props => props.bwMode === "black" ? "linear-gradient(180deg,rgba(0,0,0, 0) 0%,rgba(0,0,0,0.7) 40%, rgba(0,0,0,1) 100%)"
  : "linear-gradient(180deg,rgba(0,0,0, 0) 0%,rgba(255,255,255,0.7) 40%, rgba(255,255,255,1) 100%)"};
`;
const Fragment = React.Fragment;

class ProductVariantPreview extends Component {
  constructor(props){
    super(props);

    this.textRef = React.createRef();

    let options = _.map(this.props.supportedLanguages, sl => {
      return {
        value: sl.id,
        label: sl.language_name
      }
    });
    let regex = '';
    let str = '';

    this.state = {
      selectedTestCategory: null,
      productVariantSupportedLanguage: null,
      selectedTestExample: "",
      supportedLanguageOptions: options,
      isLoading: true,
      variant: null
    };
  }

  static getDerivedStateFromProps(props, state){
    let changed = false;
    let change_content = true;
    if (state.selectedTestCategory) {
      if (state.selectedTestCategory.value !== props.selectedTestCategory.value) {
        changed = true;
      }
    } else if (!state.selectedTestCategory && props.selectedTestCategory) {
      changed = true;
    }
    if (state.variant) {
      if(props.variant.id !== state.variant.id){
        changed = true;
        change_content = false
      }
    }

    if (state.productVariantSupportedLanguage) {
      if (props.mode === "up") {
        if (state.productVariantSupportedLanguage.value !== props.upProductVariantSupportedLanguage.value) {
          changed = true;
          change_content = true
        }
      } else {
        if (state.productVariantSupportedLanguage.value !== props.downProductVariantSupportedLanguage.value) {
          changed = true;
          change_content = true
        }
      }
    } else if (!state.productVariantSupportedLanguage) {
      if (props.mode === "up") {
        if (props.upProductVariantSupportedLanguage.value) {
          changed = true;
        }
      } else {
        if (props.downProductVariantSupportedLanguage.value) {
          changed = true;
        }
      }

    }
    if (changed) {
      let selectedTestExample = _.find(props.variant.test_examples, te => {
        if (props.mode === "up") {

        return te.test_category.value === props.selectedTestCategory.value
        && te.language.value === props.upProductVariantSupportedLanguage.value
      }else {
        return te.test_category.value === props.selectedTestCategory.value &&
            te.language.value === props.downProductVariantSupportedLanguage.value;
      }
      })

      let content = selectedTestExample.desktop_example_content;
      if(screen.width < 769){
        content = selectedTestExample.mobile_example_content
      }
      if (change_content == false){
        content = state.selectedTestExample;
      }

      return {
        variant: props.variant,
        selectedTestExample: content,
        selectedTestCategory: props.selectedTestCategory,
        productVariantSupportedLanguage: props.mode === "up" ? props.upProductVariantSupportedLanguage : props.downProductVariantSupportedLanguage
      };
    }
    return null;
  }

  handleInputChange(e){
    this.setState({
      selectedTestExample: e.target.value
    });
  }

  componentDidUpdate(prevProps){

    if (prevProps.id !== this.props.id) {
      this.fontLoad();
    }

    if (prevProps.selectedTestCategory.value !== this.props.selectedTestCategory.value) {
      this.props.dispatch(changePreviewFontSize(this.props.selectedTestCategory.value === 1 ? getFontVH() : 24));
    }


    if (prevProps.windowHeight !== this.props.windowHeight) {
      let { previewFontSize } = this.props;
      let newPreviewFontSize = this.props.selectedTestCategory.value === 1 ? getFontVH() : 24;
      if (previewFontSize > newPreviewFontSize)
      this.props.dispatch(changePreviewFontSize(newPreviewFontSize));
    }

    if (!prevProps.variable_font && this.props.variable_font) {
      this.variableSetting();
    }

  }

  componentDidMount(){

    this.variableSetting();
    this.fontLoad();

  }

  fontLoad(){

    let font;

    if (this.props.variable_font) {
      font = new FontFaceObserver(`${this.props.product_name}-variable`);

    } else {

      font = new FontFaceObserver(this.props.product_name + '-' + this.props.variant_name.replace(/ /g,''));
    }

    this.setState({
      isLoading: true
    });

    // console.log(this.props.product_name, "start loading");
    font.load(null, 10000).then(() => {
      // console.log(this.props.product_name, "start loading complate");
      this.setState({isLoading: false})

      _.delay(() => {
        this.forceUpdate();
      }, 500);
    }, err => {
      // console.error('Failed to load fonts!', err)
    });


  }

  variableSetting(){
    if (this.props.variable_font) {

      var fontSettings = _.cloneDeep(this.props.variable_font_settings);

      if (this.props.mode === "up") {

        this.props.dispatch(changeUpVariables(fontSettings));

      } else {
        this.props.dispatch(changeDownVariables(fontSettings));

      }
    }
  }


  render() {

    let { windowWidth, bwMode, selectedTestCategory, previewFontSize } = this.props;
    let { isLoading, selectedTestExample } = this.state;

    let selectStyles = {
      control: (provided, state) => {
        return {
          ...provided,
          border: 'none !important',
          boxShadow: "none",
          background: bwMode === "white" ? "white" : "black",
          "&:hover": {
            border: "none"
          }
        };
      },
      singleValue: (provided, state) => ({
        ...provided,
        color: bwMode === "white" ? "black" : "white",
        border: "none",
        marginLeft: 0,
        borderRadius: 0
      }),
      indicatorSeparator: (provided, state) => ({
        display: "none"
      }),
      menu: (provided, state) => {
        return {
          ...provided,
          borderRadius: 0,
          margin: 0,
          left: -15
        }
      },
      option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        border: "none",
        borderBottom: `1px dotted ${bwMode === "black" ? "#ddd" : "#222"}`,
        backgroundColor: bwMode === "black" ? "white" : "black",
        color: "black",
        "&:last-child": {
          border: "none"
        }
      })
    }

    let individualVariants = _.filter(this.props.product.variants, v => {
      return !v.complete;
    });

    let variantNames = _.uniq(_.map(individualVariants, v => v.variant_name))



    // debugger;
    let variantOptions = _.map(variantNames, v => {
      return {
        value: v,
        label: v
      };
    });
    let font_family_name = this.props.product_name + '-' + this.props.variant_name.replace(/ /g,'')
    let font_family = this.props.variable_font ? `${this.props.product_name}-variable` : font_family_name
    let font_family1 = `${font_family}, AdobeBlank`
    console.log(this.props)

    let lineHeight = windowWidth < 768 ? this.props.variant.mobile_line_height : this.props.variant.desktop_line_height
    if(lineHeight < 0){
      lineHeight = this.props.product.line_spacing
    }
    if(this.props.mode == "up"){
      if(this.props.product_name == "Tiffin" && this.props.upProductVariantSupportedLanguage.label == "Devanagari" && this.props.variable_font) {
        lineHeight = "1.4"
      }
    }
    if(this.props.mode == "down"){
      if(this.props.product_name == "Tiffin" && this.props.downProductVariantSupportedLanguage.label == "Devanagari" && this.props.variable_font) {
        lineHeight = "1.4"
      }
    }

    let textAreaStyle = {
      width: selectedTestCategory.value === 1 ? "70vw" : "50vw",
      fontFamily:  font_family1,
      color: bwMode === "black" ? "white" : "black",
      fontWeight: this.props.weight,
      fontStyle: this.props.style,
      lineHeight: lineHeight,
      fontSize: `${previewFontSize}px`,
      textAlign: (selectedTestCategory.value === 1 ? "center" : "left"),
      background: "transparent!important",
      resize: 'none',
      border: "none",
      padding: "10px 0",
      opacity: isLoading ? 0 : 1,
      overflow: "auto",
      // letterSpacing: letterSpacing
    };

    if (this.props.variable_font) {
      let variables = this.props.mode === "up" ? this.props.upVariables : this.props.downVariables;
      delete textAreaStyle.fontWeight;
      textAreaStyle.fontVariationSettings = convVariableToStr(variables);
    }

    let borderWrapperHeightStyleWord = {
      "min-height": "none",
      "max-height": "none"
    }

    let borderWrapperHeightStyleParagraph = {
      "min-height": "none",
      "max-height": "none"
    }
    console.log( selectedTestCategory.value === 1 ? 1 : 8)
    return (
      <Fragment>
        <BorderWrapper bwMode={bwMode} style={selectedTestCategory.value === 1 ? borderWrapperHeightStyleWord :  borderWrapperHeightStyleParagraph }>
          <PreviewContainer testCategory={selectedTestCategory.value} style={{ height: "auto" }}>

            <PreviewTextArea
              className={`previewtextarea-${this.props.mode}`}
              ref={this.textRef}
              spellCheck="false"
              maxRows={
                selectedTestCategory.value === 1 ? 1000 :
                (windowWidth < 768 ? 1 : 1000)
              }
              minRows={ selectedTestCategory.value === 1 ? 1 : 8}
              onChange={this.handleInputChange.bind(this)}
              style={textAreaStyle}
              type="text"
              value={selectedTestExample} />

            <link rel="stylesheet" href={`/api/product_variants/${this.props.id}.css`} />
          </PreviewContainer>


        </BorderWrapper>



        {
          this.props.mode === "up" ?
            <Fragment>
              <PreviewSelectors
                product={this.props.product}
                products={this.props.products}
                selectedTestCategory={this.state.selectedTestCategory}
                testCategories={this.props.testCategories}
                testExamples={this.props.testExamples}
              />
              <BwBuyBar />
            </Fragment> : null
        }


        <PreviewToolbar
          variantOptions={variantOptions}
          selectedTestCategory={selectedTestCategory}
          selectedTestExample={selectedTestExample}
          mode={this.props.mode}
          variableFont={this.props.variable_font}
          supportedLanguages={this.state.supportedLanguageOptions}
          testCategories={this.props.testCategories}
          testExamples={this.props.testExamples}
        />

      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    bwMode: state.bwMode,
    selectedTestCategory: state.selectedTestCategory,
    previewFontSize: state.previewFontSize,
    downVariables: state.downVariables,
    upVariables: state.upVariables,
    upProductVariantName: state.upProductVariantName,
    downProductVariantName: state.downProductVariantName,
    upProductVariantSupportedLanguage: state.upProductVariantSupportedLanguage,
    downProductVariantSupportedLanguage: state.downProductVariantSupportedLanguage
  }
}
export default connect(mapStateToProps)(ProductVariantPreview);
