import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeBwMode } from '../actions';
import styled from 'styled-components';
import gsap from "gsap";
import media from '../stylesheets/media';

const Fragment = React.Fragment;
const BarRight = styled.div`
  position:absolute;
  top: calc(50% - 33px);
  right: 20px;
  z-index: 5;
  
  display: flex;
  align-items: center;
  ${media.mobileLarge`
    display: none;
  `}
`;

const BwButton = styled.a`
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`

const BuyButton = styled.button`
  background:  ${props => props.bwMode === "black" ? "white" : "black"};
  border-radius: 50px;
  font-size: 18px;
  padding: 4px 6px 4px 16px;
  color: ${props => props.bwMode === "black" ? "black" : "white"};
  cursor: pointer;
  border: none;

  &:hover {
    opacity: 1;
  }
  display:flex;
  align-items: center;
  
  span.title  {
    margin-right: 0;
  }

  img.arrow {
    margin-top: -5px;
  }
`

class BwBuyBar extends Component {

  handleClickBwMode(e){
    let mode = this.props.bwMode === "black" ? "white" : "black";
    this.props.dispatch(changeBwMode(mode));
    document.body.style.background = mode;
  }

  handleClick(elementName) {

    var d = document.querySelector(`a[name="buy"]`)

    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = d.getBoundingClientRect(),
      offset = elemRect.top - bodyRect.top;

    // debugger;
    // document.body.scrollTop = offset - 100;
    gsap.to((document.body), 1, { ease: "power2.inOut", scrollTop: offset - 20 });
    gsap.to((document.documentElement), 1, { ease: "power2.inOut", scrollTop: offset - 20 });

  }

  render() {
    let { bwMode } = this.props;
    return (
      <Fragment>
        <BarRight>
          <BwButton href="javascript:void(0);" onClick={this.handleClickBwMode.bind(this)}>
            <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
              <circle cx="22.6274" cy="23" r="15.5" transform="rotate(-45 22.6274 23)" fill={bwMode === "black" ? "black" : "white"} 
                stroke={bwMode === "black" ? "white" : "black"} />
              <path d="M33.2284 11.6919L11.3193 33.601C5.61645 27.5249 5.73243 17.9747 11.6673 12.0398C17.6021 6.10502 27.1523 5.98903 33.2284 11.6919Z" fill={bwMode === "black" ? "white" : "black"} stroke={bwMode === "black" ? "white" : "black"} />
            </svg>
          </BwButton>


          <BuyButton bwMode={bwMode} onClick={this.handleClick.bind(this)}>
            <span className="title">
              Buying Options
            </span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12.3889 7.17127C12.2947 7.05709 12.165 7 12 7C11.835 7 11.6995 7.05138 11.5934 7.15415C11.4873 7.25691 11.4343 7.38822 11.4343 7.54808V17.116L8.99455 14.7867C8.73525 14.5126 8.47007 14.5069 8.19899 14.7695C7.9279 15.0322 7.9338 15.2891 8.21666 15.5403L11.6111 18.8287C11.6582 18.8972 11.7171 18.9429 11.7879 18.9657C11.9293 19.0114 12.0707 19.0114 12.2121 18.9657C12.2829 18.9429 12.3418 18.8972 12.3889 18.8287L15.7833 15.5403C16.0662 15.2891 16.0721 15.0322 15.801 14.7695C15.5299 14.5069 15.2647 14.5126 15.0055 14.7867L12.5657 17.116V7.54808C12.5657 7.38822 12.5068 7.26262 12.3889 7.17127Z" fill={ bwMode === "white" ? "white" : "black"} />
            </svg>

          </BuyButton>
        </BarRight>
      </Fragment>
    )
  }
}

let mapStateToProps = state => { 
  return {
    bwMode: state.bwMode
  }
}

export default connect(mapStateToProps)(BwBuyBar);
