import React from 'react';
import _ from 'lodash';
import { MobileMenu, MenuTypefaces, Menu, MenuScripts, Footer, BackgroundColorChanger } from '../components';
import { setCart, changeMobileMenuColors, changeLoginInfo } from '../actions';
import { connect } from 'react-redux';

import { Container, Gutter, HalfContainer, LeftColumn, RightColumn, Title, FormField, FormBorderWrapper, PrimaryButton, ButtonA, PaddingDiv, ButtonArea } from '../stylesheets/components';
import { numberWithDelimiter } from '../utils';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components';

const Fragment = React.Fragment;

class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.resource,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));
    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors("#FFFFFF", "#000000"));
  }
  handleSubmit(e){

  }

  render() {
    let { products, languages, typefacesPopup, bwMode, errors } = this.props;

    const tokenDom = document.querySelector("meta[name=csrf-token]");
    const csrfToken = tokenDom.content;

    return (
      <Fragment>
        <Menu foregroundColor="#000000" isLogoutEnabled={true} highlightColor="#000000" currentPage="User" />
        <MenuTypefaces foregroundColor="#000000" products={products} />
        <MenuScripts foregroundColor="#000000" languages={languages} />
        {
          typefacesPopup ?
            null :
            <Fragment>
              <BackgroundColorChanger color="#E0E0E0" bwMode="white" />

              <Container bwMode={bwMode} className="no-webfont">
                <HalfContainer>
                  <LeftColumn bwMode={bwMode}>
                    <Gutter h={350} />
                    <PaddingDiv>
                      <Title bwMode={bwMode}>
                        Edit User Information
                      </Title>
                    </PaddingDiv>
                    <hr style={{ borderColor: "black" }} />

                    <PaddingDiv>

                      {
                        _.isUndefined(errors) ?
                          null :
                          <DeviseError {...errors} />
                      }

                      {
                        _.isUndefined(this.props.flash.alert) ? null :
                          <div>
                            {this.props.flash.alert}
                            <Gutter h={6} />
                          </div>
                      }

                      {
                        _.isUndefined(this.props.flash.notice) ? null :
                          <div>
                            {this.props.flash.notice}
                            <Gutter h={6} />
                          </div>
                      }

                      <Form
                        onSubmit={this.handleSubmit.bind(this)}

                        initialValues={{
                          user: this.props.resource
                        }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (

                          <form class="edit_user" id="edit_user" action="/users" accept-charset="UTF-8" method="post">

                            <input type="hidden" name="_method" value="put" />
                            <input type="hidden" name="authenticity_token" value={csrfToken} />

                            <FormBorderWrapper bwMode={bwMode}>
                              <FormField bwMode={bwMode}>
                                <label>Email</label>
                                <Field
                                  name="user[email]"
                                  component="input"
                                  type="email"

                                  autoComplete="email"
                                  placeholder="abc@universalthirst.com"
                                />
                              </FormField>

                              <FormField bwMode={bwMode}>
                                <label>Fullname</label>
                                <Field
                                  name="user[fullname]"
                                  component="input"
                                  type="text"

                                  autoComplete="fullname"
                                  placeholder="Fullname"
                                />
                              </FormField>

                              <FormField bwMode={bwMode}>
                                <label>Company</label>
                                <Field
                                  name="user[company]"
                                  component="input"
                                  type="text"

                                  autoComplete="company"
                                  placeholder="Company"
                                />
                              </FormField>

                              <FormField bwMode={bwMode}>
                                <label>Street Address 1</label>
                                <Field
                                  name="user[addr_01]"
                                  component="input"
                                  type="text"

                                  autoComplete="addr_01"
                                  placeholder="Address 1"
                                />
                              </FormField>
                              <FormField bwMode={bwMode}>
                                <label>Street Address 2</label>
                                <Field
                                  name="user[addr_02]"
                                  component="input"
                                  type="text"

                                  autoComplete="addr_02"
                                  placeholder="Address 2"
                                />
                              </FormField>

                              <FormField bwMode={bwMode}>
                                <label>City</label>
                                <Field
                                  name="user[city]"
                                  component="input"
                                  type="text"

                                  autoComplete="city"
                                  placeholder="City"
                                />
                              </FormField>

                              <FormField bwMode={bwMode}>
                                <label>Country</label>
                                <Field
                                  component="select"
                                  validate={value => {

                                    if (value === "-1" ) {
                                      return "Required";
                                    } else {
                                      return (value ? undefined : 'Required');
                                    }
                                  }}
                                  name="user[country]"
                                >
                                  {({ input, meta }) => (
                                    <div className="right">

                                      <select {...input} placeholder="Select Country..." >
                                      <option value="-1">Select Country...</option>
                                        {
                                          _.map(this.props.countries, country => {
                                            return (
                                              <option key={country[1]} value={country[1]}>
                                                { country[0] }
                                              </option>
                                            )
                                          })
                                        }
                                      </select>
                                      {meta.error && meta.touched && <span className="error">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormField>

                              <FormField bwMode={bwMode}>
                                <label>State</label>
                                <Field
                                  name="user[state]"
                                  component="input"
                                  type="text"

                                  autoComplete="state"
                                  placeholder="State"
                                />
                              </FormField>

                              <FormField bwMode={bwMode}>
                                <label>Zipcode</label>
                                <Field
                                  name="user[zipcode]"
                                  component="input"
                                  type="text"

                                  autoComplete="zipcode"
                                  placeholder="Zipcode"
                                />
                              </FormField>

                              <FormField bwMode={bwMode}>
                                <label>Phone</label>
                                <Field
                                  name="user[phone]"
                                  component="input"
                                  type="text"

                                  autoComplete="phone"
                                  placeholder="Phone"
                                />
                              </FormField>
                            </FormBorderWrapper>

                            <Gutter h={20} />
                            <ButtonArea>
                              <div>
                                <PrimaryButton bwMode={bwMode} type="submit" disabled={submitting || pristine}>
                                  Submit
                                </PrimaryButton>
                              </div>
                            </ButtonArea>
                          </form>
                        )}
                      />
                    </PaddingDiv>
                  </LeftColumn>
                  <RightColumn>
                    <Gutter h={462} />
                    <hr style={{ borderColor: "black" }} />
                  </RightColumn>
                </HalfContainer>


              </Container>
              <Footer />
            </Fragment>
        }
        <MobileMenu isLogoutEnabled={true} highlightColor="#000000" currentPage="User" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode,
    current_user: state.current_user
  }
}

export default connect(mapStateToProps)(UserEdit);
