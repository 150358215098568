import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import Select from 'react-select';
import media from '../stylesheets/media';

const Fragment = React.Fragment;
const BackWrapper = styled.div`
  background-color: ${props => props.bwMode === "black" ? "white" : "black"};
  padding: 0px 15px;
  background-image: linear-gradient(to right, ${props => props.bwMode === "black" ? "black" : "white"} 25%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;

  &:last-child {
    border:none;
  }
`;
const LicenseSelectorArea = styled.div`
  padding-top: 18px;

  background-image: linear-gradient(to right, ${props => props.bwMode === "black" ? "black" : "white"} 25%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 4px 1px;
  background-repeat: repeat-x;

`;

const LicenseTitle = styled.h4`
  color: #828282;
`;

const LicenseSelectorBottomArea = styled.div`
  /* border-left: 1px dotted ${props => props.bwMode === "black" ? "black" : "white"}; */

  background:
    repeating-linear-gradient(to bottom,transparent 0 4px,black 4px 5px) 0/1px 100% no-repeat;

  margin-bottom: 15px;
  padding-bottom: 0;
  padding-left: 15px;
  /* background-color: ${props => props.bwMode === "black" ? "white" : "black"}; */

  &:first-child {
    background: none;
    padding-left: 0;
  }

  ${media.mobileLarge`
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    background: none;
    padding-left:0;
    padding-bottom: 15px;
    margin-bottom: 18px;
    background-image: linear-gradient(to right,black 25%,rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;

    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      background-image: linear-gradient(to right,black 25%,rgba(255,255,255,0) 0%);
      background-position: bottom;
      background-size: 4px 1px;
      background-repeat: repeat-x;
      padding-left: 0;
    }
  `}
`;

const SelectArea = styled.div`
  ${media.mobileLarge`
    width: 50%;
  `}
`;


const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;


  ${media.mobileLarge`
    display: block;
  `}

`;
class ProductVariantLicenseSelector extends Component {

  componentDidMount(){
    // let selectedOption = {
    //   label: "None",
    //   value: { license_id: license.id, deleted: true }
    // };

    // this.props.handleNumberLicenseClick(this, variant);
  }

  render() {
    let { bwMode, licenses, variant, windowWidth } = this.props;

    if (_.isNull(variant)) {
      return null;
    }

    return (
        <BackWrapper bwMode={bwMode}>

          <LicenseSelectorArea bwMode={bwMode}>
            <FlexBox>
              {
                _.map(licenses, license => {
                  let options = _.map(license.number_licenses, nl => {
                    return {
                      label: `${nl.number_license_name}`,
                      value: nl
                    };
                  });

                  options.unshift({
                    label: "None",
                    value: { license_id: license.id, deleted: true }
                  });

                  // debugger;

                  let selectedValue = _.find(variant.selectedNumberLicenses, sl => {
                    return sl.license_id === license.id;
                  });

                  let selectedOption = {
                    label: "None",
                    value: { license_id: license.id, deleted: true }
                  };

                  if (!_.isUndefined(selectedValue)) {

                    // console.log("selectedValue is not undefined", variant.selectedNumberLicenses, selectedValue, selectedOption);
                    let idx = _.findIndex(options, option => {
                      // debugger;
                      return option.value.id === selectedValue.id;
                    });
                    selectedOption = options[idx];
                  }

                  // console.log(variant.selectedNumberLicenses, selectedValue, selectedOption);
                  // console.log(options);

                  return (
                    <LicenseSelectorBottomArea bwMode={bwMode} key={license.id} style={{ width: windowWidth < 768 ? "100%" : `calc(${100 / licenses.length}% - 20px)` }}>
                      <LicenseTitle bwMode={bwMode}>
                        {license.license_name} License
                      </LicenseTitle>

                      <SelectArea>
                        <Select
                          components={{ DropdownIndicator:() => null, IndicatorsContainer:() => null }}
                          styles={{
                            valueContainer: (provided, state) => ({
                              ...provided,
                              padding: "2px 0"
                            }),
                            container: (provided, state) => {
                              return {
                                ...provided,
                                marginBottom: 5
                              }
                            },
                            control: (provided, state) => {
                              return {
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                border: "none !important",
                                borderRadius: 0,
                                backgroundColor: this.props.bwMode === "black" ? "white" : "black",
                                color: this.props.bwMode === "black" ? "black" : "white"
                              };
                            },
                            singleValue: (provided, state) => ({
                              ...provided,
                              color: this.props.bwMode === "black" ? "black" : "white",
                              border: "none",
                              marginLeft: 0,
                              borderRadius: 0,
                              "&:after": {
                                content: `url(/assets/arrow_${this.props.bwMode === "white" ? "white" : "black"}.svg)`,
                                paddingLeft: 10,
                                top: -2,
                                position: "relative"
                              }
                            }),
                            indicatorSeparator: (provided, state) => ({
                              display: "none"
                            }),
                            menu: (provided, state) => {
                              return {
                                ...provided,
                                borderRadius: 16,
                                padding: 5,
                                margin: 0,
                                zIndex: 999,
                                width: 200,
                                // maxHeight: 264,
                                left: -16,
                                overflow: "hidden",
                                boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.75)",
                                backgroundColor: bwMode === "black" ? "white" : "black",
                                color: bwMode === "black" ? "black" : "white"
                              }
                            },
                            menuList: (provided, state) => {
                              return {
                                ...provided,
                                overflowX: "hidden"
                              }
                            },
                            option: (provided, state) => ({
                              ...provided,
                              borderRadius: 0,
                              border: "none",
                              paddingLeft: 11,
                              backgroundImage: `linear-gradient(to right, ${this.props.bwMode} 25%, rgba(255,255,255,0) 0%)`,
                              backgroundPosition: "bottom",
                              backgroundSize: "4px 1px",
                              backgroundRepeat: "repeat-x",
                              '&:hover, &:active': {
                                backgroundColor: bwMode === "black" ? "white" : "black"
                              },
                              backgroundColor: this.props.bwMode === "black" ? "white" : "black",
                              color: bwMode === "black" ? "black" : "white",
                              "&:first-child": {
                                paddingTop: 0
                              },
                              "&:last-child": {
                                backgroundImage: "none",
                                paddingBottom: 0,
                                marginBottom: 0
                              }
                            })
                            // menu: (provided, state) => {
                            //   return {
                            //     ...provided,
                            //     borderRadius: 0,
                            //     margin: 0,
                            //     padding:0,
                            //     left: windowWidth < 768 ? 0 : -15
                            //   }
                            // },
                            // option: (provided, state) => ({
                            //   ...provided,
                            //   borderRadius: 0,
                            //   border: "none",
                            //   borderBottom: `1px dotted ${this.props.bwMode === "black" ? "#ddd" : "#222"}`,
                            //   backgroundColor: this.props.bwMode === "black" ? "white" : "black",
                            //   color: this.props.bwMode === "black" ? "black" : "white",
                            //   "&:last-child": {
                            //     border: "none"
                            //   }
                            // })
                          }}
                          isSearchable={false}
                          onChange={this.props.handleNumberLicenseClick.bind(this, variant)}
                          value={selectedOption}
                          options={options}>
                        </Select>
                      </SelectArea>

                    </LicenseSelectorBottomArea>
                  )
                })
              }
            </FlexBox>
          </LicenseSelectorArea>
        </BackWrapper>
    )
  }
}

let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    windowWidth: state.windowWidth
  };
}

export default connect(mapStateToProps)(ProductVariantLicenseSelector);
