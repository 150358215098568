import React from 'react';
import _ from 'lodash';
import { MobileMenu, Menu, MenuTypefaces, MenuScripts, BackgroundColorChanger, Footer, CustomWorkLayout } from '../components';
import { changeMobileMenuColors, changeLoginInfo, setCart } from '../actions';
import { connect } from 'react-redux';
import { BorderArea, PaddingDiv, DesktopHr, Title, MenuGutter, Container, RightColumn, LeftColumn, HalfContainer, Gutter, MenuArticleGutter, OneLineDesktopGutter } from '../stylesheets/components';
import styled from 'styled-components';
import media from '../stylesheets/media';
import { COLUMN_WIDTH } from '../constants/defaults';

const Fragment = React.Fragment;


const FCTitle = styled(Title)`
  color: ${props => props.foregroundColor};
  word-break: normal;
`;

const LineDiv = styled.div`
  height: 50px;
  border: none;
  border-right: 1px solid ${props => props.foregroundColor};

`
const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MoreBtn = styled.a`
  border: 1px solid ${props => props.foregroundColor};
  padding: 10px 25px;
  border-radius: 30px;
  color: ${props => props.foregroundColor};
  font-size: 20px;

`;

const MobileDiv = styled.div`
  display: none;
  ${media.mobileLarge`
    display: block;
  `}
`;


const DescDiv = styled.div`
  padding: 32px;
  max-width: 600px;
  width: calc(50% - 64px);

  ${media.mobileLarge`
    padding: 10px 15px;
    width: calc(100% - 30px);
  `}

  div, p, a {
    color: ${props => props.foregroundColor};


    img {
      width: 100%;
      height: auto;
    }

  }

`;

const PhotoArea1 = styled.div`
  width: calc(100% - 64px);
  padding: 32px;
  text-align: center;
  ${media.mobileLarge`
    width: calc(100% - 30px);
    padding: 15px;
    padding-bottom: 25px;
  `}
`;
const PhotoArea = styled.div`
  width: calc(100% - 64px);
  padding: 32px;
  text-align: center;
  ${media.mobileLarge`
    width: calc(100% - 30px);
    padding: 15px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${props => props.border};
  `}
`;

const PhotoImg = styled.img`
  width: ${props => props.size === "large" ? 900 : 600 }px;
  ${media.mobileLarge`
    width: 100%;
  `}
`;

const Caption = styled.div`
  padding: 30px 32px 0 32px;
  font-weight:600;
  color:${props => props.foregroundColor};
  ${media.mobileLarge`
    padding: 25px 15px 0 15px;
  `}
`;

class CustomWork extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));


    this.props.dispatch(setCart(this.props.cart));
    this.props.dispatch(changeMobileMenuColors(this.props.custom_work.background_color, this.props.custom_work.foreground_color));

    this.hrRef = React.createRef();
    this.state = {
      hrTop: 479
    };
  }

  componentDidMount() {
    this.updateHrTop();
    window.addEventListener('resize', this.resizeHandler.bind(this));
    setTimeout(() => {
      this.resizeHandler();

    }, 200);


  }

  updateHrTop() {
    let hrElem = this.hrRef.current;

    var top = 0;
    do {
        top += hrElem.offsetTop  || 0;
        hrElem = hrElem.offsetParent;
    } while(hrElem);

    // let rect = hrElem.getBoundingClientRect();
    // console.log(rect);
    this.setState({
      hrTop: top
    });
  }

  resizeHandler(e) {
    this.updateHrTop();
  }

  handleImageLoadComplete(e) {
    this.updateHrTop();
  }

  render() {
    let { custom_work, products, languages, bwMode, typefacesPopup } = this.props;
    let i =0 ;
    let count = custom_work.custom_work_photos.length
    console.log(custom_work.border_color)

    return (
      <Fragment>
        <Menu foregroundColor={typefacesPopup ? "#FFFFFF" : custom_work.foreground_color} isLogoutEnabled={true} highlightColor={custom_work.highlight_color} currentPage="Custom Work" />
        <MenuTypefaces backgroundColor={custom_work.background_color} foregroundColor={typefacesPopup ? "#FFFFFF" : custom_work.foreground_color} products={products} />
        <MenuScripts foregroundColor={typefacesPopup ? "#FFFFFF" : custom_work.foreground_color} languages={languages} />
        {
          typefacesPopup ?
          null :
          <Fragment>
            <BackgroundColorChanger color={custom_work.background_color} bwMode="white" />
            <Container className="no-webfont">
              <BorderArea foregroundColor={custom_work.foreground_color}>

                <MenuGutter />
                <PaddingDiv>
                  <FCTitle foregroundColor={custom_work.foreground_color}>
                    { custom_work.title }
                  </FCTitle>
                </PaddingDiv>
                <DesktopHr ref={this.hrRef} bwMode={bwMode} foregroundColor={custom_work.foreground_color} />
              </BorderArea>
              <BorderArea foregroundColor={custom_work.foreground_color}>
                <DescDiv foregroundColor={custom_work.foreground_color} dangerouslySetInnerHTML={{ __html: custom_work.description }} />
              </BorderArea>
              {

                _.map(custom_work.custom_work_photos, (p, index) => {
                    return (
                      <BorderArea foregroundColor={custom_work.foreground_color}>
                        <DesktopHr foregroundColor={custom_work.foreground_color}/>
                        {
                          p.caption ?
                          <Caption foregroundColor={custom_work.foreground_color}>
                            { p.caption }
                          </Caption> : null
                        }
                        { index != count - 1 ?
                        <PhotoArea border={custom_work.border_color} key={p.id} foregroundColor={custom_work.foreground_color}>
                          <PhotoImg src={p.photo} size={p.size} />
                        </PhotoArea> :
                        <PhotoArea1 key={p.id} foregroundColor={custom_work.foreground_color}>
                          <PhotoImg src={p.photo} size={p.size} />
                        </PhotoArea1>
                      }
                      </BorderArea>

                    );
                })
              }
              <BtnContainer foregroundColor={custom_work.foreground_color}>
                <MoreBtn foregroundColor={custom_work.foreground_color} href="/custom-work">More Custom Work</MoreBtn>
              </BtnContainer>
              <BtnContainer>
                <LineDiv foregroundColor={custom_work.foreground_color} />
              </BtnContainer>
            </Container>
            <Footer foregroundColor={custom_work.foreground_color}/>
          </Fragment>
        }
        <MobileMenu isLogoutEnabled={true} backgroundColor={custom_work.background_color} foregroundColor={custom_work.foreground_color}  highlightColor={custom_work.highlight_color} currentPage="Custom Work" />
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    typefacesPopup: state.typefacesPopup,
    bwMode: state.bwMode,
    // customWorkContainerHeight: state.customWorkContainerHeight
  }
}

export default connect(mapStateToProps)(CustomWork);
